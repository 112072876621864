import React, { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { isLogged } from '../../store/slices/authInfoSlice';
import { getFunzionalita } from '../../store/slices/funzionalitaSlice';
import { navigateTo } from '../../store/slices/routingSlice';
import { loginPagePath, loginTitle } from '../../utils/utilconst';

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const logged = useAppSelector(isLogged);

  const funzionalita = useAppSelector(getFunzionalita);

  // controllo se l'operatore è abilitato a tale funzione
  // attraverso il controllo delle url
  useEffect(() => {
    // contiene tutti i path delle funzioni
    const paths = ['/'];

    funzionalita.forEach(func => {
      if (func.funzioni && func.funzioni?.length > 0) {
        func.funzioni
          ?.forEach(subFunc => {
            subFunc.uri && paths.push(subFunc.uri);
          });
      } else {
        func.uri && paths.push(func.uri);
      }
    });

    if (paths.every(path => path !== '/' + window.location.pathname.split('/')[1])) {
      history.replace('/forbidden')
    }
  }, [funzionalita, history]);

  useEffect(() => {
    if (!logged) {
      history.push(loginPagePath);
      dispatch(navigateTo({
        uri: loginPagePath, title: loginTitle,
        guida: false
      }));
    }
  }, [dispatch, history, logged]);

  return <Route {...rest} >{logged ? children : <></>}</Route>
}
