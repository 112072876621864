import { useEffect, useMemo, useState } from "react";
import { Column } from "@material-table/core";
import { useTranslation } from "react-i18next";
import { allFields } from "../../../models/VariazioniUO";
import { AnagraficaSoggettiUnitaOperativa } from "../../../models/AnagraficaSoggettiUnitaOperativa";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Paper from "@material-ui/core/Paper";
import { Box, Grid, Typography } from "@material-ui/core";
import { lookup as fetchStruttureLookup, reset as resetStrutture } from "../../../store/slices/struttureSlice";
import { lookupFiltered as fetchDipartimentiByStruttura, cleanLookup as resetDipartimentiLookup, reset as resetDipartimentiFiltered } from "../../../store/slices/dipartimentiFilteredSlice";
import { findIdStrutturaAndLegge104, reset as resetAnagraficheSoggette } from '../../../store/slices/anagraficaSoggettiSlice';
import {
  fetchByStrutturaDipartimentoBetweenDate as fetchVariazioniUO, reset as resetUnitaOperative
} from '../../../store/slices/anagraficaSoggettiUnitaOperativeSlice';

import { useHistory, Switch, Route, Redirect } from "react-router-dom";
import { componentTabsPath } from "../../../utils/innerFuncPaths";
import { Fields } from "../../../models/Fields";
import ReadOnlyMaterialTable from '../tables/ReadOnly/ReadOnlyMaterialTable';
import { variazioniUOPath, notFoundPath } from "../../../utils/utilconst";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { PDFExtraData } from "../../../models/Utils";
import { getDateDDMMYYYY } from "../../../utils/utilfunctions";
import i18n from "../../../i18n";
import { it, enGB } from "date-fns/locale";
import SimpleIdSelects from "../selects/SimpleIdSelects";

function isUrlOnUpdate(url: string) {
  return url === variazioniUOPath + componentTabsPath;
}

const VariazioniUOW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector((state) => state.authInfo.logoUri);

  const strSelectName = useMemo(() => t("structuresTitle"), [t]);
  const strSelectLabel = useMemo(() => t("structureSelect"), [t]);
  const dipSelectName = useMemo(() => t("departmentsTitle"), [t]);
  const dipSelectLabel = useMemo(() => t("departmentSelect"), [t]);
  const fromSelectName = useMemo(() => t("startDateTitle"), [t]);
  const fromSelectLabel = useMemo(() => t("startDateSelect"), [t]);
  const toSelectName = useMemo(() => t("endDateTitle"), [t]);
  const toSelectLabel = useMemo(() => t("endDateSelect"), [t]);

  const idStrutturaConst: string = "idStruttura";
  const idDipartimentoConst: string = "idDipartimento";
  const daConst: string = "da";
  const aConst: string = "a";

  const lookupStr = useAppSelector(state => state.strutture.lookup);
  const lookupDip = useAppSelector(state => state.dipartimentiFiltered.lookup);

  const [states, setStates] = useState<{
    [selectName: string]: number | string | null;
  }>({
    [strSelectName]: null,
    [dipSelectName]: null,
    [fromSelectName]: null,
    [toSelectName]: null,
  });

  const fixedProps = useMemo(() => {
    return {
      [idStrutturaConst]: states[strSelectName],
      [idDipartimentoConst]: states[dipSelectName],
      [daConst]: states[fromSelectName],
      [aConst]: states[toSelectName],
    };
  }, [dipSelectName, fromSelectName, states, strSelectName, toSelectName]);

  /**
   * header combo lookup fetch
   */
  useEffect(() => {
    if (!states[strSelectName])
      dispatch(fetchStruttureLookup());
    else if (states[strSelectName])
      dispatch(fetchDipartimentiByStruttura({ idStruttura: states[strSelectName] as number }));
  }, [dispatch, states, strSelectName]);

  /**
   * Data fetch
   */
  useEffect(() => {
    if (fixedProps[idStrutturaConst] && fixedProps[idDipartimentoConst] && fixedProps[daConst] && fixedProps[aConst]) {
      dispatch(fetchVariazioniUO({
        idStruttura: Number(fixedProps[idStrutturaConst]),
        idDipartimento: Number(fixedProps[idDipartimentoConst]),
        dataFrom: fixedProps[daConst].toString(),
        dataTo: fixedProps[aConst].toString()
      }));
    }
  }, [dispatch, fixedProps]);

  /**
   * Column creation
   */
  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>([]);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettiUnitaOperativa>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettiUnitaOperativa> = {
          title: f.titleKey ? t(f.titleKey) : "",
          field: f.field ? f.field : "",
          removable: f.removable ?? !f.required,
          editable: "never",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        };

        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        /*+++ lookups */

        /*+++*/
        return obj;
      })
    );
  }, [t, allFieldsState, excludedFieldInTable]);

  // da aggiornare
  const errorBE = useAppSelector((state) => state.anagraficaSoggetti.error);
  const validAnagraficheSoggetti = useAppSelector((state) => state.anagraficaSoggettiUnitaOperative.validAnagraficaSoggettiUnitaOperative);
  const statusValidAnagraficheSoggetti = useAppSelector((state) => state.anagraficaSoggettiUnitaOperative.statusValidAnagraficaSoggettiUnitaOperative);

  /**
   * Data filter based on selected tab
   */
  const [data, setData] = useState<Array<AnagraficaSoggettiUnitaOperativa>>([]);
  useEffect(() => {
    setData(validAnagraficheSoggetti);
  }, [validAnagraficheSoggetti]);

  const handleDateChange = (d: Date | null, name?: string) => {
    let fieldDate: string = "";
    if (d) {
      let month = (d.getMonth() + 1).toString().padStart(2, '0');
      let day = d.getDate().toString().padStart(2, '0');
      fieldDate = d.getFullYear() + "-" + month + "-" + day + "T00:00:00";
    }
    name && setStates(prev => { return { ...prev, [name]: fieldDate } });
  };

  useEffect(() => {
    setAllFieldsState(allFields);
  }, []);

  const [exportDataExtra, setExportDataExtra] = useState<PDFExtraData>();
  useEffect(() => {
    const _daConst = fixedProps[daConst]
    const _aConst = fixedProps[aConst]

    const headTitle: string[] = [];
    const headValue: any[] = [];

    /**
     * init title table
     */
    headTitle.push(t("startDateTitle"), t("endDateTitle"));
    headValue.push(
      _daConst ? getDateDDMMYYYY(new Date(_daConst)) : '',
      _aConst ? getDateDDMMYYYY(new Date(_aConst)) : '',
    );

    setExportDataExtra({
      head: {
        title: headTitle,
        value: headValue
      }
    });
  }, [fixedProps, t]);

  useEffect(() => {
    return () => {
      dispatch(resetStrutture());
      dispatch(resetDipartimentiFiltered());
      dispatch(resetAnagraficheSoggette());
      dispatch(resetUnitaOperative());
    };
  }, [dispatch]);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={8} >
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: strSelectName,
                    lookup: lookupStr,
                    label: strSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, sm: 12, md: 6, lg: 6 },
                  },
                  {
                    name: dipSelectName,
                    lookup: lookupDip,
                    label: dipSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, sm: 12, md: 6, lg: 6 },
                    callback: resetDipartimentiLookup
                  }
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>
            {states[strSelectName] && states[dipSelectName] &&
              <Grid item xs={12} sm={6} md={6} lg={2} >
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                  <DatePicker
                    label={fromSelectLabel}
                    format={"dd/MM/yyyy"}
                    onChange={(e) => handleDateChange(e, fromSelectName)}
                    okLabel={t('insertLabel')}
                    clearLabel={t('clearLabel')}
                    cancelLabel={t('cancelLabel')}
                    clearable={true}
                    disabled={isUrlOnUpdate(history.location.pathname)}
                    value={states[fromSelectName] ? states[fromSelectName] : null}
                    autoOk={true}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>}
            {
              states[fromSelectName] &&
              <Grid item xs={12} sm={6} md={6} lg={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                  <DatePicker
                    minDate={states[fromSelectName] ? states[fromSelectName] : null}
                    label={toSelectLabel}
                    format={"dd/MM/yyyy"}
                    onChange={(e) => handleDateChange(e, toSelectName)}
                    okLabel={t('insertLabel')}
                    clearLabel={t('clearLabel')}
                    cancelLabel={t('cancelLabel')}
                    clearable={true}
                    disabled={isUrlOnUpdate(history.location.pathname)}
                    value={states[toSelectName] ? states[toSelectName] : null}
                    autoOk={true}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2}>
        {
          states[fromSelectName] && states[toSelectName] ? (
            <>
              <Switch>
                <Route path={variazioniUOPath} exact>
                  <ReadOnlyMaterialTable
                    title={t("UOVariationsTitle")}
                    columns={columns}
                    data={data}
                    fetchAllValid={findIdStrutturaAndLegge104}
                    statusValid={statusValidAnagraficheSoggetti}
                    errorBE={errorBE}
                    logoUri={logoUri}
                    fixedProps={fixedProps}
                    exportDataExtra={exportDataExtra}
                    isExportLandscape={true}
                    localizedDatePicker={true}
                    extraOptions={{
                      maxBodyHeight: 460,
                    }}
                  />
                </Route>
                <Route>
                  <Redirect to={notFoundPath} />
                </Route>
              </Switch>
            </>
          ) : (
            <Switch>
              <Route path={variazioniUOPath} exact>
                <Paper elevation={2}>
                  <Box p={4}>
                    <Typography align="center" variant="h5">
                      {!states[strSelectName]
                        ? strSelectLabel
                        : !states[dipSelectName]
                          ? dipSelectLabel
                          : !states[fromSelectName]
                            ? fromSelectLabel
                            : toSelectLabel}
                    </Typography>
                  </Box>
                </Paper>
              </Route>
              <Route><Redirect to={variazioniUOPath} /></Route>
            </Switch>
          )}
      </Box>
    </>
  );
};
export default VariazioniUOW;
