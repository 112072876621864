import { Box, Grid, Paper, Typography, } from "@material-ui/core";
import React, { useEffect, useMemo, useState, } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { presenzeDipendentiPath, } from "../../../utils/utilconst";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import {
  fetchAllLookupByAbilitazione,
  reset as resetAnagraficaSoggetti,
} from '../../../store/slices/anagraficaSoggettiSlice';
import {
  fetchAll as fetchAllFormazioni,
  reset as resetFormazione,
} from '../../../store/slices/formazioneSlice';
import { useHistory } from "react-router-dom";
import { componentTabsPath } from "../../../utils/innerFuncPaths";
import i18n from "../../../i18n";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { getDateYYYYMMDD_BackEnd, getToday, } from '../../../utils/utilfunctions';
import DateFnsUtils from '@date-io/date-fns';
import { enGB, it, } from "date-fns/locale";
import { fetchAnagraficaSoggettoPerData } from "../../../store/slices/presenzeSlice";
import { lastDayOfMonth } from "date-fns";
import { Presenza } from "../../../models/Presenze";
import CalendarDipendentiPresenze from "./pianificazioniComponents/Calendar_Dipendenti/CalendarDipendentiPresenze";


function isUrlOnUpdate(url: string) {
  return url === presenzeDipendentiPath + componentTabsPath;
}

const PresenzeDipendentiW = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const errorBE = useAppSelector(state => state.presenze.error);

  const validPresenze = useAppSelector(state => state.presenze.validPresenze);

  const soggettoSelectName = useMemo(() => t('subjectRegistryTitle'), [t]);
  const soggettoSelectLabel = useMemo(() => t('registrySelect'), [t]);
  const monthYearSelectName = useMemo(() => t("dateTitle"), [t]);
  const monthYearSelectLabel = useMemo(() => t("monthSelect"), [t]);

  const lookupAnagraficaSoggettoPerAbilitazione = useAppSelector((state) => state.anagraficaSoggetti.lookup);

  const [presenze, setPianificazioni] = useState<Presenza[]>([]);
  useEffect(() => {
    setPianificazioni(validPresenze);
  }, [validPresenze])

  const [states, setStates] = useState<{
    [selectName: string]: number | string | null;
  }>({
    [soggettoSelectName]: null,
    [monthYearSelectName]: null,
  });

  useEffect(() => {
    setStates({
      [monthYearSelectName]: getToday(),
    });
  }, [monthYearSelectName]);

  const handleDateChange = (d: Date | null, field?: string) => {
    let fieldDate: string = "";
    if (d) {
      let month = d.getMonth() < 9 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      fieldDate = d.getFullYear() + "-" + month + "-" + day + "T00:00:00";
    }
    setStates(prev => { return { ...prev, [monthYearSelectName]: fieldDate } });
  };

  const fixedProps = useMemo(() => {
    const _monthYearSelectName = states[monthYearSelectName];
    const selectedDate: Date | null = _monthYearSelectName ? new Date(_monthYearSelectName) : null;
    return {
      idAnagraficaSoggetto: states[soggettoSelectName],
      dataInizio: selectedDate ? getDateYYYYMMDD_BackEnd(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)) : null,
      dataFine: selectedDate ? getDateYYYYMMDD_BackEnd(lastDayOfMonth(new Date(selectedDate))) : null,
    };
  }, [states, monthYearSelectName, soggettoSelectName]);

  useEffect(() => {
    dispatch(fetchAllLookupByAbilitazione());
    dispatch(fetchAllFormazioni()); // la chiamata serve per il lookup

  }, [dispatch]);

  useEffect(() => {
    if (fixedProps.idAnagraficaSoggetto && fixedProps.dataInizio && fixedProps.dataFine) {
      dispatch(fetchAnagraficaSoggettoPerData({
        idAnagraficaSoggetto: fixedProps.idAnagraficaSoggetto,
        dataInizio: fixedProps.dataInizio,
        dataFine: fixedProps.dataFine,
      }))
    }
  }, [dispatch, fixedProps.dataFine, fixedProps.dataInizio, fixedProps.idAnagraficaSoggetto,]);

  useEffect(() => {
    if (history.location.pathname === presenzeDipendentiPath)
      dispatch(fetchAllFormazioni());
  }, [dispatch, history.location.pathname]);


  useEffect(() => {
    return () => {
      dispatch(resetAnagraficaSoggetti());
      dispatch(resetFormazione());
    };
  }, [dispatch]);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={8}>
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: soggettoSelectName,
                    lookup: lookupAnagraficaSoggettoPerAbilitazione,
                    label: soggettoSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, md: 12, sm: 12, lg: 6 }
                  },
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                <DatePicker
                  label={monthYearSelectLabel}
                  format={"MMMM yyyy"}
                  onChange={(e) => handleDateChange(e)}
                  okLabel={t('insertLabel')}
                  clearLabel={t('clearLabel')}
                  cancelLabel={t('cancelLabel')}
                  clearable={true}
                  disabled={isUrlOnUpdate(history.location.pathname)}
                  value={states[monthYearSelectName] ? states[monthYearSelectName] : null}
                  views={['month', 'year',]}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2}>
        {states[soggettoSelectName]
          ? (
            errorBE ? (
              <Paper elevation={2}>
                <Box p={4}>
                  <Typography align="center" variant="h5">
                    {errorBE}
                  </Typography>
                </Box>
              </Paper>
            ) : (
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  {
                    fixedProps?.dataFine && fixedProps?.dataInizio &&
                    <CalendarDipendentiPresenze
                      idAnagraficaSoggetto={presenze?.[0]?.idAnagraficaSoggetto}
                      presenza={presenze}
                      dataInizio={fixedProps.dataInizio}
                      dataFine={fixedProps.dataFine}
                    />
                  }
                </Grid>
              </Grid>
            )
          ) : (
            <Paper elevation={2}>
              <Box p={4}>
                <Typography align="center" variant="h5">
                  {soggettoSelectLabel}
                </Typography>
              </Box>
            </Paper>
          )}
      </Box>

    </>
  )
}
export default PresenzeDipendentiW;