import { Box, Grid, Paper, Theme, Tooltip, Typography, createStyles, makeStyles, } from "@material-ui/core";
import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState, } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { dipendentiPresenzePath, } from "../../../utils/utilconst";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import {
  reset as resetAnagraficaSoggetti,
} from '../../../store/slices/anagraficaSoggettiSlice';
import { Redirect, Route, Switch, } from "react-router-dom";
import i18n from "../../../i18n";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { daysInMonth, getDateDDMMYYYY, getToday, } from '../../../utils/utilfunctions';
import DateFnsUtils from '@date-io/date-fns';
import { enGB, it, } from "date-fns/locale";
import { fetchStruttuturaPerData } from "../../../store/slices/dipendentiPresenzeSlice";
import { lookup as fetchStruttureLookup } from "../../../store/slices/struttureSlice";
import ReadOnlyMaterialTable from "../tables/ReadOnly/ReadOnlyMaterialTable";
import { PresenzeDipendenti, DipendentiPresenze, allFieldsState, DipendentiPresenzeKeys, PresenzeDipendentiKeys } from "../../../models/DipendentiPresenze";
import { Presenza } from "../../../models/Presenze";
import { Fields } from "../../../models/Fields";
import { Colors, ColorsKeys, MonthsList, WeekList } from "../../../utils/utildata";
import { isEqual } from "date-fns";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { PDFExtraData } from "../../../models/Utils";
import { fetchAllValid as fetchAllValidFestivita } from "../../../store/slices/festivitaSlice";
import { FixedProps } from "../../../utils/data.types";

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    h55: {
      height: 55
    },
    hCell: {
      height: 120
    },
    flexGrow: {
      flexGrow: 1,
    },
    appbar: {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    indicator: {
      backgroundColor: "#fff",
    },
    warning: {
      color: "#d00",
      fontWeight: "bold"
    },
    hoverCell: {
      '&:hover': {
        filter: 'brightness(0.9)'
      }
    }
  }),
);

const DipendentiPresenzeW = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector((state) => state.authInfo.logoUri);
  const classes = useStyles1();

  const strSelectName = useMemo(() => t("structuresTitle"), [t]);
  const strSelectLabel = useMemo(() => t("structureSelect"), [t]);
  const monthYearSelectName = useMemo(() => t("dateTitle"), [t]);
  const monthYearSelectLabel = useMemo(() => t("monthSelect"), [t]);
  const errorBe = useAppSelector((state) => state.dipendentipresenze.error)
  const lookupStr = useAppSelector((state) => state.strutture.lookup);

  const [columns, setColumns] = useState<Column<DipendentiPresenze>[]>([]);
  const [dataPresenzeDipendenti] = useState<PresenzeDipendenti[]>([]);
  const [dateFestivi, setDateFestivi] = useState<number[]>([]);

  const excludedFieldInTable = useMemo((): string[] => [], []);

  const giornoFestivo = useAppSelector((state => state.struttureFestivita.allStruttureFestivita))
  const festivita = useAppSelector((state => state.festivita.validFestivita))
  const statusValidDipendentiPresenze = useAppSelector((state) => state.dipendentipresenze.statusValidDipendentiPresenze)
  const validDipendentiPresenze = useAppSelector((state) => state.dipendentipresenze.validDipendentiPresenze)
  const validPresenze = useAppSelector((state) => state.presenze.validPresenze)

  const [states, setStates] = useState<{
    [selectName: string]: number | string | null;
  }>({
    [strSelectName]: null,
    [monthYearSelectName]: null,
  });
  useEffect(() => {
    setStates({
      [monthYearSelectName]: getToday(),
    });
  }, [monthYearSelectName]);

  const monthSelected = useMemo(() => {
    return states[monthYearSelectName];
  }, [monthYearSelectName, states])

  const fixedProps: FixedProps = useMemo(() => {
    let da = null;
    let a = null;

    if (monthSelected) {
      const currentDate: Date = new Date(monthSelected);
      const currentMonth: string = currentDate.getMonth() + 1 > 9 ? (currentDate.getMonth() + 1).toString() : "0" + (currentDate.getMonth() + 1);
      da = currentDate.getFullYear() + "-" + currentMonth + "-01T00:00:00";
      a = currentDate.getFullYear() + "-" + currentMonth + "-" + daysInMonth(currentDate.getMonth() + 1, currentDate.getFullYear()) + "T00:00:00";
    }

    return {
      idStruttura: states[strSelectName],
      dataInizio: da,
      dataFine: a,
    };
  }, [monthSelected, states, strSelectName]);


  useEffect(() => {
    setColumns(
      reworkPianificazioneFieldsNoBgColor(allFieldsState, validDipendentiPresenze, monthSelected ? (new Date(monthSelected)).getMonth() + 1 : null, monthSelected ? (new Date(monthSelected)).getFullYear() : null, t, classes)
        .filter(f => !excludedFieldInTable.includes(f.field))
        .map((f) => {
          let obj: Column<DipendentiPresenze> = {
            title: f.titleKey ? t(f.titleKey) : "",
            field: f.field,
            removable: f.removable ?? !f.required,
            editable: f.editable ? f.editable : "always",
            defaultSort: f.sort,
            emptyValue: f.defaultValue ?? "",
          };
          obj.headerStyle = { textAlign: 'center' }
          if (f.validate2) {
            obj.validate = (rowData) => {
              let resp: boolean | { isValid: boolean, helperText: string } = false;
              let resp2: boolean | { isValid: boolean, helperText: string } = false;

              if (f.validate2) {
                resp2 = f.validate2(
                  rowData[f.field as DipendentiPresenzeKeys],
                  rowData[f.field2Validation as DipendentiPresenzeKeys],
                  f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
                );
              }

              if (f.validate) {
                resp = f.validate(
                  rowData[f.field as DipendentiPresenzeKeys],
                  f.keyTradValidation ? t(f.keyTradValidation) : ''
                );
              }
              if (resp === true) {
                return resp2;
              } else return resp;
            }
          } else if (f.validate) {
            obj.validate = (rowData) => {
              if (f.validate) {
                return f.validate(
                  rowData[f.field as DipendentiPresenzeKeys],
                  f.keyTradValidation ? t(f.keyTradValidation) : ''
                );
              }
              return false;
            }
          }

          if (!f.show) {
            obj.hidden = true;
            obj.hiddenByColumnsButton = false;
          }
          if (f.type && f.type !== "image" && f.type !== "file") {
            obj.type = f.type;
          }

          if (f.render) {
            obj.render = f.render;
          }

          if (f.defaultGroupOrder !== null || f.defaultGroupOrder !== undefined) {
            obj.defaultGroupOrder = f.defaultGroupOrder;
          }

          if (['nominativo'].includes(f.field)) {
            obj.width = 300;
          } else if (['turnoDescrizione'].includes(f.field)) {
            obj.width = 200;
            obj.cellStyle = (_, rowData) => {
              const data = rowData['idTurno_TurnoAssenza' as DipendentiPresenzeKeys];
              if (data && data < 0) {
                return {
                  background: "#FF0000"
                }
              } else return {
                background: "#008000"
              }
            }

          } else {
            obj.align = 'center';
            obj.sorting = false;

            if (dateFestivi && dateFestivi[(Number(f.field.substring(1)))] === 1) {
              obj.cellStyle = {
                borderStyle: 'solid',
                borderColor: '#e0e0e0',
                borderWidth: 1,
                background: '#cecece',
                padding: 0,

              }
              obj.headerStyle = { background: '#cecece' };
            }
            else {
              obj.cellStyle = {
                borderStyle: 'solid',
                borderColor: '#e0e0e0',
                borderWidth: 1,
                padding: 0,
              }

            }


          }
          return obj;
        })
    );
  }, [classes, dateFestivi, excludedFieldInTable, festivita, fixedProps.dataInizio, giornoFestivo, monthSelected, monthYearSelectName, states, t, validDipendentiPresenze])


  useEffect(() => {
    if (fixedProps.idStruttura && fixedProps.dataInizio && fixedProps.dataFine)
      dispatch(fetchStruttuturaPerData({
        idStruttura: fixedProps.idStruttura as number,
        dataInizio: fixedProps.dataInizio as string,
        dataFine: fixedProps.dataFine as string,
      }

      ))
  }, [dispatch, fixedProps, fixedProps.dataFine, fixedProps.dataInizio, fixedProps.idStruttura])


  const handleDateChange = (d: Date | null, field?: string) => {
    let fieldDate: string = "";
    if (d) {
      let month = (d.getMonth() + 1).toString().padStart(2, '0');
      let day = d.getDate().toString().padStart(2, '0');
      fieldDate = d.getFullYear() + "-" + month + "-" + day + "T00:00:00";
    }
    setStates(prev => { return { ...prev, [monthYearSelectName]: fieldDate } });

  };

  useEffect(() => {
    dispatch(fetchAllValidFestivita())
  }, [dispatch]);

  useEffect(() => {
    const _dataInizio = fixedProps.dataInizio as string;

    if (_dataInizio) {
      const SUNDAY = 0;
      const SATURDAY = 6;
      const currentDate: Date = new Date(_dataInizio);

      const dateFestivi = [];
      for (let i = 1; i < 31; i++) {
        let dataFestiva = new Date(currentDate.getFullYear(), currentDate.getMonth(), i); //creare un oggetto date con il giorno uguale a 1 e mese e anno presi da monthYearSelectName
        dateFestivi[i] = (dataFestiva.getDay() === SUNDAY || dataFestiva.getDay() === SATURDAY) ? 1 : 0;

        if (dateFestivi[i] === 0) {

          festivita.forEach((elem) => {
            if (elem.giorno === i && elem.mese === currentDate.getMonth() + 1) {
              dateFestivi[i] = 1
            }
          })
        }
      }

      setDateFestivi(dateFestivi);
    }
  }, [dispatch, festivita, fixedProps.dataInizio]);



  const [exportDataExtra, setExportDataExtra] = useState<PDFExtraData>(() => {
    const _idStruttura = fixedProps['idStruttura'] as number;
    const _presenzaData = fixedProps['presenzaData'] as string;

    return {
      head: {
        title: [t("structureTitle"), t("dateTitle")],
        value: [
          _idStruttura ? lookupStr[_idStruttura] : null,
          _presenzaData ? getDateDDMMYYYY(new Date(_presenzaData)) : '',
        ]
      }
    }
  }
  );

  useEffect(() => {
    setExportDataExtra(state => {
      const _idStruttura = fixedProps['idStruttura'] as number;

      return {
        head: {
          title: [t("structureTitle"), t("yearTitle"), t("monthTitle")],
          value: [
            _idStruttura ? lookupStr[_idStruttura] : null,
            monthSelected ? (new Date(monthSelected)).getFullYear() : null,
            monthSelected ? t(MonthsList[(new Date(monthSelected)).getMonth()]) : null,  // mese inizia da 0
          ]
        },
      }
    });
  }, [fixedProps, lookupStr, monthSelected, monthYearSelectName, states, t])

  const newRiepilogoPresenzeDipendenti: Partial<PresenzeDipendenti>[] = useMemo(() => {

    const retval: Partial<PresenzeDipendenti>[] = [];
    if (validPresenze) {

      let maxDays = 0; //daysInMonth(mese, anno);

      validPresenze?.map((elem: Presenza) => {
        // interface ExtendedPresenzeDipendenti extends

        if (maxDays === 0) {
          maxDays = daysInMonth((new Date(elem.presenzaData)).getMonth() + 1, (new Date(elem.presenzaData)).getFullYear());
        }
        const index = retval.findIndex(presenza => presenza.idAnagraficaSoggetto === elem.idAnagraficaSoggetto && (presenza.idTurno_TurnoAssenza === elem.idTurno || (elem.idTurnoAssenza && presenza.idTurno_TurnoAssenza === (elem.idTurnoAssenza * -1))));
        const date = (new Date(elem.presenzaData)).getDate();

        const _dateIndex = '_' + (date).toString() as PresenzeDipendentiKeys;

        if (index === -1) {
          const temp: Partial<PresenzeDipendenti> = {
            idAnagraficaSoggetto: elem.idAnagraficaSoggetto,
            nominativo: elem.anagraficaSoggetto[0].cognome + ' ' + elem.anagraficaSoggetto[0].nome,
            turnoDescrizioneBreve: elem.turnoDescrizioneBreve ?? elem.turnoAssenzaDescrizioneBreve,
            turnoDescrizione: elem.turnoDescrizione ?? elem.turnoAssenzaDescrizione,
            presenzeObj: new Array(maxDays).fill([]),
            idTurno_TurnoAssenza: elem.idTurno ?? (elem.idTurnoAssenza ? elem.idTurnoAssenza * -1 : undefined),
          }

          if (temp.presenzeObj)
            temp.presenzeObj[date - 1] = elem;

          if (_dateIndex) {
            (temp as Record<string, unknown>)[_dateIndex] = (elem.oreTurno === 0 && elem.minutiTurno === 0) ? "" : ('0' + elem.oreTurno).substring(('0' + elem.oreTurno).length - 2) + ':' + ('0' + elem.minutiTurno).substring(('0' + elem.minutiTurno).length - 2);
          }

          retval.push(temp as PresenzeDipendenti);
        }
        else {
          const _presenzeObj = retval[index].presenzeObj;

          if (_presenzeObj)
            _presenzeObj[date - 1] = elem;
          if (_dateIndex)
            (retval[index] as Record<string, unknown>)[_dateIndex] = (elem.oreTurno === 0 && elem.minutiTurno === 0) ? "" : ('0' + elem.oreTurno).substring(('0' + elem.oreTurno).length - 2) + ':' + ('0' + elem.minutiTurno).substring(('0' + elem.minutiTurno).length - 2);
        }

        return validPresenze
      });
    }

    return retval
  }, [validPresenze])




  useEffect(() => {
    dispatch(fetchStruttureLookup());
  }, [dispatch]);


  useEffect(() => {
    return () => {
      dispatch(resetAnagraficaSoggetti());
    };
  }, [dispatch]);


  return (
    <>
      <Paper elevation={2}>

        <Box p={4} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={8}>
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: strSelectName,
                    lookup: lookupStr,
                    label: strSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, md: 12, sm: 12, lg: 6 }
                  },
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2}>
              {
                (states[strSelectName]) &&
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                  <DatePicker
                    label={monthYearSelectLabel}
                    format={"MMMM yyyy"}
                    onChange={(e) => handleDateChange(e)}
                    okLabel={t('insertLabel')}
                    clearLabel={t('clearLabel')}
                    cancelLabel={t('cancelLabel')}
                    clearable={true}
                    value={states[monthYearSelectName] ? states[monthYearSelectName] : null}
                    views={['month', 'year',]}
                  />
                </MuiPickersUtilsProvider>
              }
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Box marginTop={2}>
        {
          states[strSelectName] &&
            states[monthYearSelectName] &&
            validDipendentiPresenze ? (

            <ReadOnlyMaterialTable
              title={t("attendancedependents")}
              columns={columns}
              data={newRiepilogoPresenzeDipendenti}
              statusValid={statusValidDipendentiPresenze}
              errorBE={errorBe}
              logoUri={logoUri}
              fixedProps={fixedProps}
              exportDataExtra={exportDataExtra}
              isExportLandscape={true}
              localizedDatePicker={true}
              extraOptions={{
                draggable: false,
                // fixedColumns: { left: 0 },
                maxBodyHeight: 460,
                padding: 'dense',
                pageSize: dataPresenzeDipendenti?.length >= 20
                  ? 20
                  : dataPresenzeDipendenti?.length <= 5
                    ? 5
                    : 10,
                // grouping: false,
                defaultExpanded: true,

              }}
              pdfOptions={{
                format: 'a3',
                bodyFontSize: 8,
                fontWeight: 'bold',


              }}
            />
          ) :
            <Switch>
              <Route path={dipendentiPresenzePath} exact>
                <Paper elevation={2}>
                  <Box p={4}>
                    <Typography align="center" variant="h5">
                      {
                        !states[strSelectName]
                          ? strSelectLabel

                          : !states[monthYearSelectName]
                            ? monthYearSelectLabel

                            : ''
                      }
                    </Typography>
                  </Box>
                </Paper>
              </Route>
              <Route><Redirect to={dipendentiPresenzePath} /></Route>
            </Switch>
        }

      </Box>

    </>
  )
}
export default DipendentiPresenzeW;

function reworkPianificazioneFieldsNoBgColor(fields: Fields[], presenzeDipendenti: DipendentiPresenze[], month: number | null, year: number | null, t: (s: string) => string, classes: ClassNameMap<string>): Fields[] {
  if (month === null || year === null) return [];
  if (month > 12 || month < 1 || year < 1) return [];
  /*******************/
  const retval = [...fields];
  const validWeekDays = WeekList.slice(1);

  const first = new Date(year, month - 1, 1)

  const maxDays = daysInMonth(month, year);
  const weekDay = first.getDay() - 1 < 0
    ? validWeekDays.length - 1
    : first.getDay() - 1;       // [0,6] - first day of the week: monday

  for (let i = 0; i < maxDays; i++) {
    retval.push({
      field: '_' + (i + 1).toString(),
      titleKey: (i + 1) + '\n' + t(validWeekDays[((i) + weekDay) % validWeekDays.length]).substring(0, 2),
      required: false,
      removable: false,
      show: true,
      render: (rowData) => {
        const data = rowData['_' + (i + 1)];
        const color = rowData['colore' + (i + 1)] as ColorsKeys;

        const turnoNumeroAddettiInfo = presenzeDipendenti?.find(elem => {
          const month = new Date(elem.presenzaData).getMonth();
          const year = new Date(elem.presenzaData).getFullYear();
          return isEqual(new Date(elem.presenzaData), new Date(year, month, i + 1, 0, 0, 0, 0))
            && elem.idAnagraficaSoggetto === rowData['idAnagraficaSoggetto']
            && (elem.idTurno === rowData['idTurno_TurnoAssenza'] || (elem.idTurnoAssenza && elem.idTurnoAssenza * - 1) === rowData['idTurno_TurnoAssenza'])
        })

        return (
          <Tooltip title={
            <span style={{ fontSize: 12 }}>
              Struttura: {turnoNumeroAddettiInfo?.strutturaNome}<br />
              Dipartimento:{turnoNumeroAddettiInfo?.dipartimentoNome} <br />
              Unita Operativa: {turnoNumeroAddettiInfo?.unitaOperativaNome}  <br />
              Qualifica : {turnoNumeroAddettiInfo?.qualificaDescrizione}  <br />
              Turno: {turnoNumeroAddettiInfo?.turnoDescrizione ?? turnoNumeroAddettiInfo?.turnoAssenzaDescrizione}  <br />
              Inizio Turno : {turnoNumeroAddettiInfo?.oraInizioTurno}  <br />
              Fine Turno : {turnoNumeroAddettiInfo?.oraFineTurno} <br />
            </span>}
          >
            <Box
              bgcolor={Colors[color] /* + alpha */}
              minHeight={'32px'}
              pt={'6px'}
              fontWeight={'bold'}
              className={classes.hoverCell}
            >
              {data}
            </Box>
          </Tooltip>
        )

      }
    });
  }

  return retval;
}
