import { validateInputNumberMinMax, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type StrutturaTurnoOreKeys = keyof StrutturaTurnoOre;

export const elementIdProps: StrutturaTurnoOreKeys[] = ["idStruttura", "idTurno"];
export const elementRenderProps: StrutturaTurnoOreKeys[] = [];

export const allFields: Fields[] = [
  {
    field: "idTurno", required: true, show: true, titleKey: "shiftParam", editable: "onAdd",
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
  },
  {
    field: "ore", type: "numeric", required: false, minVal: 0, maxVal: 23,
    validate: (x: number, helperText: string) => validateInputNumberMinMax(x, 0, 23, false, helperText, true), keyTradValidation: "between0And23Validation",
    show: true, titleKey: "hoursParam"
  },
  {
    field: "minuti", type: "numeric", required: false, minVal: 0, maxVal: 59,
    validate: (x: number, helperText: string) => validateInputNumberMinMax(x, 0, 59, false, helperText, true), keyTradValidation: "between0And59Validation",
    show: true, titleKey: "minutesParam"
  }
]
export interface StrutturaTurnoOre {
  idStruttura: number,
  idTurno: number,
  ore: number,
  minuti: number,
  version: number
}

export interface StruttureTurniOreCompleto extends StrutturaTurnoOre {
  descrizioneBreve?: string;
  descrizione?: string;
}