import { Column } from "@material-table/core";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  pianificazioniPath
} from "../../../utils/utilconst";
import {
  AppBar,
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Tabs,
  Tab,
  Button,
  Paper,
  Tooltip,
} from "@material-ui/core";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { isEqual } from "date-fns";
import {
  fetchAllValid as fetchArticoliLeggeLookup,
  reset as resetArticoliLegge,
  resetError as resetErrorArticoliLegge
} from "../../../store/slices/articoliLeggeSlice";
import {
  fetchAllValid as fetchAssenzeLookup,
  reset as resetAssenze,
  resetError as resetErrorAssenze
} from "../../../store/slices/assenzeSlice";
import {
  lookup as fetchStruttureLookup,
  reset as resetStrutture,
  resetError as resetErrorStrutture
} from "../../../store/slices/struttureSlice";
import {
  lookupFiltered as fetchDipartimentiLookup,
  cleanLookup as cleanDipartimentiLookup,
  reset as resetDipartimentiFiltered,
  resetError as resetErrorDipartimentiFiltered
} from "../../../store/slices/dipartimentiFilteredSlice";
import {
  lookupFiltered as fetchUnitaOperativeLookup,
  cleanLookup as cleanUOLookup,
  reset as resetUnitaOperativeFiltered,
  resetError as resetErrorUnitaOperativeFiltered
} from "../../../store/slices/unitaOperativeFilteredSlice";
import {
  fetchAllValid as fetchTurniLookup,
  reset as resetTurni,
  resetError as resetErrorTurni
} from '../../../store/slices/turniSlice';
import {
  fetchAllValid as fetchQualificaLookup,
  reset as resetQualifiche,
  resetError as resetErrorQualifiche
} from '../../../store/slices/qualificheSlice';
import {
  fetchByYearAndMonth,
  update as reopenPianificazione,
  insert as saveNuovaPianificazione,
  reset as resetPianificazione,
  resetError as resetErrorPianificazioni
} from "../../../store/slices/pianificazioniSlice";
import {
  lookupByUnitaOperativa as fetchLookupByUO,
  reset as resetAnagraficaSoggettiUnitaOperative,
  resetError as resetErrorAnagraficaSoggettiUnitaOperative
} from '../../../store/slices/anagraficaSoggettiUnitaOperativeSlice';
import {
  allLookup as fetchLookupAllAnagraficaSoggetti,
  reset as resetAnagrificaSoggetti,
  resetError as resetErrorAnagraficaSoggetti
} from '../../../store/slices/anagraficaSoggettiSlice';

import { useHistory, Switch, Route, Redirect } from "react-router-dom";
import { componentTabsPath } from "../../../utils/innerFuncPaths";
import { Fields } from "../../../models/Fields";
import { daysInMonth, createLookup, getDateYYYYMMDD } from '../../../utils/utilfunctions';
import { Colors, ColorsKeys, MonthsList, WeekList } from '../../../utils/utildata';

import AnagraficaSoggettiList, { AS } from './pianificazioniComponents/AnagraficaSoggettiList/AnagraficaSoggettiList';
import Calendar from './pianificazioniComponents/Calendar/Calendar';
import i18n from "../../../i18n";
import { it, enGB } from "date-fns/locale";
import ReadOnlyMaterialTable from "../tables/ReadOnly/ReadOnlyMaterialTable";
import {
  allFieldsAnagraficaSoggetto as allFieldsPianificazioneAS,
  allFieldsQualifica as allFieldsPianificazioneQualifica,
  allFieldsStatoPianificazione,
  Pianificazione,
  PianificazioneKeys,
  PianificazioneQualifica,
  PianificazioneQualificaKeys,
  PianificazioniAnagraficaSoggetti,
} from "../../../models/Pianificazioni";
import { IdsConfig, PDFExtraData, StatusEnum, } from "../../../models/Utils";
import GeneralForm from "../forms/GeneralForm";
import { sideBarWidth } from "../../../utils/styleconst";
import { ClassNameMap } from "@material-ui/styles";
import LoadingSvg from "../svgs/LoadingSvg";
import TabPanel from "../tabs/TabPanel/TabPanel";
import { fetchAllValidByFilter as fetchAllValidTurniAddetti } from "../../../store/slices/unitaOperativeTurniNumeroAddettiSlice";
import useCalendar from "./pianificazioniComponents/Calendar/useCalendar";
import InsertTurniModal from "./pianificazioniComponents/Calendar/InsertTurniModal";
import { fetchAllValidById as fetchTurniAbilitati } from '../../../store/slices/anagraficaSoggettiTurnoAbilitatoSlice';

type TipoInserimento = 'Manuale' | 'Calcolato' | null;

const ERROR_VALUE = -1;

function isUrlOnUpdate(url: string) {
  return url === pianificazioniPath + componentTabsPath;
}

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    h55: {
      height: 55
    },
    hCell: {
      height: 120
    },
    flexGrow: {
      flexGrow: 1,
    },
    appbar: {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    indicator: {
      backgroundColor: "#fff",
    },
    warning: {
      color: "#d00",
      fontWeight: "bold"
    },
    hoverCell: {
      '&:hover': {
        filter: 'brightness(0.9)'
      }
    },
    cell: {
      '&:hover': {
        filter: 'brightness(0.9)',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
      },
      cursor: 'pointer'
    }
  }),
);

interface StatoPianificazione {
  oreLavorativeMese: number,
  numeroRiposiMese: number,
  stato: string,
}

export interface IdsConfigExtended extends IdsConfig {
  reperibilita?: boolean
}

export interface RiepilogoPianificazioneDTO {
  idStruttura: number;           // contiene idStruttura e vuoto nelle righe del riepilogo di qualifica
  idAnagraficaSoggetto: number;  // contiene idAnagraficaSoggetto e vuoto nelle righe del riepilogo di qualifica
  idQualifica: number;
  qualificaDescrizione?: string;
  qualificaDescrizioneBreve: string,
  nominativo: string;             // contiene nominativo e turnoDescrizione
  cognome: string;
  nome: string;
  turniObj: IdsConfigExtended[][],
  quantita: string[],
  qualificheIds: number[][],
  coloreAnagraficaSoggetto: 'Verde' | 'Giallo' | 'Rosso' | 'Bianco',
  coloreQualifica: Array<'Verde' | 'Giallo' | 'Rosso' | 'Arancione' | 'Bianco'>,
  oreTotale?: number;
  minutiTotale?: number,
  numeroRiposi?: number;
  turnoFisso: boolean;
  modificaTurnoFisso: boolean;
  numeroLegaleUnitaOperativa: number[];
  numeroAddettiUnitaOperativa: number[];
  numeroReperibilitaRichieste: number[];
  tipoInserimento: TipoInserimento[];        // Nel caso di Riepilogo qualifica, il valore è null
}

export interface RiepilogoPianificazioneQualificheDTO {
  idQualifica: number;
  qualificaDescrizione: string;
  qualificaDescrizioneBreve: string;
  turnoDescrizione: string;
  quantita: string[];

  colore: Array<'Verde' | 'Giallo' | 'Rosso' | 'Arancione'>;
}

export interface MappaTurniOre {
  [turno: string]: {
    ore: number,
    minuti: number
  };
}

enum TabIndex {
  ANAGRAFICA,
  QUALIFICA,
  MODIFICA,
}

const PianificazioniW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector((state) => state.authInfo.logoUri);
  const classes = useStyles1();
  const appbarOpenStatus = useAppSelector(state => state.appbar.open);

  const [selectedMonth, setSelectedMonth] = useState<Date | null>(new Date());
  const [selectedAnagraficaSoggetto, setSelectedAnagraficaSoggetto] = useState<number>(ERROR_VALUE);

  const strSelectName = useMemo(() => t("structuresTitle"), [t]);
  const strSelectLabel = useMemo(() => t("structureSelect"), [t]);
  const dipSelectName = useMemo(() => t("departmentsTitle"), [t]);
  const dipSelectLabel = useMemo(() => t("departmentSelect"), [t]);
  const unitOpSelectName = useMemo(() => t("operatingUnitTitle"), [t]);
  const unitOpSelectLabel = useMemo(() => t("operatingUnitSelect"), [t]);
  const monthYearSelectName = useMemo(() => t("dateTitle"), [t]);
  const monthYearSelectLabel = useMemo(() => t("monthSelect"), [t]);

  const [states, setStates] = useState<{
    [selectName: string]: number | string | null;
  }>({
    [strSelectName]: null,
    [dipSelectName]: null,
    [unitOpSelectName]: null,
    [monthYearSelectName]: null,
  });

  const fixedProps = useMemo(() => {
    const _monthSelected = states[monthYearSelectName];

    const selectedDate: Date | null = _monthSelected ? new Date(_monthSelected) : null;

    return {
      idStruttura: states[strSelectName] as number,
      idDipartimento: states[dipSelectName] as number,
      idUnitaOperativa: states[unitOpSelectName] as number,
      anno: selectedDate ? selectedDate.getFullYear() : ERROR_VALUE,
      mese: selectedDate ? selectedDate.getMonth() + 1 : ERROR_VALUE,
      // presenzaData: getDateYYYYMMDD_BackEnd(selectedDate)
    };
  }, [states, strSelectName, dipSelectName, unitOpSelectName, monthYearSelectName]);

  const idAnagraficaSoggettoConst = "idAnagraficaSoggetto";
  const idTurnoAssenzaConst = "idTurnoAssenza";
  const idArticoloLeggeConst = "idArticoloLegge";
  const idTurnoConst = "idTurno";
  const idQualificaConst = "idQualifica";

  const lookupStr = useAppSelector((state) => state.strutture.lookup);
  const lookupDip = useAppSelector((state) => state.dipartimentiFiltered.lookup);
  const lookupUnitOp = useAppSelector((state) => state.unitaOperativeFiltered.lookup);
  const lookupTurnoAssenza = useAppSelector((state) => state.assenze.lookupAssenzaProgrammata);
  const lookupTurni = useAppSelector(state => state.turni.lookupDescrizione);
  const lookupQualificheDescrizione = useAppSelector(state => state.qualifiche.lookupDescrizione);
  const lookupQualificheDescrizioneBreve = useAppSelector(state => state.qualifiche.lookupDescrizioneBreve);
  const _lookupAnagraficaSoggettoPerUnitaOperativa = useAppSelector((state) => state.anagraficaSoggettiUnitaOperative.lookup);

  const lookupAnagraficaSoggettoPerUnitaOperativa = useMemo(() => {
    return { ..._lookupAnagraficaSoggettoPerUnitaOperativa }
  }, [_lookupAnagraficaSoggettoPerUnitaOperativa]);

  const lookupArticoloLeggeTemp = useAppSelector((state) => state.articoliLegge.lookup);
  const lookupArticoloLegge = useMemo(() => {
    return { null: t("fieldNullSelect"), ...lookupArticoloLeggeTemp };
  }, [lookupArticoloLeggeTemp, t]);

  const qualificheAbilitateFiltered = useAppSelector(state => state.anagraficaSoggettiQualifica.qualificheAbilitate);
  const turniAbilitatiFiltered = useAppSelector(state => state.anagraficaSoggettiTurnoAbilitato.turniAbilitati);
  const validAssenze = useAppSelector(state => state.assenze.validAssenze);

  const validUnitaOperativaPianificazione = useAppSelector(state => state.pianificazione.validUnitaOperativaPianificazioni);
  const validPianificazione = useAppSelector(state => state.pianificazione.validPianificazioni);
  const validPianificazioneQualifiche = useAppSelector(state => state.pianificazione.validPianificazioniQualifiche);
  const validPianificazioneAnagraficaSoggetti = useAppSelector(state => state.pianificazione.validPianificazioniAnagraficaSoggetti);
  const initPianificazione = useAppSelector(state => state.pianificazione.initPianificazione);
  const salvato = useAppSelector(state => state.pianificazione.saved);

  const statoPianificazioneTranslation = useAppSelector(state => state.lookup.statoPianificazioneTranslation);
  const statoPianificazioneMap = useAppSelector(state => state.lookup.statoPianificazioneMap);

  const [currentStatoPianificazione, setCurrentStatoPianificazione] = useState<StatoPianificazione>();
  const [currentValidPianificazione, setCurrentValidPianificazione] = useState<RiepilogoPianificazioneDTO[]>([]);
  const [currentValidPianificazioneQualifica, setCurrentValidPianificazioneQualifica] = useState<RiepilogoPianificazioneQualificheDTO[]>([]);
  const [currentSelectedAS, setCurrentSelectedAS] = useState<RiepilogoPianificazioneDTO | null>(null);

  const [dateFestivi, setDateFestivi] = useState<number[]>([]);

  // stati del buttone 'Approva'
  const [approvato, setApprovato] = useState<boolean>(false);
  // const [salvato, setSalvato] = useState<boolean>(false);

  useEffect(() => {
    setApprovato(validUnitaOperativaPianificazione?.stato === statoPianificazioneMap.APPROVATO);
  }, [statoPianificazioneMap.APPROVATO, validUnitaOperativaPianificazione?.stato]);

  // lista delle anagrafiche nella pianificazione
  const [listAS, setListAS] = useState<AS[]>([]);

  const [serializedInitPianificazione, setSerializedInitPianificazione] = useState<string>('');
  useEffect(() => {
    setSerializedInitPianificazione(JSON.stringify(initPianificazione));
  }, [initPianificazione]);

  // aggiorna lo stato della pianificazione ad ogni modifica sul calendario
  useEffect(() => {
    setCurrentStatoPianificazione(state => {
      if (validUnitaOperativaPianificazione?.stato) {
        const statoLabel: string = statoPianificazioneTranslation[validUnitaOperativaPianificazione?.stato];
        return {
          numeroRiposiMese: validUnitaOperativaPianificazione?.numeroRiposiMese,
          oreLavorativeMese: validUnitaOperativaPianificazione?.oreLavorativeMese,
          stato: t(statoLabel),
        } as StatoPianificazione;
      }

      return state
    })
  }, [statoPianificazioneTranslation, t, validUnitaOperativaPianificazione?.numeroRiposiMese, validUnitaOperativaPianificazione?.oreLavorativeMese, validUnitaOperativaPianificazione?.stato]);

  /**
   * Tabs
   */
  const [tabValue, setTabValue] = useState<TabIndex>(TabIndex.ANAGRAFICA);
  const handleTabIndexChange = (event: React.ChangeEvent<{}>, newValue: TabIndex) => {
    setTabValue(newValue);
    setCurrentSelectedAS(null);
  };
  const handleTabChange = (index: TabIndex) => {
    return {
      id: 'tab-' + index,
      'aria-controls': 'tabpanel-' + index,
    };
  }

  /**
   * resettare selected AS quando cambia tab
   * per non mostrare il calendario se non
   * è stato selezionato un soggetto in tab di modifica
   */
  // useEffect(() => {
  //   if ([TabIndex.ANAGRAFICA, TabIndex.QUALIFICA].includes(tabValue)) {
  //     setSelectedAnagraficaSoggetto(ERROR_VALUE)
  //   }
  // }, [tabValue]);

  /**
   * Initial API calls
   */
  useEffect(() => {
    dispatch(fetchStruttureLookup());
    dispatch(fetchAssenzeLookup());
    dispatch(fetchArticoliLeggeLookup());
    dispatch(fetchTurniLookup());
    dispatch(fetchQualificaLookup());
    dispatch(fetchLookupAllAnagraficaSoggetti());
  }, [dispatch]);

  const resetError = useCallback(() => {
    dispatch(resetErrorArticoliLegge());
    dispatch(resetErrorAssenze());
    dispatch(resetErrorStrutture());
    dispatch(resetErrorDipartimentiFiltered());
    dispatch(resetErrorUnitaOperativeFiltered());
    dispatch(resetErrorTurni());
    dispatch(resetErrorQualifiche());
    dispatch(resetErrorPianificazioni());
    dispatch(resetErrorAnagraficaSoggettiUnitaOperative());
    dispatch(resetErrorAnagraficaSoggetti());
  }, [dispatch]);

  useEffect(() => {
    resetError()
  }, [resetError, tabValue]);

  const [isFixedPropsChanged, setIsFixedPropsChanged] = useState(false);
  useEffect(() => {
    setIsFixedPropsChanged(true);
  }, [fixedProps.idStruttura, fixedProps.idDipartimento, fixedProps.idUnitaOperativa, fixedProps]);

  /**
   * Ottiene i riepiloghi per pianificazioneAnagraficaSoggetti e pianificazioneQualifiche
   */
  useEffect(() => {
    if (validPianificazione && ERROR_VALUE !== fixedProps.mese && ERROR_VALUE !== fixedProps.anno)
      setCurrentValidPianificazione(
        createRiepilogoDatiAnagraficaSoggetto(
          validPianificazione,
          validPianificazioneAnagraficaSoggetti,
          validPianificazioneQualifiche,
          fixedProps.mese,
          fixedProps.anno,
          tabValue
        )
      );

    if (validPianificazioneQualifiche && ERROR_VALUE !== fixedProps.mese && ERROR_VALUE !== fixedProps.anno)
      setCurrentValidPianificazioneQualifica(createRiepilogoDatiQualifica(validPianificazioneQualifiche, fixedProps.mese, fixedProps.anno));
  }, [fixedProps.anno, fixedProps.mese, tabValue, validPianificazione, validPianificazioneAnagraficaSoggetti, validPianificazioneQualifiche]);

  useEffect(() => {
    const SUNDAY = 0;
    const SATURDAY = 6;
    let dateFestivi = validPianificazione
      ?.filter(elem => elem.giornoFestivo || [SUNDAY, SATURDAY].includes(new Date(elem.pianificazioneData).getDay()))
      .map(elem => new Date(elem.pianificazioneData).getDate());

    dateFestivi = [...new Set(dateFestivi ?? [])];
    setDateFestivi(dateFestivi);
  }, [validPianificazione]);

  /**
   * Data fetch
   */
  useEffect(() => {
    if (fixedProps.idStruttura && fixedProps.idDipartimento && fixedProps.idUnitaOperativa && ERROR_VALUE !== fixedProps.anno && ERROR_VALUE !== fixedProps.mese && isFixedPropsChanged) {
      setListAS([]);
      setTabValue(TabIndex.ANAGRAFICA);
      dispatch(resetPianificazione());

      dispatch(fetchByYearAndMonth({
        idStruttura: Number(fixedProps.idStruttura),
        idDipartimento: Number(fixedProps.idDipartimento),
        idUnitaOperativa: Number(fixedProps.idUnitaOperativa),
        anno: fixedProps.anno,
        mese: fixedProps.mese,
      }));
    }
    isFixedPropsChanged && setIsFixedPropsChanged(false);
  }, [dispatch, fixedProps.anno, fixedProps.idDipartimento, fixedProps.idStruttura, fixedProps.idUnitaOperativa, fixedProps.mese, isFixedPropsChanged]);

  /**
   * Lookup fetch
   */
  useEffect(() => {
    if (states[strSelectName] && states[dipSelectName] && states[unitOpSelectName]) {
      dispatch(fetchLookupByUO({
        idStruttura: states[strSelectName] as number,
        idDipartimento: states[dipSelectName] as number,
        idUnitaOperativa: states[unitOpSelectName] as number,
      }));
      dispatch(fetchAllValidTurniAddetti({
        idStruttura: states[strSelectName] as number,
        idDipartimento: states[dipSelectName] as number,
        idUnitaOperativa: states[unitOpSelectName] as number,
      }));
    }
    if (states[strSelectName] && states[dipSelectName]) {
      dispatch(
        fetchUnitaOperativeLookup({
          idStruttura: states[strSelectName] as number,
          idDipartimento: states[dipSelectName] as number,
        })
      );
    }
    if (states[strSelectName]) {
      dispatch(
        fetchDipartimentiLookup({
          idStruttura: states[strSelectName] as number,
        })
      );
    } else {
      dispatch(fetchStruttureLookup());
    }
  }, [states, strSelectName, dipSelectName, unitOpSelectName, dispatch]);

  /**
   * Used by InsertTurniModal
   */
  const [currentSelectedDate, setCurrentSelectedDate] = useState<Date | null>(new Date());
  const [openTurniModal, setOpenTurniModal] = useState<boolean>(false);
  const [selectedDateIndex, setSelectedDateIndex] = useState<number>(0);

  const [dateToShow, setDateToShow] = useState<Date[]>([]);

  const excludedFieldInTable: string[] = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFieldsPianificazioneAS);
  const [columnsQualifica, setColumnsQualifica] = useState<Column<PianificazioneQualifica>[]>([]);
  const [columnsAnagraficaSoggetto, setColumnsAnagraficaSoggetto] = useState<Column<Pianificazione>[]>([]);
  useEffect(() => {
    /**
     * Column per PianificazioneQualifica
     */
    if (tabValue === TabIndex.ANAGRAFICA) {
      const selectedAS = createLookup(listAS, 'idAnagraficaSoggetto', ['nominativo'])

      if (ERROR_VALUE !== fixedProps.mese && ERROR_VALUE !== fixedProps.anno) {
        setColumnsAnagraficaSoggetto(
          reworkPianificazioneFieldsNoBgColor(allFieldsPianificazioneAS, fixedProps.mese, fixedProps.anno, t)
            .filter(f => !excludedFieldInTable.includes(f.field))
            .map((f) => {
              let obj: Column<Pianificazione> = {
                title: f.titleKey ? t(f.titleKey) : "",
                field: f.field,
                removable: f.removable ?? !f.required,
                editable: f.editable ? f.editable : "always",
                defaultSort: f.sort,
                emptyValue: f.defaultValue ?? 'N/A',
              };

              if (f.validate2) {
                obj.validate = (rowData) => {
                  let resp: boolean | { isValid: boolean, helperText: string } = false;
                  let resp2: boolean | { isValid: boolean, helperText: string } = false;

                  if (f.validate2)
                    resp2 = f.validate2(
                      rowData[f.field as PianificazioneKeys],
                      rowData[f.field2Validation as PianificazioneKeys],
                      f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
                    );

                  if (f.validate) {
                    resp = f.validate(
                      rowData[f.field as PianificazioneKeys],
                      f.keyTradValidation ? t(f.keyTradValidation) : ''
                    );
                  }
                  if (resp === true) {
                    return resp2;
                  } else return resp;
                }
              } else if (f.validate) {
                obj.validate = (rowData) => {
                  if (f.validate)
                    return f.validate(
                      rowData[f.field as PianificazioneKeys],
                      f.keyTradValidation ? t(f.keyTradValidation) : ''
                    );
                  return false;
                }
              }

              if (!f.show) {
                obj.hidden = true;
                obj.hiddenByColumnsButton = false;
              }
              if (f.type && f.type !== "image" && f.type !== "file") {
                obj.type = f.type;
              }

              if (f.render) {
                obj.render = f.render;
              }

              if (!['nominativo', 'oreTotale', 'numeroRiposi', 'qualificaDescrizioneBreve'].includes(f.field)) {
                obj.render = (rowData: Record<string, any>) => {

                  if (rowData.idStruttura === -1) {
                    /**
                     * Render pianificazioni qualifiche
                    */
                    if (f.field.startsWith('_')) {
                      const colorIndex = f.field.replace('_', 'colore');
                      const index = Number(f.field.replace('_', '')) - 1;

                      const data = rowData[f.field];
                      const color = rowData[colorIndex];

                      return (
                        <Tooltip title={<span style={{ fontSize: 12 }}>
                          Dotazione Minima: {rowData?.numeroLegaleUnitaOperativa[index] ?? ''}<br />
                          Dotazione Ottimale: {rowData?.numeroAddettiUnitaOperativa[index] ?? ''} <br />
                          Reperibilità Richieste: {rowData?.numeroReperibilitaRichieste[index] ?? ''}
                        </span>}
                        >
                          <Box
                            bgcolor={Colors[color as ColorsKeys] === '#FFFFFF' ? 'transparent' : Colors[color as ColorsKeys]}
                            minHeight={'50px'}
                            pt={'6px'}
                            fontWeight={'bold'}
                            className={classes.hoverCell}
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                          >
                            {data}
                          </Box>
                        </Tooltip>
                      )
                    }
                  } else {
                    /**
                     * Render pianificazioni anagraficaSoggetti
                     */
                    const onClick = () => {
                      const dateIndex = Number(f.field.replace('_', '')) - 1;
                      const _currentSelectedDate = dateToShow[dateIndex];

                      setCurrentSelectedDate(_currentSelectedDate)
                      setSelectedDateIndex(dateIndex);
                      setOpenTurniModal(true);

                      const fetchTurniLookupAsync = async () => {
                        if (selectedAnagraficaSoggetto !== rowData.idAnagraficaSoggetto) {
                          setSelectedAnagraficaSoggetto(rowData.idAnagraficaSoggetto);
                          await dispatch(fetchTurniAbilitati([fixedProps.idStruttura, rowData.idAnagraficaSoggetto, getDateYYYYMMDD(_currentSelectedDate) + 'T00:00:00']));
                        }
                      }

                      fetchTurniLookupAsync();
                    }

                    const index = isNaN(Number(f.field.replace('_', ''))) ? null : Number(f.field.replace('_', '')) - 1;

                    return <Box
                      onClick={onClick}
                      className={classes.cell}
                      width='100%'
                      height='50px'
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      bgcolor={(index != null && rowData.tipoInserimento?.[index]) === 'Manuale' ? 'pink' : undefined}
                    >
                      {(rowData as any)[f.field]}
                    </Box>
                  }
                }
              }

              if (['qualificaDescrizioneBreve'].includes(f.field)) {
                obj.width = 300;
                obj.cellStyle = {
                  backgroundColor: '#fff'
                }
              } else if (['nominativo'].includes(f.field)) {
                obj.cellStyle = {
                  padding: 0,
                  position: "sticky",
                  left: 0,
                  zIndex: 10,
                  backgroundColor: '#ccc',
                }
                obj.headerStyle = {
                  position: "sticky",
                  left: 0,
                  backgroundColor: "#ccc",
                  zIndex: 11,
                  minWidth: 300
                };
              } else if (['oreTotale'].includes(f.field)) {
                obj.cellStyle = {
                  padding: 0,
                  position: "sticky",
                  left: 299,
                  zIndex: 10,
                  boxSizing: 'inherit',
                  backgroundColor: '#fff',
                }
                obj.headerStyle = {
                  position: "sticky",
                  left: 299,
                  backgroundColor: "#fff",
                  zIndex: 11,
                  minWidth: 75,
                };
              } else if (['numeroRiposi'].includes(f.field)) {
                obj.cellStyle = {
                  padding: 0,
                  position: "sticky",
                  left: 370,
                  zIndex: 10,
                  boxSizing: 'inherit',
                  backgroundColor: '#fff',
                }
                obj.headerStyle = {
                  position: "sticky",
                  left: 370,
                  backgroundColor: "#fff",
                  zIndex: 11,
                  minWidth: 75,
                };
              } else {
                obj.width = 50;
                obj.align = 'center';
                obj.cellStyle = {
                  borderStyle: 'solid',
                  borderColor: '#e0e0e0',
                  borderWidth: 1,
                  backgroundColor: dateFestivi.includes(Number(f.field.substring(1))) ? '#cecece' : undefined,
                  padding: 0,
                }

              }

              /*+++*/
              switch (f.field) {
                case idAnagraficaSoggettoConst:
                  obj.lookup = { ...lookupAnagraficaSoggettoPerUnitaOperativa, ...selectedAS };
                  break;
              }
              /*+++*/

              if (f.defaultGroupOrder !== null || f.defaultGroupOrder !== undefined) {
                obj.defaultGroupOrder = f.defaultGroupOrder;
              }

              return obj;
            })
        );
      }
    } else if (tabValue === TabIndex.QUALIFICA) {
      if (ERROR_VALUE !== fixedProps.mese && ERROR_VALUE !== fixedProps.anno) {
        setColumnsQualifica(
          reworkPianificazioneFieldsBgColor(allFieldsPianificazioneQualifica, validPianificazioneQualifiche, fixedProps.mese, fixedProps.anno, t, classes)
            .filter(f => !excludedFieldInTable.includes(f.field))
            .map((f) => {
              let obj: Column<PianificazioneQualifica> = {
                title: f.titleKey ? t(f.titleKey) : "",
                field: f.field,
                removable: f.removable ?? !f.required,
                editable: f.editable ? f.editable : "always",
                defaultSort: f.sort ? f.sort : undefined,
                emptyValue: f.defaultValue ?? 'N/A',
              };

              if (f.validate2) {
                obj.validate = (rowData) => {
                  let resp: boolean | { isValid: boolean, helperText: string } = false;
                  let resp2: boolean | { isValid: boolean, helperText: string } = false;

                  if (f.validate2)
                    resp2 = f.validate2(
                      rowData[f.field as PianificazioneQualificaKeys],
                      rowData[f.field2Validation as PianificazioneQualificaKeys],
                      f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
                    );

                  if (f.validate) {
                    resp = f.validate(
                      rowData[f.field as PianificazioneQualificaKeys],
                      f.keyTradValidation ? t(f.keyTradValidation) : ''
                    );
                  }
                  if (resp === true) {
                    return resp2;
                  } else return resp;
                }
              } else if (f.validate) {
                obj.validate = (rowData) => {
                  if (f.validate)
                    return f.validate(
                      rowData[f.field as PianificazioneQualificaKeys],
                      f.keyTradValidation ? t(f.keyTradValidation) : ''
                    );
                  return false
                }
              }

              if (!f.show) {
                obj.hidden = true;
                obj.hiddenByColumnsButton = false;
              }
              if (f.type && f.type !== "image" && f.type !== "file") {
                obj.type = f.type;
              }

              if (f.render) {
                obj.render = f.render;
              }

              if (['qualificaDescrizioneBreve'].includes(f.field)) {
                obj.width = 300;
              } else if (['turnoDescrizione'].includes(f.field)) {
                obj.width = 200;
              } else {
                obj.width = 50;
                obj.align = 'center';
                obj.cellStyle = {
                  borderStyle: 'solid',
                  borderColor: '#e0e0e0',
                  borderWidth: 1,
                  padding: 0,
                }
              }

              if (f.defaultGroupOrder !== null || f.defaultGroupOrder !== undefined) {
                obj.defaultGroupOrder = f.defaultGroupOrder;
              }

              /*+++*/
              switch (f.field) {
                case idAnagraficaSoggettoConst:
                  obj.lookup = lookupAnagraficaSoggettoPerUnitaOperativa;
                  break;
              }
              /*+++*/
              return obj;
            })
        );
      }
    }
  }, [t, lookupTurnoAssenza, lookupArticoloLegge, lookupAnagraficaSoggettoPerUnitaOperativa, lookupUnitOp, lookupTurni, allFieldsState, excludedFieldInTable, tabValue, fixedProps.mese, fixedProps.anno, listAS, dateFestivi, validPianificazioneQualifiche, classes, dateToShow, dispatch, selectedAnagraficaSoggetto, fixedProps.idStruttura]);

  const errorBE = useAppSelector((state) => state.pianificazione.error);
  const validPianificazioni = useAppSelector((state) => state.pianificazione.validUnitaOperativaPianificazioni);
  const statusValidUnitaOperativaPianificazioni = useAppSelector((state) => state.pianificazione.statusValidUnitaOperativaPianificazioni);
  const statusSavePianificazione = useAppSelector((state) => state.pianificazione.statusSavePianificazione);

  const [dataPianificazioniAnagraficaSoggetto, setDataPianificazioniAnagraficaSoggetto] = useState<RiepilogoPianificazioneDTO[]>([]);
  const [dataPianificazioniQualifica, setDataPianificazioniQualifica] = useState<RiepilogoPianificazioneQualificheDTO[]>([]);


  const [collezioneCurrentTurniEOre, setCollezioneCurrentTurniEOre] = useState<MappaTurniOre>({});
  useEffect(() => {
    const mappaTurniOre: Record<string, unknown> = {};
    const getMappaTurniOre = () => {
      validPianificazione.forEach(elem => {
        if (elem.turnoDescrizioneBreve && !mappaTurniOre[elem.turnoDescrizioneBreve] && elem.idAnagraficaSoggetto === selectedAnagraficaSoggetto) {
          mappaTurniOre[elem.turnoDescrizioneBreve] = {
            ore: elem.oreTurno,
            minuti: elem.minutiTurno
          };
        } else if (elem.turnoAssenzaDescrizioneBreve && !mappaTurniOre[elem.turnoAssenzaDescrizioneBreve]) {
          mappaTurniOre[elem.turnoAssenzaDescrizioneBreve] = {
            ore: elem.oreTurno,
            minuti: elem.minutiTurno
          };
        }
      });
    }
    getMappaTurniOre();
    setCollezioneCurrentTurniEOre(mappaTurniOre as MappaTurniOre);
  }, [selectedAnagraficaSoggetto, validPianificazione]);

  /**
   * Prepare data for Material Table
   */
  useEffect(() => {
    let newDataAS: RiepilogoPianificazioneDTO[] = []
    let newRiepilogoSoggetto: Partial<RiepilogoPianificazioneDTO>[] = []
    switch (tabValue) {
      case TabIndex.ANAGRAFICA:
        /**
         * validPianificazioniAnagraficaSoggetto #############################################################
         */
        newDataAS = currentValidPianificazione;

        newRiepilogoSoggetto = newDataAS.map((elem: RiepilogoPianificazioneDTO) => {
          const temp: Record<string, string | number | undefined | number[] | TipoInserimento[]> = {
            idStruttura: elem.idStruttura,
            idAnagraficaSoggetto: elem.idAnagraficaSoggetto,
            nominativo: elem.nominativo,
            oreTotale: elem.oreTotale,
            minutiTotale: elem.minutiTotale,
            numeroRiposi: elem.numeroRiposi,
            qualificaDescrizione: elem.qualificaDescrizione,
            qualificaDescrizioneBreve: elem.qualificaDescrizioneBreve,
            coloreAnagraficaSoggetto: elem.coloreAnagraficaSoggetto,
            numeroAddettiUnitaOperativa: elem.numeroAddettiUnitaOperativa,
            numeroLegaleUnitaOperativa: elem.numeroLegaleUnitaOperativa,
            numeroReperibilitaRichieste: elem.numeroReperibilitaRichieste,
            tipoInserimento: elem.tipoInserimento
          }

          if (elem.idStruttura !== -1) {
            elem.turniObj.forEach((turni, index) => {
              let val = '';
              turni.forEach(turno => {
                val += (val !== '' ? '+' : '') + turno.descrizioneBreve
              });
              temp['_' + (index + 1)] = val;
            });
          } else {
            elem.quantita.forEach((quantita, index) => {
              temp['_' + (index + 1)] = quantita;
            });
            elem.coloreQualifica.forEach((coloreQualifica, index) => {
              temp['colore' + (index + 1)] = coloreQualifica;
            });
          }

          return temp;
        });
        setDataPianificazioniAnagraficaSoggetto(newRiepilogoSoggetto as RiepilogoPianificazioneDTO[]);
        break;
      case TabIndex.QUALIFICA:
        /**
         * validPianificazioniQualifica #############################################################
         */
        const newDataQualifica: RiepilogoPianificazioneQualificheDTO[] = currentValidPianificazioneQualifica;

        const newRiepilogoQualifiche: Partial<RiepilogoPianificazioneQualificheDTO>[] = newDataQualifica.map((elem: RiepilogoPianificazioneQualificheDTO) => {
          const temp: Record<string, string | number | undefined> = {
            qualificaDescrizioneBreve: elem.qualificaDescrizioneBreve,
            turnoDescrizione: elem.turnoDescrizione,
          }

          elem.quantita.forEach((val, index) => {
            temp['_' + (index + 1)] = val;
            temp['colore' + (index + 1)] = elem.colore[index];
          })

          return temp;
        });
        setDataPianificazioniQualifica(newRiepilogoQualifiche as RiepilogoPianificazioneQualificheDTO[]);
        break;
      case TabIndex.MODIFICA:
        newDataAS = currentValidPianificazione;

        newRiepilogoSoggetto = newDataAS.map((elem: RiepilogoPianificazioneDTO) => {
          const temp: Record<string, string | number | undefined> = {
            idAnagraficaSoggetto: elem.idAnagraficaSoggetto,
            nominativo: elem.nominativo,
            oreTotale: elem.oreTotale,
            minutiTotale: elem.minutiTotale,
            numeroRiposi: elem.numeroRiposi,
          }

          elem.turniObj.forEach((turni, index) => {

            temp['colore'] = elem.coloreAnagraficaSoggetto;

            let val = '';
            turni.forEach(turno => {
              val += (val !== '' ? '+' : '') + turno.descrizioneBreve
            });
            temp['_' + (index + 1)] = val;
          })

          return temp;
        });
        setDataPianificazioniAnagraficaSoggetto(newRiepilogoSoggetto as RiepilogoPianificazioneDTO[]);
        break;
    }
  }, [collezioneCurrentTurniEOre, currentValidPianificazione, currentValidPianificazioneQualifica, fixedProps.anno, fixedProps.mese, lookupTurni, t, tabValue, validPianificazione, validPianificazioneQualifiche, validPianificazioni, validUnitaOperativaPianificazione?.stato]);

  const handleDateChange = (d: Date | null) => {
    let fieldDate: string = "";
    if (d) {
      let month = (d.getMonth() + 1).toString().padStart(2, '0');
      let day = d.getDate().toString().padStart(2, '0');
      fieldDate = d.getFullYear() + "-" + month + "-" + day + "T00:00:00";
    }
    setStates(prev => { return { ...prev, [monthYearSelectName]: fieldDate } });
    d && setSelectedMonth(new Date(d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()));
    setSelectedAnagraficaSoggetto(ERROR_VALUE);
    setAS_selectedMenu(0);
  };

  const setInternalObjectRef = useRef<{ internalStateSetter: Dispatch<SetStateAction<Object>>, formObject: Object, field: string } | null>(null);
  useEffect(() => {
    const oreTurnoConst = 'oreTurno';
    const defaultValues = fixedProps;

    if (setInternalObjectRef.current) {

      const setter = setInternalObjectRef.current.internalStateSetter;
      const formObject: Record<string, any> = setInternalObjectRef.current.formObject;
      const field = setInternalObjectRef.current.field;

      setter(state => {
        let newState: Record<string, any> = { state };
        switch (field) {
          case idTurnoConst:
            setAllFieldsState(state => {
              state.forEach(elem => {
                if (elem.field === idArticoloLeggeConst)
                  elem.readonly = true;
              });
              return state;
            });

            const selectedTurno = turniAbilitatiFiltered.find(elem => elem.idTurno === Number(formObject[idTurnoConst]));
            newState = { ...state, [oreTurnoConst]: selectedTurno ? selectedTurno.ore : '' };

            if (newState[idTurnoAssenzaConst]) delete newState[idTurnoAssenzaConst];
            if (newState[idArticoloLeggeConst]) delete newState[idArticoloLeggeConst];
            break;
          case idTurnoAssenzaConst:
            setAllFieldsState(state => {
              state.forEach(elem => {
                if (elem.field === idArticoloLeggeConst)
                  delete elem.readonly;
              });
              return state;
            });
            const selectedAssenza = validAssenze.find(elem => elem.idTurnoAssenza === Number(formObject[idTurnoAssenzaConst]));
            newState = { ...state, [oreTurnoConst]: selectedAssenza ? selectedAssenza.ore : '' };
            if (newState[idTurnoConst]) delete newState[idTurnoConst];
            break;
          case idAnagraficaSoggettoConst:
            const defaultQualifica = qualificheAbilitateFiltered.find(elem => elem.selected);
            newState = {
              ...defaultValues,
              idAnagraficaSoggetto: formObject[idAnagraficaSoggettoConst],
              reperibilita: false,
              [idQualificaConst]: defaultQualifica ? defaultQualifica[idQualificaConst] : '',
            };
            break;
        }
        return newState;
      });
    }
  }, [fixedProps, qualificheAbilitateFiltered, turniAbilitatiFiltered, validAssenze]);

  // list of AS
  useEffect(() => {
    switch (tabValue) {
      case TabIndex.ANAGRAFICA:
        break;
      case TabIndex.QUALIFICA:
        break;
      case TabIndex.MODIFICA:
        setListAS(state => {
          const listSoggetti: Array<AS> = [];

          if (state.length === 0) {
            validPianificazione.forEach((soggetto) => {
              if (listSoggetti.some(elem => elem.idAnagraficaSoggetto === soggetto.idAnagraficaSoggetto)) {
                const index = listSoggetti.findIndex(elem => elem.idAnagraficaSoggetto === soggetto.idAnagraficaSoggetto)
                const qualificheDescrizioneList = listSoggetti[index].qualificheDescrizione;

                if (qualificheDescrizioneList?.every(elem => elem.id !== soggetto.idQualifica)) {
                  qualificheDescrizioneList.push({
                    id: soggetto.idQualifica,
                    name: soggetto.qualificaDescrizione,
                  });
                  qualificheDescrizioneList?.push({
                    id: soggetto.idQualifica,
                    name: soggetto.qualificaDescrizioneBreve,
                  });
                }
              } else {
                const { cognome, nome } = validPianificazioneAnagraficaSoggetti?.find(elem => elem.idAnagraficaSoggetto === soggetto.idAnagraficaSoggetto) ?? { cognome: '', nome: '' };

                listSoggetti.push({
                  idStruttura: soggetto.idStruttura,
                  idAnagraficaSoggetto: soggetto.idAnagraficaSoggetto,
                  nominativo: cognome + ' ' + nome,
                  cognome: soggetto.cognome,
                  nome: soggetto.nome,
                  qualificheDescrizione: [{
                    id: soggetto.idQualifica,
                    name: soggetto.qualificaDescrizione,
                  }],
                  qualificheDescrizioneBreve: [{
                    id: soggetto.idQualifica,
                    name: soggetto.qualificaDescrizioneBreve,
                  }]
                });
              }
            });

            return listSoggetti;
          }

          return [...state]
        });
        break;
    }
  }, [lookupAnagraficaSoggettoPerUnitaOperativa, tabValue, validPianificazione, validPianificazioneAnagraficaSoggetti]);

  const [selectedIdQualifica, setSelectedIdQualifica] = useState<number>();
  const [AS_selectedMenu, setAS_selectedMenu] = useState<number>(0);
  const handleSelectItem = useCallback((idAnagraficaSoggetto: number | string, idQualifica: number) => {
    setSelectedAnagraficaSoggetto(typeof idAnagraficaSoggetto === 'number' ? idAnagraficaSoggetto : Number(idAnagraficaSoggetto?.split('-')[1]));
    setAS_selectedMenu(typeof idAnagraficaSoggetto === 'number'
      ? idAnagraficaSoggetto
      : Number(idAnagraficaSoggetto?.split('-')[1]));
    setSelectedIdQualifica(typeof idAnagraficaSoggetto === 'string' ? Number(idAnagraficaSoggetto.split('-')[0]) : idQualifica);
  }, []);

  useEffect(() => {
    setCurrentSelectedAS(state => {
      let retval = currentValidPianificazione
        .find(elem => {
          const selectedIdAnagraficaSoggetto = selectedAnagraficaSoggetto;
          return elem?.idAnagraficaSoggetto === selectedIdAnagraficaSoggetto;
        });

      if (!retval) {
        const soggettoNuovo = listAS.find(elem => {
          const selectedIdAnagraficaSoggetto = selectedAnagraficaSoggetto;
          return elem.idAnagraficaSoggetto === selectedIdAnagraficaSoggetto;
        });

        if (soggettoNuovo?.idStruttura
          && soggettoNuovo?.idAnagraficaSoggetto
          && soggettoNuovo?.nominativo
          && selectedIdQualifica
        )
          retval = {
            idStruttura: soggettoNuovo?.idStruttura,
            idAnagraficaSoggetto: soggettoNuovo?.idAnagraficaSoggetto,
            nominativo: soggettoNuovo?.nominativo,
            cognome: soggettoNuovo?.cognome,
            nome: soggettoNuovo?.nome,
            idQualifica: selectedIdQualifica,
            qualificaDescrizione: lookupQualificheDescrizione[selectedIdQualifica],
            qualificaDescrizioneBreve: lookupQualificheDescrizioneBreve[selectedIdQualifica],
            turniObj: [],
            quantita: [],
            qualificheIds: [],
            coloreAnagraficaSoggetto: 'Rosso',
            coloreQualifica: [],
            turnoFisso: false,
            modificaTurnoFisso: false,
            numeroAddettiUnitaOperativa: [],
            numeroLegaleUnitaOperativa: [],
            numeroReperibilitaRichieste: [],
            tipoInserimento: []
          }
      }

      if (retval) {
        return {
          ...retval,
          qualificaDescrizione: lookupQualificheDescrizione[retval.idQualifica],
          qualificaDescrizioneBreve: lookupQualificheDescrizioneBreve[retval.idQualifica],
        };
      } else {
        return state
      }
    });
  }, [currentValidPianificazione, listAS, lookupAnagraficaSoggettoPerUnitaOperativa, lookupQualificheDescrizione, lookupQualificheDescrizioneBreve, selectedAnagraficaSoggetto, selectedIdQualifica]);

  const firstDateOfMonth = useMemo(() => {
    if (selectedMonth)
      return new Date(selectedMonth.getFullYear() + "-" + (selectedMonth.getMonth() + 1) + "-1");
    return null;
  }, [selectedMonth]);

  const lastDateOfMonth = useMemo(() => {
    if (selectedMonth)
      return new Date(selectedMonth.getFullYear() + "-" + (selectedMonth.getMonth() + 1) + "-" + daysInMonth(selectedMonth.getMonth() + 1, selectedMonth.getFullYear()));
    return null;
  }, [selectedMonth]);

  const thisMonth = useMemo(() => {
    return {
      dayOfFirst: firstDateOfMonth?.getDay() ?? null,
      dayOfLast: lastDateOfMonth?.getDay() ?? null,
      month: selectedMonth ? selectedMonth.getMonth() + 1 : null, // month: index from 1
      year: selectedMonth?.getFullYear() ?? null,
      totalDays: selectedMonth ? daysInMonth(selectedMonth.getMonth() + 1, selectedMonth.getFullYear()) : 0
    }
  }, [firstDateOfMonth, lastDateOfMonth, selectedMonth]);

  const lastMonth = useMemo(() => {
    return {
      month: selectedMonth ? (selectedMonth.getMonth() === 0 ? 12 : selectedMonth.getMonth()) : null,  // month: index from 1
      year: selectedMonth ? (selectedMonth.getMonth() === 0 ? selectedMonth.getFullYear() - 1 : selectedMonth.getFullYear()) : null,
      totalDays: selectedMonth ? (daysInMonth(
        selectedMonth.getMonth() === 0 ? 12 : selectedMonth.getMonth(),
        selectedMonth.getMonth() === 0 ? selectedMonth.getFullYear() - 1 : selectedMonth.getFullYear()
      )) : 0
    }
  }, [selectedMonth]);

  const nextMonth = useMemo(() => {
    return {
      month: selectedMonth ? (selectedMonth.getMonth() === 11 ? 1 : selectedMonth.getMonth() + 2) : null, // month: index from 1
      year: selectedMonth ? (selectedMonth.getMonth() === 11 ? selectedMonth.getFullYear() + 1 : selectedMonth.getFullYear()) : null,
      totalDays: selectedMonth ? (daysInMonth(
        selectedMonth.getMonth() === 11 ? 1 : selectedMonth.getMonth() + 2,
        selectedMonth.getMonth() === 11 ? selectedMonth.getFullYear() + 1 : selectedMonth.getFullYear()
      )) : 0
    }
  }, [selectedMonth]);

  useEffect(() => {
    const temp: Date[] = [];

    // all date previous month
    for (let i = 0; null !== thisMonth.dayOfFirst && i < thisMonth.dayOfFirst; i++) temp.unshift(new Date(lastMonth.year + "-" + lastMonth.month + "-" + (lastMonth.totalDays - i)));
    // all date current month
    for (let i = 1; i <= thisMonth.totalDays; i++) temp.push(new Date(thisMonth.year + "-" + thisMonth.month + "-" + i));
    // all date next month
    for (let i = 1; null !== thisMonth.dayOfLast && i <= 6 - thisMonth.dayOfLast; i++) temp.push(new Date(nextMonth.year + "-" + nextMonth.month + "-" + i));

    setDateToShow(temp);
  }, [lastMonth.month, lastMonth.totalDays, lastMonth.year, nextMonth.month, nextMonth.year, selectedMonth, thisMonth.dayOfFirst, thisMonth.dayOfLast, thisMonth.month, thisMonth.totalDays, thisMonth.year])

  const numberOfWeeks: number[] = [];
  for (let i = 0; i < (dateToShow.length / 7); i++) numberOfWeeks.push(i);

  const validations: string[] = [];
  for (let i = 0; i < 5; i++) validations.push("Validation " + (i + 1));

  const [exportDataExtra, setExportDataExtra] = useState<PDFExtraData>(
    {
      head: {
        title: [t("structureTitle"), t("departmentTitle"), t("operatingUnitTitle"), t("dateTitle")],
        value: [
          lookupStr[fixedProps['idStruttura']],
          lookupDip[fixedProps['idDipartimento']],
          lookupUnitOp[fixedProps['idUnitaOperativa']],
          // getDateDDMMYYYY(new Date(fixedProps['presenzaData'])),
        ]
      }
    }
  );

  /**
   * set export data (head and additional) based on selected tab
   */
  useEffect(() => {
    if (tabValue === TabIndex.ANAGRAFICA) {
      setExportDataExtra(() => {
        const _fixedPropsMese = fixedProps['mese'];

        return {
          head: {
            title: [t("structureTitle"), t("departmentTitle"), t("operatingUnitTitle"), t("yearTitle"), t("monthTitle")],
            value: [
              lookupStr[fixedProps['idStruttura']],
              lookupDip[fixedProps['idDipartimento']],
              lookupUnitOp[fixedProps['idUnitaOperativa']],
              fixedProps['anno'],
              null !== _fixedPropsMese ? t(MonthsList[_fixedPropsMese - 1]) : '',  // mese inizia da 0
            ]
          }
        }
      });
    } else if (tabValue === TabIndex.QUALIFICA) {
      setExportDataExtra(() => {
        const _fixedPropsMese = fixedProps['mese'];

        return {
          head: {
            title: [t("structureTitle"), t("departmentTitle"), t("operatingUnitTitle"), t("yearTitle"), t("monthTitle")],
            value: [
              lookupStr[fixedProps['idStruttura']],
              lookupDip[fixedProps['idDipartimento']],
              lookupUnitOp[fixedProps['idUnitaOperativa']],
              fixedProps['anno'],
              null !== _fixedPropsMese ? t(MonthsList[_fixedPropsMese - 1]) : '',  // mese inizia da 0
            ]
          }
        }
      });
    } else if (tabValue === TabIndex.MODIFICA) {
      // TODO: pdf del calendario
    }

  }, [fixedProps, lookupDip, lookupStr, lookupUnitOp, t, tabValue,]);

  useEffect(() => {
    return () => {
      dispatch(resetArticoliLegge());
      dispatch(resetAssenze());
      dispatch(resetStrutture());
      dispatch(resetTurni());
      dispatch(resetQualifiche());
      dispatch(resetAnagraficaSoggettiUnitaOperative());
      dispatch(resetAnagrificaSoggetti());
      dispatch(resetDipartimentiFiltered());
      dispatch(resetUnitaOperativeFiltered());

      setStates({});
      setSelectedMonth(null);
      setSelectedAnagraficaSoggetto(ERROR_VALUE);
      setCurrentValidPianificazione([]);
      setCurrentValidPianificazioneQualifica([]);
      setCurrentSelectedAS(null);
      setTabValue(TabIndex.ANAGRAFICA);
      setDataPianificazioniAnagraficaSoggetto([]);
      setDataPianificazioniQualifica([]);
      setDateToShow([]);
      setListAS([]);
    };
  }, [dispatch]);

  // Utilizzato per il ridimensionamento dei componenti
  const xPadding = 48;
  const mainBodyWidth = document.body.clientWidth - (xPadding + (appbarOpenStatus ? sideBarWidth : 0));

  const riepilogoAnagraficaModifica: RiepilogoPianificazioneDTO | null = useMemo(() => {
    const retval = dataPianificazioniAnagraficaSoggetto?.find(elem => elem.idAnagraficaSoggetto === currentSelectedAS?.idAnagraficaSoggetto);

    if (retval)
      return {
        ...retval,
        idAnagraficaSoggetto: retval?.idAnagraficaSoggetto ?? currentSelectedAS?.idAnagraficaSoggetto,
        nominativo: retval?.nominativo ?? currentSelectedAS?.nominativo,
        qualificaDescrizione: currentSelectedAS?.qualificaDescrizione,
        qualificaDescrizioneBreve: currentSelectedAS?.qualificaDescrizioneBreve ?? '',
        idQualifica: currentSelectedAS?.idQualifica ?? ERROR_VALUE,
        numeroAddettiUnitaOperativa: [],
        numeroLegaleUnitaOperativa: [],
        numeroReperibilitaRichieste: [],
        tipoInserimento: []
      };

    if (currentSelectedAS)
      return {
        idStruttura: currentSelectedAS.idStruttura,
        idAnagraficaSoggetto: currentSelectedAS?.idAnagraficaSoggetto,
        nominativo: currentSelectedAS?.nominativo,
        cognome: '',
        nome: '',
        turniObj: [],
        quantita: [],
        qualificheIds: [],
        oreTotale: 0,
        minutiTotale: 0,
        numeroRiposi: 0,
        turnoFisso: false,
        modificaTurnoFisso: false,
        coloreAnagraficaSoggetto: 'Verde',
        coloreQualifica: [],
        qualificaDescrizione: currentSelectedAS?.qualificaDescrizione,
        qualificaDescrizioneBreve: currentSelectedAS?.qualificaDescrizioneBreve ?? '',
        idQualifica: currentSelectedAS?.idQualifica ?? ERROR_VALUE,
        numeroAddettiUnitaOperativa: [],
        numeroLegaleUnitaOperativa: [],
        numeroReperibilitaRichieste: [],
        tipoInserimento: []
      };

    return null;
  }, [currentSelectedAS, dataPianificazioniAnagraficaSoggetto]);

  const { preparedData } = useCalendar({
    currentSelectedAS,
    mappaTurniOre: collezioneCurrentTurniEOre,
    dateToShow: dateToShow,
    selectedMonth,
    selectedIdQualifica,
    riepilogoAnagraficaModifica,
    fixedProps,
    isApprovato: approvato
  });

  return (
    <>
      {/* SIMPLE SELECT */}
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={10}>
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: strSelectName,
                    lookup: lookupStr,
                    label: strSelectLabel,
                    disabled: isUrlOnUpdate(history.location.pathname),
                    breakpoints: { xs: 12, sm: 12, md: 12, lg: 4 },
                  },
                  {
                    name: dipSelectName,
                    lookup: lookupDip,
                    label: dipSelectLabel,
                    disabled: isUrlOnUpdate(history.location.pathname),
                    callback: cleanDipartimentiLookup,
                    breakpoints: { xs: 12, sm: 12, md: 12, lg: 4 },
                  },
                  {
                    name: unitOpSelectName,
                    lookup: lookupUnitOp,
                    label: unitOpSelectLabel,
                    disabled: isUrlOnUpdate(history.location.pathname),
                    callback: cleanUOLookup,
                    breakpoints: { xs: 12, sm: 12, md: 12, lg: 4 },
                  },
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              {(states[strSelectName] && states[dipSelectName] && states[unitOpSelectName]) &&
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                  <DatePicker
                    label={monthYearSelectLabel}
                    format={"MMMM yyyy"}
                    onChange={(e) => handleDateChange(e)}
                    okLabel={t('insertLabel')}
                    clearLabel={t('clearLabel')}
                    cancelLabel={t('cancelLabel')}
                    clearable={true}
                    disabled={isUrlOnUpdate(history.location.pathname)}
                    value={states[monthYearSelectName] ? states[monthYearSelectName] : null}
                    views={['month', 'year',]}
                  />
                </MuiPickersUtilsProvider>
              }
            </Grid>
          </Grid> {/* Grid container */}
        </Box>
      </Paper>
      {
        ERROR_VALUE !== fixedProps.mese && ERROR_VALUE !== fixedProps.anno && isOldPianificazione(fixedProps.mese, fixedProps.anno) && errorBE ?
          <Paper elevation={2}>
            <Box mt={2} p={4}>
              <Typography align="center" variant="h5">
                {errorBE}
              </Typography>
            </Box>
          </Paper>
          :
          <>
            {/* STATO DELLA PIANIFICAZIONE */}
            {
              states[strSelectName] &&
              states[dipSelectName] &&
              states[unitOpSelectName] &&
              states[monthYearSelectName] &&
              validUnitaOperativaPianificazione &&

              <Paper elevation={2} >
                <Box mt={2} p={4}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                      <GeneralForm
                        fields={allFieldsStatoPianificazione}
                        translate={t}
                        obj={currentStatoPianificazione}
                        readOnly={true}
                        action={undefined}
                        status={statusValidUnitaOperativaPianificazioni}
                        error={errorBE}
                        update={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                      <Grid item xs>
                        {/* APPROVA */}
                        <Button
                          variant={'contained'}
                          disabled={!isApprovable(validPianificazioneAnagraficaSoggetti, validPianificazioneQualifiche)}
                          fullWidth
                          onClick={() => {
                            if (!approvato) {
                              dispatch(saveNuovaPianificazione({
                                unitaOperativePianificazione: validUnitaOperativaPianificazione,
                                pianificazione: validPianificazione,
                                stato: statoPianificazioneMap.APPROVATO,
                              }));
                            } else {
                              dispatch(reopenPianificazione({
                                unitaOperativePianificazione: validUnitaOperativaPianificazione,
                                stato: statoPianificazioneMap.INLAVORAZIONE
                              }));
                            }
                            setApprovato(state => !state);
                          }}>
                          {approvato
                            ? t('reopenLabel')
                            : t('approveLabel')}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                      {/* SALVA */}
                      <Button
                        variant={'contained'}
                        fullWidth
                        disabled={(approvato || salvato) ||
                          (serializedInitPianificazione === JSON.stringify(validPianificazione) && validUnitaOperativaPianificazione?.stato === statoPianificazioneMap.INLAVORAZIONE)
                        }
                        onClick={() => {
                          if (currentStatoPianificazione) {
                            dispatch(saveNuovaPianificazione({
                              unitaOperativePianificazione: validUnitaOperativaPianificazione,
                              pianificazione: validPianificazione,
                              stato: currentStatoPianificazione.stato === statoPianificazioneMap.APPROVATO
                                ? statoPianificazioneMap.DAAPPROVARE
                                : currentStatoPianificazione.stato
                            }));
                          }
                        }}>
                        {t('saveLabel')}
                      </Button>
                    </Grid>
                  </Grid>
                  {
                    errorBE &&

                    <Box mt={2} p={4}>
                      <Typography align="center" variant="h5" color="error">
                        {errorBE}
                      </Typography>
                    </Box>

                  }

                </Box>
              </Paper>
            }

            {/* Material Table */}
            <Box marginTop={2} maxWidth={mainBodyWidth}>
              {
                states[strSelectName] &&
                  states[dipSelectName] &&
                  states[unitOpSelectName] &&
                  states[monthYearSelectName] &&
                  validUnitaOperativaPianificazione ? (
                  <>
                    <AppBar position="static" className={classes.appbar}>
                      <Tabs value={tabValue ?? TabIndex.ANAGRAFICA} onChange={handleTabIndexChange} classes={{ indicator: classes.indicator }}>
                        <Tab label={t("summaryPlanTabLabel")} {...handleTabChange(TabIndex.ANAGRAFICA)} />
                        <Tab label={t("summaryQualificationTabLabel")} {...handleTabChange(TabIndex.QUALIFICA)} />
                        <Tab label={t("Modifica")} {...handleTabChange(TabIndex.MODIFICA)} />
                      </Tabs>
                    </AppBar>
                    <TabPanel value={tabValue} index={TabIndex.ANAGRAFICA}>
                      <ReadOnlyMaterialTable
                        title={t("summaryPlanTabLabel")}
                        columns={columnsAnagraficaSoggetto}
                        data={dataPianificazioniAnagraficaSoggetto}
                        statusValid={statusValidUnitaOperativaPianificazioni}
                        errorBE={null}
                        logoUri={logoUri}
                        fixedProps={fixedProps}
                        exportDataExtra={exportDataExtra}
                        isExportLandscape={true}
                        localizedDatePicker={true}
                        extraOptions={{
                          draggable: false,
                          // fixedColumns: { left: 3 },
                          headerStyle: { position: "sticky", top: 0 },
                          doubleHorizontalScroll: true,
                          defaultExpanded: true,
                          sorting: false,
                          padding: 'dense',
                          paging: false,
                          maxBodyHeight: 460,
                          rowStyle: (rowData) => {
                            return {
                              borderTop: rowData.idStruttura === -1 ? "2px solid #000" : undefined,
                              borderBottom: rowData.idStruttura === -1 ? "2px solid #000" : undefined
                            }
                          }
                        }}
                        pdfOptions={{
                          bodyFontSize: 5
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={tabValue} index={TabIndex.QUALIFICA}>
                      <ReadOnlyMaterialTable
                        title={t("summaryQualificationTabLabel")}
                        columns={columnsQualifica}
                        data={dataPianificazioniQualifica}
                        statusValid={statusValidUnitaOperativaPianificazioni}
                        errorBE={null}
                        logoUri={logoUri}
                        fixedProps={fixedProps}
                        exportDataExtra={exportDataExtra}
                        isExportLandscape={true}
                        localizedDatePicker={true}
                        extraOptions={{
                          draggable: false,
                          // fixedColumns: { left: 0 },
                          maxBodyHeight: 460,
                          padding: 'dense',
                          pageSize: dataPianificazioniQualifica.length >= 20
                            ? 20
                            : dataPianificazioniQualifica.length <= 5
                              ? 5
                              : 10,
                          // grouping: false,
                          defaultExpanded: true,
                        }}
                        pdfOptions={{
                          bodyFontSize: 5
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={tabValue} index={TabIndex.MODIFICA}>
                      <Paper>
                        <Box p={4} className={classes.flexGrow}>
                          <Grid container spacing={2}>
                            {/* AS LIST & CALENDAR */}
                            <Grid item container spacing={2}>
                              <Grid item xs={3}>
                                {/* Anagrafica Soggetti list */}
                                {
                                  AS_selectedMenu != null &&
                                  <AnagraficaSoggettiList
                                    selectedItem={AS_selectedMenu}
                                    handleSelectItem={handleSelectItem}
                                    setSelectedAnagraficaSoggetto={setSelectedAnagraficaSoggetto}
                                    listAsState={{
                                      listAS: listAS,
                                      setListAS: setListAS
                                    }}
                                    lookupAnagrafica={lookupAnagraficaSoggettoPerUnitaOperativa}
                                    fixedProps={fixedProps}
                                    approvato={approvato}
                                  />
                                }
                              </Grid>
                              <Grid item xs={9}>
                                {/* Calendar & Validations */}
                                {
                                  ERROR_VALUE !== selectedAnagraficaSoggetto
                                    ? (
                                      statusSavePianificazione === StatusEnum.Loading
                                        ? <LoadingSvg color="primary" width={300} />
                                        : <>
                                          {/* Calendar */}
                                          {
                                            selectedIdQualifica && selectedMonth && currentSelectedAS && riepilogoAnagraficaModifica && ERROR_VALUE !== riepilogoAnagraficaModifica.idQualifica &&
                                            <Calendar
                                              selectedIdQualifica={selectedIdQualifica}
                                              mappaTurniOre={collezioneCurrentTurniEOre}
                                              dateToShow={dateToShow}
                                              selectedMonth={selectedMonth}
                                              currentSelectedAS={currentSelectedAS}
                                              fixedProps={fixedProps}
                                              approvato={approvato}
                                              riepilogoAnagraficaModifica={riepilogoAnagraficaModifica}
                                            />
                                          }
                                        </>)
                                    : <Box p={4}>
                                      <Typography align="center" variant="h5">
                                        {t("registrySelect")}
                                      </Typography>
                                    </Box>
                                }
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Paper>
                    </TabPanel>
                  </>
                ) :
                  <Switch>
                    <Route path={pianificazioniPath} exact>
                      <Paper elevation={2}>
                        <Box p={4}>
                          <Typography align="center" variant="h5">
                            {
                              !states[strSelectName]
                                ? strSelectLabel
                                : !states[dipSelectName]
                                  ? dipSelectLabel
                                  : !states[unitOpSelectName]
                                    ? unitOpSelectLabel
                                    : !states[monthYearSelectName]
                                      ? monthYearSelectLabel
                                      : errorBE
                                        ? errorBE
                                        : 'Pianificazione in fase di Elaborazione'
                            }
                          </Typography>
                        </Box>
                      </Paper>
                    </Route>
                    <Route><Redirect to={pianificazioniPath} /></Route>
                  </Switch>
              }
            </Box>
          </>
      }
      {
        currentSelectedDate && currentSelectedAS &&
        <InsertTurniModal
          currentSelectedAS={currentSelectedAS}
          currentSelectedDate={currentSelectedDate}
          preparedData={preparedData}
          selectedDateIndex={selectedDateIndex}
          approvato={approvato}
          fixedProps={fixedProps}
          openState={{
            open: openTurniModal,
            setOpen: setOpenTurniModal
          }}
        />
      }
    </>
  );
};
export default PianificazioniW;

function reworkPianificazioneFieldsBgColor(fields: Fields[], pianificazioneQualifica: PianificazioneQualifica[], month: number, year: number, t: (s: string) => string, classes: ClassNameMap<string>): Fields[] {
  if (month > 12 || month < 1 || year < 1) return [];

  /*******************/
  const retval = [...fields];
  const validWeekDays = WeekList.slice(1);

  const first = new Date(year, month - 1, 1)

  const maxDays = daysInMonth(month, year);
  const weekDay = first.getDay() - 1 < 0
    ? validWeekDays.length - 1
    : first.getDay() - 1;       // [0,6] - first day of the week: monday


  for (let i = 0; i < maxDays; i++) {
    retval.push({
      field: '_' + (i + 1).toString(),
      titleKey: (i + 1) + ' ' + t(validWeekDays[((i) + weekDay) % validWeekDays.length]).substring(0, 2),
      required: false,
      removable: false,
      show: true,
      render: (rowData) => { // rowData: {nominativo: string, _{date}: string, colore{date}: string, qualificaDescrizioneBreve: string, turnoDescrizioneBreve: string}
        const data = rowData['_' + (i + 1)];
        const color = rowData['colore' + (i + 1)];

        const turnoNumeroAddettiInfo = pianificazioneQualifica?.find(elem => {
          const month = new Date(elem.pianificazioneData).getMonth();
          const year = new Date(elem.pianificazioneData).getFullYear()
          return isEqual(new Date(elem.pianificazioneData), new Date(year, month, i + 1, 0, 0, 0, 0))
            && elem.qualificaDescrizioneBreve === rowData['qualificaDescrizioneBreve']
            && elem.turnoDescrizione === rowData['turnoDescrizione']
        })

        return (
          <Tooltip title={<span style={{ fontSize: 12 }}>
            Dotazione Minima: {turnoNumeroAddettiInfo?.numeroLegaleUnitaOperativa ?? ''}<br />
            Dotazione Ottimale: {turnoNumeroAddettiInfo?.numeroAddettiUnitaOperativa ?? ''} <br />
            Reperibilità Richieste: {turnoNumeroAddettiInfo?.numeroReperibilitaRichieste ?? ''}
          </span>}
          >
            <Box
              bgcolor={Colors[color as ColorsKeys] /* + alpha */}
              minHeight={'32px'}
              pt={'6px'}
              fontWeight={'bold'}
              className={classes.hoverCell}
            >
              {data}
            </Box>
          </Tooltip>
        )
      }
    });
  }

  return retval;
}

function reworkPianificazioneFieldsNoBgColor(fields: Fields[], month: number, year: number, t: (s: string) => string): Fields[] {
  if (month > 12 || month < 1 || year < 1) return [];

  /*******************/
  const retval = [...fields];
  const validWeekDays = WeekList.slice(1);

  const first = new Date(year, month - 1, 1)

  const maxDays = daysInMonth(month, year);
  const weekDay = first.getDay() - 1 < 0
    ? validWeekDays.length - 1
    : first.getDay() - 1;       // [0,6] - first day of the week: monday


  for (let i = 0; i < maxDays; i++) {
    retval.push({
      field: '_' + (i + 1).toString(),
      titleKey: (i + 1) + ' ' + t(validWeekDays[((i) + weekDay) % validWeekDays.length]).substring(0, 2),
      required: false,
      removable: false,
      show: true,
    });
  }

  return retval;
}

function createRiepilogoDatiAnagraficaSoggetto(pianificazioni: Pianificazione[], pianificazioniAnagrafiche: PianificazioniAnagraficaSoggetti[], pianificazioniQualifiche: PianificazioneQualifica[], mese: number, anno: number, tabValue: TabIndex): RiepilogoPianificazioneDTO[] {
  const retval: RiepilogoPianificazioneDTO[] = [];
  const maxDays = daysInMonth(mese, anno);

  /**
   * Collect all anagrafica soggetti
   */
  pianificazioni
    .filter(elem => elem.idTurno || elem.idTurnoAssenza)
    .forEach((pianificazione, pianificazioneIndex) => {
      const date = (new Date(pianificazione.pianificazioneData)).getDate();

      if (retval.every(elem => elem.idAnagraficaSoggetto !== pianificazione.idAnagraficaSoggetto)) {

        const soggetto = pianificazioniAnagrafiche.find(soggetto => soggetto.idAnagraficaSoggetto === pianificazione.idAnagraficaSoggetto)
        const colore = soggetto?.coloreValidazioneOre.toLowerCase() === 'rosso' || soggetto?.coloreValidazioneRiposi.toLowerCase() === 'rosso'
          ? 'Rosso'
          : soggetto?.coloreValidazioneOre.toLowerCase() === 'giallo' || soggetto?.coloreValidazioneRiposi.toLowerCase() === 'giallo'
            ? 'Giallo'
            : 'Verde';

        const temp: RiepilogoPianificazioneDTO = {
          idStruttura: pianificazione.idStruttura,
          idAnagraficaSoggetto: pianificazione.idAnagraficaSoggetto,
          idQualifica: pianificazione.idQualifica,
          nominativo: pianificazione.cognome + ' ' + pianificazione.nome,
          cognome: pianificazione?.cognome,
          nome: pianificazione?.nome,
          coloreAnagraficaSoggetto: colore,
          coloreQualifica: new Array(maxDays).fill([]),
          turniObj: new Array(maxDays).fill([]),
          quantita: new Array(maxDays).fill(''),
          qualificheIds: new Array(maxDays).fill([]),
          oreTotale: soggetto?.totaleOre,
          minutiTotale: soggetto?.totaleMinuti,
          numeroRiposi: soggetto?.totaleRiposi,
          turnoFisso: pianificazione.turnoFisso,
          modificaTurnoFisso: pianificazione.modificaTurnoFisso,
          qualificaDescrizione: pianificazione.qualificaDescrizione,
          qualificaDescrizioneBreve: pianificazione.qualificaDescrizioneBreve,
          numeroAddettiUnitaOperativa: [],
          numeroLegaleUnitaOperativa: [],
          numeroReperibilitaRichieste: [],
          tipoInserimento: new Array(maxDays).fill(null)
        };

        // this is needed because .fill([]) adds an empty array with the same reference,
        // a change in a position will be applied to the rest of the array
        temp.turniObj.forEach((_, index) => {
          temp.turniObj[index] = [];
          temp.qualificheIds[index] = [];
        });

        temp.tipoInserimento[date - 1] = pianificazione.tipoInserimento as TipoInserimento;

        temp.turniObj[date - 1]?.push({
          id: pianificazione.idTurno ?? pianificazione.idTurnoAssenza,
          descrizione: pianificazione.turnoDescrizione ?? pianificazione.turnoAssenzaDescrizione,
          descrizioneBreve: pianificazione.turnoDescrizioneBreve ?? pianificazione.turnoAssenzaDescrizioneBreve,
          reperibilita: pianificazione.reperibilita
        });
        temp.qualificheIds[date - 1]?.push(pianificazione.idQualifica);
        retval.push(temp);
      } else {
        const index = retval.findIndex(elem => elem.idAnagraficaSoggetto === pianificazione.idAnagraficaSoggetto);

        retval[index].tipoInserimento[date - 1] = pianificazione.tipoInserimento as TipoInserimento;

        retval[index].turniObj[date - 1]?.push({
          id: pianificazione.idTurno ?? pianificazione.idTurnoAssenza,
          descrizione: pianificazione.turnoDescrizione ?? pianificazione.turnoAssenzaDescrizione,
          descrizioneBreve: pianificazione.turnoDescrizioneBreve ?? pianificazione.turnoAssenzaDescrizioneBreve,
          reperibilita: pianificazione.reperibilita,
        });
        retval[index].qualificheIds[date - 1]?.push(pianificazione.idQualifica);
      }
    });

  /**
   * Collect all qualifiche
   */
  if (TabIndex.ANAGRAFICA === tabValue) {
    pianificazioniQualifiche.forEach(pianificazione => {
      const date = (new Date(pianificazione.pianificazioneData)).getDate();

      if (!retval.find(elem => elem.qualificaDescrizione === pianificazione.qualificaDescrizione && elem.nominativo === pianificazione.turnoDescrizione && elem.idStruttura === -1)) {
        const temp: RiepilogoPianificazioneDTO = {
          idStruttura: -1,
          idAnagraficaSoggetto: -1,
          idQualifica: pianificazione.idQualifica,
          nominativo: pianificazione.turnoDescrizione,
          cognome: '',
          nome: '',
          coloreAnagraficaSoggetto: 'Bianco',
          coloreQualifica: new Array(maxDays).fill('Bianco'),
          turniObj: new Array(maxDays).fill([]),
          quantita: new Array(maxDays).fill(''),
          qualificheIds: new Array(maxDays).fill([]),
          oreTotale: 0,
          minutiTotale: 0,
          numeroRiposi: 0,
          turnoFisso: false,
          modificaTurnoFisso: false,
          qualificaDescrizione: pianificazione.qualificaDescrizione,
          qualificaDescrizioneBreve: pianificazione.qualificaDescrizioneBreve,
          numeroAddettiUnitaOperativa: new Array(maxDays).fill(0),
          numeroLegaleUnitaOperativa: new Array(maxDays).fill(0),
          numeroReperibilitaRichieste: new Array(maxDays).fill(0),
          tipoInserimento: new Array(maxDays).fill(null)
        };

        temp.quantita[date - 1] = pianificazione.totaleNumeroAddetti + (pianificazione.totaleNumeroReperibilita > 0 ? ' / (' + pianificazione.totaleNumeroReperibilita + ')' : '');
        temp.coloreQualifica[date - 1] = pianificazione.coloreValidazione;
        temp.numeroAddettiUnitaOperativa[date - 1] = pianificazione.numeroAddettiUnitaOperativa;
        temp.numeroLegaleUnitaOperativa[date - 1] = pianificazione.numeroLegaleUnitaOperativa;
        temp.numeroReperibilitaRichieste[date - 1] = pianificazione.numeroReperibilitaRichieste;

        retval.push(temp);
      } else {
        const index = retval.findIndex(elem => elem.qualificaDescrizione === pianificazione.qualificaDescrizione && elem.nominativo === pianificazione.turnoDescrizione && elem.idStruttura === -1);
        retval[index].coloreQualifica[date - 1] = pianificazione.coloreValidazione;
        retval[index].quantita[date - 1] = pianificazione.totaleNumeroAddetti + (pianificazione.totaleNumeroReperibilita > 0 ? ' / (' + pianificazione.totaleNumeroReperibilita + ')' : '');

        retval[index].numeroAddettiUnitaOperativa[date - 1] = pianificazione.numeroAddettiUnitaOperativa;
        retval[index].numeroLegaleUnitaOperativa[date - 1] = pianificazione.numeroLegaleUnitaOperativa;
        retval[index].numeroReperibilitaRichieste[date - 1] = pianificazione.numeroReperibilitaRichieste;
      }
    });
  }

  return retval;
}

function createRiepilogoDatiQualifica(pianificazioniQualifica: PianificazioneQualifica[], mese: number, anno: number): RiepilogoPianificazioneQualificheDTO[] {
  const retval: RiepilogoPianificazioneQualificheDTO[] = [];
  const maxDays = daysInMonth(mese, anno);

  /**
   * Collect all qualifiche
   */
  pianificazioniQualifica.forEach(pianificazione => {
    const date = (new Date(pianificazione.pianificazioneData)).getDate();

    if (!retval.find(elem => elem.qualificaDescrizione === pianificazione.qualificaDescrizione && elem.turnoDescrizione === pianificazione.turnoDescrizione)) {
      const temp: RiepilogoPianificazioneQualificheDTO = {
        qualificaDescrizioneBreve: pianificazione.qualificaDescrizioneBreve,
        qualificaDescrizione: pianificazione.qualificaDescrizione,
        turnoDescrizione: pianificazione.turnoDescrizione,
        idQualifica: pianificazione.idQualifica,
        colore: new Array(maxDays).fill(''),
        quantita: new Array(maxDays).fill(0),
      };

      temp.quantita[date - 1] = pianificazione.totaleNumeroAddetti + (pianificazione.totaleNumeroReperibilita > 0 ? ' / (' + pianificazione.totaleNumeroReperibilita + ')' : '');
      temp.colore[date - 1] = pianificazione.coloreValidazione;
      retval.push(temp);
    } else {
      const index = retval.findIndex(elem => elem.qualificaDescrizione === pianificazione.qualificaDescrizione && elem.turnoDescrizione === pianificazione.turnoDescrizione);
      retval[index].colore[date - 1] = pianificazione.coloreValidazione;
      retval[index].quantita[date - 1] = pianificazione.totaleNumeroAddetti + (pianificazione.totaleNumeroReperibilita > 0 ? ' / (' + pianificazione.totaleNumeroReperibilita + ')' : '');
    }
  });
  return retval;
}

function isApprovable(validPianificazioneAnagraficaSoggetti: PianificazioniAnagraficaSoggetti[], validPianificazioneQualifiche: PianificazioneQualifica[]) {
  // let tuttiVerde = validPianificazioneAnagraficaSoggetti.every(elem => elem.coloreValidazioneRiposi === elem.coloreValidazioneOre &&
  //   elem.coloreValidazioneOre.toLowerCase() === 'verde');
  // tuttiVerde = tuttiVerde && validPianificazioneQualifiche.every(elem => elem.coloreValidazione.toLowerCase() === 'verde');

  // return tuttiVerde;
  return true
}

function isOldPianificazione(mese: number, anno: number) {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  return anno <= currentYear && mese < currentMonth;
}