import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback
} from "react";
import { useTranslation } from "react-i18next";
import RestoreIcon from "@material-ui/icons/Restore";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import RefreshIcon from "@material-ui/icons/Refresh";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { saveAs } from "file-saver";
import { Abilitazione } from "../../../../models/AbilitazioneEnum";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  openDialogConfirm,
  resetYesClickDialogConfirm,
} from "../../../../store/slices/dialogsSlice";
import { AsyncThunk } from "@reduxjs/toolkit";
import { Ids, StatusEnum } from "../../../../models/Utils";
import { Grid, Theme, Typography } from "@material-ui/core";
import { Components, MTableBodyRow, MTableCell, MTableEditField, MTableToolbar } from "@material-table/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import itIT from "date-fns/locale/it";
import enGB from "date-fns/locale/en-GB";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";
import { getDateDDMMYYYY, getDateDDMMYYYY_HHMM } from "../../../../utils/utilfunctions";
import { PDFExtraData } from '../../../../models/Utils';
import { ExportType } from "../../../../utils/utildata";
import lod from 'lodash';

interface GeneralMessage {
  text: string;
  color: string;
}

const useCrudMaterialTable = (
  abilitazione: number,
  theme: Theme,
  title: string,
  logoUri: string | null,
  elementIdProps: string[],
  elementRenderProps: string[],
  fetchAllValid:
    | AsyncThunk<Object[], void, {}>
    | AsyncThunk<Object[], Object, {}>
    | AsyncThunk<Object, Object, {}>,
  logicalDel: AsyncThunk<{ ids: Ids<any>[] }, Ids<any>[], {}>,
  physicalDel: AsyncThunk<{ ids: Ids<any>[] }, Ids<any>[], {}>,
  restore: AsyncThunk<{ ids: Ids<any>[] }, Ids<any>[], {}>,
  columnsButton: boolean,
  dataValid: boolean,
  setToggleValidDeleted: Dispatch<SetStateAction<boolean>>,
  statusValid: string,
  statusDeleted: string,
  errorBE: string | null,
  fetchAllDeleted?:
    | AsyncThunk<Object[], void, {}>
    | AsyncThunk<Object[], Object, {}>
    | AsyncThunk<Object, Object, {}>,
  insert?: AsyncThunk<Object, Object, {}>,
  update?: AsyncThunk<Object, Object, {}>,
  localizedDatePicker: boolean = true,
  nullableTextFields?: boolean,
  fixedProps?: Object,
  exportDataExtra?: PDFExtraData,
  exportType?: ExportType,
  isExportLandscape: boolean = true,
  insertCallback?: () => void,
  updateCallback?: (obj: Object) => void,
  detailCallback?: (obj: Object) => void,
  resetErrorCallback?: () => void,
  autofetch: boolean = true,
  isLoading: boolean = false
) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [wrapValidResolve, setWrapValidResolve] = useState<(() => void) | null>(null);
  const [wrapValidReject, setWrapValidReject] = useState<(() => void) | null>(null);
  const [wrapDelResolve, setWrapDelResolve] = useState<(() => void) | null>(null);
  const [wrapDelReject, setWrapDelReject] = useState<(() => void) | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [generalMessage, setGeneralMessage] = useState<GeneralMessage | null>(null);
  const [actions, setActions] = useState<any[]>([]);
  const [editable, setEditable] = useState<Object>({});
  const [components, setComponents] = useState<Object>({});
  const [typeResDel, setTypeResDel] = useState<null | {
    type: "res" | "del";
    ids: Ids<string>[];
    resolve: any;
    reject: any;
  }>(null);
  const confirmClick = useAppSelector(
    (state) => state.dialogs.dialogConfirm.click
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [logoImage, setLogoImage] = useState<HTMLImageElement>();
  const materialTableRef = useRef();

  useEffect(() => {
    return () => {
      setErrorMessage(null);
      setWrapValidResolve(null);
      setWrapValidReject(null);
      setWrapDelResolve(null);
      setWrapDelReject(null);
      setActions([]);
      setEditable({});
      setComponents({});
      setTypeResDel(null);
      setLoading(false);
      setLogoImage(undefined);
    };
  }, []);

  useEffect(() => {
    if (wrapValidResolve && statusValid === StatusEnum.Succeeded) {
      setErrorMessage(null);
      setTimeout(() => {
        wrapValidResolve();
      }, 1000);
      setWrapValidResolve(null);
    } else if (wrapValidReject && statusValid === StatusEnum.Failed) {
      setErrorMessage(errorBE);
      wrapValidReject();
      setWrapValidReject(null);
    }
  }, [wrapValidResolve, statusValid, dispatch, errorBE, wrapValidReject]);

  useEffect(() => {
    if (wrapDelResolve && statusDeleted === StatusEnum.Succeeded) {
      setErrorMessage(null);
      setTimeout(() => {
        wrapDelResolve();
      }, 1000);
      wrapDelResolve();
      setWrapDelResolve(null);
    } else if (wrapDelReject && statusDeleted === StatusEnum.Failed) {
      setErrorMessage(errorBE);
      wrapDelReject();
      setWrapDelReject(null);
    }
  }, [wrapDelResolve, statusDeleted, dispatch, errorBE, wrapDelReject]);

  useEffect(() => {
    const im = new Image();
    im.onload = () => {
      setLogoImage(im);
    };
    if (logoUri)
      im.src = logoUri.startsWith('data:') ? logoUri : 'data:image/png;base64,' + logoUri;
  }, [logoUri]);

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading]);

  useEffect(() => {
    const refreshData = (
      resolve: (value: unknown) => void,
      reject: (value: unknown) => void,
      fetchDel: boolean
    ) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapDelResolve(() => resolve);
      setWrapValidReject(() => reject);
      setWrapDelReject(() => reject);
      if (autofetch)
        if (fixedProps) {
          let fav = fetchAllValid as AsyncThunk<Object[], Object, {}>;
          dispatch(fav(fixedProps));
          if (fetchAllDeleted) {
            let fad = fetchAllDeleted as AsyncThunk<Object[], Object, {}>;
            dispatch(fad(fixedProps));
          }
        } else {
          let fav = fetchAllValid as AsyncThunk<Object[], void, {}>;
          dispatch(fav());
          if (fetchAllDeleted) {
            let fad = fetchAllDeleted as AsyncThunk<Object[], void, {}>;
            dispatch(fad());
          }
        }
    };
    setLoading(true);
    new Promise((resolve, reject) => {
      refreshData(resolve, reject, abilitazione > Abilitazione.OVERVIEW);
    }).then(() => setLoading(false)).catch(() => setLoading(false));
  }, [abilitazione, fetchAllValid, fetchAllDeleted, fixedProps, dispatch, t, autofetch]);

  const localization = useMemo(() => {
    return {
      body: {
        emptyDataSourceMessage: t("emptyDataSourceMessage"),
        addTooltip: t("addTooltip"),
        deleteTooltip: t("deleteTooltip"),
        editTooltip: t("editTooltip"),
        filterRow: {
          filterTooltip: t("filterTooltip"),
        },
        editRow: {
          deleteText: t("deleteText"),
          cancelTooltip: t("cancelTooltip"),
          saveTooltip: t("saveTooltip"),
        },
      },
      grouping: {
        placeholder: t("groupingPlaceholder"),
        groupedBy: t("groupedBy"),
      },
      header: {
        actions: t("actions"),
      },
      pagination: {
        labelDisplayedRows: t("labelDisplayedRows"),
        labelRowsSelect: t("labelRowsSelect"),
        labelRowsPerPage: t("labelRowsPerPage"),
        firstPageLabel: t("firstPageLabel"),
        firstTooltip: t("firstTooltip"),
        previousPageLabel: t("previousPageLabel"),
        previousTooltip: t("previousTooltip"),
        nextPageLabel: t("nextPageLabel"),
        nextTooltip: t("nextTooltip"),
        lastPageLabel: t("lastPageLabel"),
        lastTooltip: t("lastTooltip"),
      },
      toolbar: {
        addRemoveColumns: t("addRemoveColumns"),
        nRowsSelected: t("nRowsSelected"),
        showColumnsTitle: t("showColumnsTitle"),
        showColumnsAriaLabel: t("showColumnsAriaLabel"),
        exportTitle: t("exportTitle"),
        exportAriaLabel: t("exportAriaLabel"),
        exportName: t("exportName"),
        searchTooltip: t("searchTooltip"),
        searchPlaceholder: t("searchPlaceholder"),
      },
    };
  }, [t]);

  useEffect(() => {
    const restoreData = (ids: Ids<string>[], resolve: (value: unknown) => void) => {
      setErrorMessage(null);
      setWrapDelResolve(() => resolve);
      dispatch(restore(ids));
    };

    const definitiveDeleteData = (ids: Ids<string>[],
      resolve: (value: unknown) => void,
      reject: (value: unknown) => void
    ) => {
      setErrorMessage(null);
      setWrapDelResolve(() => resolve);
      setWrapDelReject(() => reject);
      dispatch(physicalDel(ids));
    };

    if (typeResDel) {
      if (confirmClick === "yes") {
        if (typeResDel.type === "res") {
          restoreData(typeResDel.ids, typeResDel.resolve);
          setTypeResDel(null);
          dispatch(resetYesClickDialogConfirm());
        } else if (typeResDel.type === "del") {
          definitiveDeleteData(typeResDel.ids, typeResDel.resolve, typeResDel.reject);
          setTypeResDel(null);
          dispatch(resetYesClickDialogConfirm());
        }
      } else if (confirmClick === "no") {
        typeResDel.resolve();
        dispatch(resetYesClickDialogConfirm());
      }
    }
  }, [confirmClick, dispatch, typeResDel, physicalDel, restore, t]);

  const resetErrorStatus = useCallback(() => {
    resetErrorCallback && resetErrorCallback();
    setGeneralMessage(null);
    setWrapValidResolve(() => () => { });
    setWrapDelResolve(() => () => { });
  }, [resetErrorCallback]);

  const operationCancel = useCallback(() => {
    setGeneralMessage({
      text: t('cancelOpLabel'),
      color: 'green',
    });
    setTimeout(() => {
      setGeneralMessage(null);
    }, 5000);
  }, [t]);

  useEffect(() => {
    const refreshData = (
      resolve: (value: unknown) => void,
      reject: (value: unknown) => void,
      fetchDel: boolean
    ) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapDelResolve(() => resolve);
      setWrapValidReject(() => reject);
      setWrapDelReject(() => reject);
      if (fixedProps) {
        let fav = fetchAllValid as AsyncThunk<Object[], Object, {}>;
        dispatch(fav(fixedProps));
        if (fetchAllDeleted) {
          let fad = fetchAllDeleted as AsyncThunk<Object[], Object, {}>;
          dispatch(fad(fixedProps));
        }
      } else {
        let fav = fetchAllValid as AsyncThunk<Object[], void, {}>;
        dispatch(fav());
        if (fetchAllDeleted) {
          let fad = fetchAllDeleted as AsyncThunk<Object[], void, {}>;
          dispatch(fad());
        }
      }
    };

    const insertData = (obj: Object, resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapValidReject(() => reject);

      let dispObj: Record<string, unknown> = {};

      for (const [key, value] of Object.entries(obj)) {
        let val = value;
        if (typeof value === "string") val = val.trim();
        if (val !== null && val !== undefined && val !== "") dispObj[key] = val;
      }
      if (fixedProps) {
        for (const [key, value] of Object.entries(fixedProps)) {
          dispObj[key] = value;
        }
      }

      insert && dispatch(insert(dispObj));
    };

    const updateData = (obj: object, resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapValidReject(() => reject);

      let dispObj: Record<string, unknown> = {};

      for (const [key, value] of Object.entries(obj)) {
        let val = value;
        if (typeof value === "string") val = val.trim();
        if (val !== null && val !== undefined && val !== "") dispObj[key] = val;
      }
      if (fixedProps) {
        for (const [key, value] of Object.entries(fixedProps)) {
          dispObj[key] = value;
        }
      }

      update && dispatch(update(dispObj));
    };

    const logicalDeleteData = (ids: Ids<string>[], resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapValidReject(() => reject);
      dispatch(logicalDel(ids));
    };

    const toggleViewValidDeleted = () => {
      setErrorMessage(null);
      setToggleValidDeleted((prev) => !prev);
    };

    let act = [];
    if (!dataValid) {
      if (
        abilitazione >= Abilitazione.READ_UPDATE_LOGICDELETE_DEFDELETE_RESTORE
      ) {
        act.push({
          icon: RestoreIcon,
          tooltip: t("restore"),
          onClick: (_: unknown, rowData: Record<string, unknown>) => {
            setLoading(true);
            new Promise((resolve, reject) => {
              const ids: Ids<string>[] = [];
              elementIdProps.forEach((propId) =>
                ids.push({ name: propId, id: rowData[propId] })
              );

              let text = "";
              if (elementRenderProps.length === 0) {
                text += t("theElement");
              } else {
                text += "'";

                elementRenderProps.forEach((prop) => {
                  let value = rowData[prop] && rowData[prop] !== ""
                    ? rowData[prop] + " "
                    : "";

                  if (prop.includes('.')) {
                    value = lod.get(rowData, prop) as string;
                  }

                  return text += value
                });

                text += "'";
              }
              setTypeResDel({ type: "res", ids: ids, resolve, reject });
              dispatch(
                openDialogConfirm({
                  title: t("restoreTitle"),
                  text: t("restoreText") + " " + text + "?",
                })
              );
            }).then(() => setLoading(false))
              .catch(() => setLoading(false));
          },
        });
        act.push({
          icon: DeleteForeverIcon,
          tooltip: t("defDelete"),
          onClick: (_: unknown, rowData: Record<string, unknown>) => {
            setLoading(true);
            new Promise((resolve, reject) => {
              const ids: Ids<string>[] = [];
              elementIdProps.forEach((propId) =>
                ids.push({ name: propId, id: rowData[propId] })
              );

              let text = "";
              if (elementRenderProps.length === 0) {
                text += t("theElement");
              } else {
                text += "'";
                elementRenderProps.forEach(
                  (prop) =>
                  (text +=
                    rowData[prop] && rowData[prop] !== ""
                      ? rowData[prop] + " "
                      : "")
                );
                text += "'";
              }
              setTypeResDel({ type: "del", ids: ids, resolve, reject });
              dispatch(
                openDialogConfirm({
                  title: t("defDeleteTitle"),
                  text: t("defDeleteText") + " " + text + "?",
                })
              );
            }).then(() => setLoading(false))
              .catch(() => setLoading(false));
          },
        });
      }
      if (abilitazione >= Abilitazione.READ) {
        act.push({
          icon: PlaylistAddCheckIcon,
          isFreeAction: true,
          tooltip: t("showValid"),
          onClick: () => {
            toggleViewValidDeleted();
          },
        });
      }
    } else {
      if (abilitazione >= Abilitazione.READ) {
        act.push({
          icon: DeleteSweepIcon,
          isFreeAction: true,
          tooltip: t("showDeleted"),
          onClick: () => {
            toggleViewValidDeleted();
            const materialTable = materialTableRef.current;
            /*@ts-ignore*/
            materialTable.setState({
              /*@ts-ignore*/
              ...materialTable.dataManager.getRenderState(),
              showAddRow: false,
            });
          },
        });
      }
    }

    act.push({
      icon: RefreshIcon,
      isFreeAction: true,
      tooltip: t("refreshData"),
      onClick: () => {
        setLoading(true);
        new Promise((resolve, reject) => {
          refreshData(resolve, reject, abilitazione > Abilitazione.OVERVIEW);
        }).then(() => setLoading(false)).catch(() => setLoading(false));
      },
    });

    if (dataValid && abilitazione >= Abilitazione.READ_UPDATE) {
      if (insertCallback) {
        act.push({
          icon: AddIcon,
          isFreeAction: true,
          tooltip: t("addTooltip"),
          onClick: () => {
            resetErrorStatus();
            insertCallback();
          },
        });
      }
      if (updateCallback) {
        act.push({
          icon: EditIcon,
          tooltip: t("editAndDetailTooltip"),
          onClick: (_: unknown, rowData: Record<string, unknown>) => {
            resetErrorStatus();
            updateCallback(rowData);
          },
        });
      }
    } else if (dataValid && abilitazione >= Abilitazione.READ) {
      if (detailCallback) {
        act.push({
          icon: DescriptionIcon,
          tooltip: t("detailTooltip"),
          onClick: (_: unknown, rowData: Record<string, unknown>) => {
            resetErrorStatus();
            detailCallback(rowData);
          },
        });
      }
    }
    setActions(act);

    let edit: Record<string, unknown> = {};
    if (dataValid) {
      if (abilitazione >= Abilitazione.READ_UPDATE) {
        if (!insertCallback && insert) {
          edit["onRowAdd"] = (newData: object) => {
            return new Promise((resolve, reject) => {
              insertData(newData, resolve, reject);
            });
          };
          edit["onRowAddCancelled"] = () => {
            resetErrorStatus();
            operationCancel();
          };
        }
        if (!updateCallback && update) {
          edit["onRowUpdate"] = (newData: object) => {
            return new Promise((resolve, reject) => {
              updateData(newData, resolve, reject);
            })
          };
          edit["onRowUpdateCancelled"] = () => {
            resetErrorStatus();
            operationCancel();
          };
        }
      }
      if (abilitazione >= Abilitazione.READ_UPDATE_LOGICDELETE) {
        edit["onRowDelete"] = (oldData: Record<string, unknown>) =>
          new Promise((resolve, reject) => {
            const ids: Ids<string>[] = [];
            elementIdProps.forEach((propId) =>
              ids.push({ name: propId, id: oldData[propId] })
            );
            logicalDeleteData(ids, resolve, reject);
          });
      }
    }
    setEditable(edit);
  }, [abilitazione, dataValid, detailCallback, dispatch, elementIdProps, elementRenderProps, fetchAllDeleted, fetchAllValid, fixedProps, insert, insertCallback, logicalDel, operationCancel, resetErrorStatus, setToggleValidDeleted, t, update, updateCallback]);

  useEffect(() => {
    const comp: Components = {};

    comp["Cell"] = (props) => {
      if (localizedDatePicker && props.columnDef.type === "date") {
        const formattedDate = props.value ? getDateDDMMYYYY(new Date(props.value)) : '';
        const updatedProps = {
          ...props,
          "value": formattedDate,
        }
        return <MTableCell {...updatedProps} />
      }
      else if (localizedDatePicker && props.columnDef.type === "datetime") {
        const formattedDate = props.value ? getDateDDMMYYYY_HHMM(new Date(props.value)) : '';
        const updatedProps = {
          ...props,
          "value": formattedDate,
        }
        return <MTableCell {...updatedProps} />
      }
      else return <MTableCell {...props} />
    }

    comp["Toolbar"] = (props) => {
      if ((insertCallback || insert) && abilitazione >= Abilitazione.READ_UPDATE) {
        const INSERT_ICON = 2;

        /**
         * Se c'è un errore su props.actions[INSERT_ICON] come undefined,
         * controlla l'abilitazione che viene passata su CrudMaterialTable
         */
        const preInsertCallback = props.actions[INSERT_ICON].onClick;

        props.actions[INSERT_ICON].onClick = () => {
          resetErrorStatus();
          preInsertCallback();
        }
      }

      return <>
        {!dataValid ? (
          <div style={{ color: theme.palette.secondary.main }}>
            {" "}
            <MTableToolbar {...props} />
            <Grid container justifyContent="center">
              <Typography variant="h5">{t("deletedPl")}</Typography>
            </Grid>
          </div>
        ) : (
          <MTableToolbar {...props} />
        )}
        {errorMessage && errorMessage.length > 0 && (
          <Grid container justifyContent="center">
            <Typography color="secondary" variant="body1">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        {generalMessage && generalMessage.text && generalMessage.color && generalMessage.text.length > 0 && (
          <Grid container justifyContent="center">
            <Typography style={{ color: generalMessage.color }} variant="body1">
              {generalMessage.text}
            </Typography>
          </Grid>
        )}
      </>
    };

    comp["EditField"] = (props) => {
      if (localizedDatePicker && props.columnDef.type === "date") {
        return (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={
              i18n.language === "it-IT" || i18n.language === "it" ? itIT : enGB
            }
          >
            <KeyboardDatePicker
              okLabel={t('insertLabel')}
              clearLabel={t("clearLabel")}
              cancelLabel={t("cancelLabel")}
              clearable
              margin="none"
              format="dd/MM/yyyy"
              value={props.value ? props.value : null}
              error={props.error}
              helperText={props.helperText}
              onChange={(d) => {
                if (d) {
                  let month = (d.getMonth() + 1).toString().padStart(2, '0');
                  let day = d.getDate().toString().padStart(2, '0');
                  props.onChange(d.getFullYear() + "-" + month + "-" + day + "T00:00:00");
                } else props.onChange(null);
              }}
              KeyboardButtonProps={{ "aria-label": "change date" }}
            />
          </MuiPickersUtilsProvider>
        );
      } else if (
        nullableTextFields &&
        props.columnDef.type === "string" &&
        (props.value === null || props.value === undefined)
      ) {
        let editProps = { ...props, value: "" };
        return <MTableEditField {...editProps} />;
      } else return <MTableEditField {...props} />;
    };

    comp['Row'] = (_props) => {
      const MODIFY_ICON = 3 - (insertCallback || insert ? 0 : 1);
      const DELETE_ICON = 4 - (insertCallback || insert ? 0 : 1);

      const props = { ..._props }

      if (props.actions[MODIFY_ICON] && props.actions[DELETE_ICON]) {

        // Prendere l'oggetto per update e delete
        const updateButton: Record<string, (e: unknown, rowData: unknown) => void> = updateCallback || typeof props.actions[MODIFY_ICON] !== 'function' ? props.actions[MODIFY_ICON] : props.actions[MODIFY_ICON]();
        const deleteButton: Record<string, (e: unknown, rowData: unknown) => void> = typeof props.actions[DELETE_ICON] !== 'function' ? props.actions[DELETE_ICON] : props.actions[DELETE_ICON]();

        // Prendere l'onClick callback di ogni buttone
        const preUpdateCallback: (e: unknown, rowData: unknown) => void = updateButton['onClick'];
        const preDeleteCallback: (e: unknown, rowData: unknown) => void = deleteButton['onClick'];

        // La nuova callback contiene il reset dello stato nello store e la callback originale
        updateButton['onClick'] = (e: any, rowData: any) => {
          resetErrorStatus();
          preUpdateCallback(e, rowData);
        }

        deleteButton['onClick'] = (e: any, rowData: any) => {
          resetErrorStatus();
          preDeleteCallback(e, rowData);
        }

        // Reimpostare le action
        props.actions[MODIFY_ICON] = updateCallback || typeof props.actions[MODIFY_ICON] !== 'function' ? updateButton : () => updateButton;
        props.actions[DELETE_ICON] = typeof props.actions[DELETE_ICON] !== 'function' ? deleteButton : () => deleteButton;
      }

      return <MTableBodyRow {...props} />
    }

    setComponents(comp);
  }, [abilitazione, dataValid, errorMessage, generalMessage, i18n.language, insert, insertCallback, localizedDatePicker, nullableTextFields, resetErrorStatus, t, theme.palette.secondary.main, updateCallback]);

  const exportMenuOptions = useMemo(() => [
    {
      label: t("exportPDF"),
      exportFunc: (cols: Record<string, any>[], data: Record<string, any>) => {
        let dataArray = data as any[][];
        let modData = dataArray.map((da) => {
          return Object.values(da).map((el) => {
            const pattern: RegExp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}$/;

            if (pattern.test(el)) {
              return getDateDDMMYYYY(new Date(el));
            }

            if (typeof el === "boolean") {
              return el ? t("yes") : t("no");
            } else return el;
          });
        });
        const imgHeight = 25;
        const doc = new jsPDF({ orientation: isExportLandscape ? "landscape" : "portrait" });
        if (logoImage) {
          const imgWidth = (logoImage.width * imgHeight) / logoImage.height;
          doc.addImage(logoImage, "PNG", 15, 15, imgWidth, imgHeight);
        }
        doc.text(
          title + (!dataValid ? " - " + t("deletedPl").toUpperCase() : ""),
          15,
          imgHeight + 22
        );

        // fixedProps header
        exportDataExtra && exportDataExtra.head &&
          autoTable(doc, {
            startY: imgHeight + 27,
            head: [exportDataExtra.head.title],
            body: [exportDataExtra.head.value],
            headStyles: {
              fillColor: theme.palette.secondary.main,
            }
          });

        // data
        autoTable(doc, {
          startY: exportDataExtra ? undefined : imgHeight + 27,
          head: [cols],
          body: modData,
          headStyles: {
            fillColor: theme.palette.primary.main,
          },
          theme: "grid",
        });

        exportDataExtra && exportDataExtra.extra &&
          exportDataExtra.extra.forEach((extra) => {
            autoTable(doc, {
              startY: imgHeight + 27,
              head: [Object.keys(extra)],
              body: [Object.values(extra)],
              headStyles: {
                fillColor: theme.palette.primary.main,
              }
            });
          });

        doc.save(title + ".pdf");
      },
    },
    {
      label: t("exportCSV"),
      exportFunc: (cols, data: Record<string, any>[]) => {
        const sep = '|'
        let csvData = "sep=" + sep + "\n";
        if (!dataValid) csvData += t("deletedPl").toUpperCase() + "\n";
        // headers
        cols.forEach((col, ind) => {
          csvData += col.title;
          if (ind !== cols.length - 1) csvData += sep;
          else csvData += "\n";
        });
        // data
        data?.forEach((row, ind) => {
          const values = Object.values(row);
          values?.forEach((el, ind) => {
            csvData += el;
            if (ind !== cols.length - 1) csvData += sep;
          });
          if (ind !== data.length - 1) csvData += "\n";
        });
        var myFile = new File([csvData], title + ".csv", {
          type: "text/csv;charset=utf-8",
        });
        saveAs(myFile);
      },
    },
  ], [dataValid, exportDataExtra, isExportLandscape, logoImage, t, theme.palette.primary.main, theme.palette.secondary.main, title]);

  const options = useMemo(() => {
    return {
      toolbarButtonAlignment: "left" as "left",
      addRowPosition: 'first' as 'first',
      columnsButton: columnsButton,
      exportAllData: true,
      thirdSortClick: false,
      exportMenu: exportType === ExportType.PDF || exportType === ExportType.CSV
        ? [exportMenuOptions[exportType]]
        : exportMenuOptions,
    };
  }, [columnsButton, exportMenuOptions, exportType]);

  return {
    loading,
    materialTableRef,
    localization,
    editable,
    actions,
    options,
    components,
  };
};
export default useCrudMaterialTable;
