import { Fields } from "./Fields";
import { LookupAnagraficaSoggettoElem } from "./AnagraficaSoggetti";
import {
  validateInputNumberMinMax,
  validateInputTextMinLen,
  validateInputTextMinMaxLen,
  validateRequired,
} from "../utils/utilfunctions";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Box } from "@material-ui/core";

export type PresenzaKeys = keyof Presenza;

export const elementIdProps: PresenzaKeys[] = ['idStruttura', 'idDipartimento', 'idUnitaOperativa', 'idAnagraficaSoggetto', 'idPresenza'];
export const elementRenderProps: PresenzaKeys[] = ["presenzaData"];

export const allFields: Fields[] = [
  {
    field: "idAnagraficaSoggetto", titleKey: "registryTitle", removable: false, required: false, show: true, lookupField: true, editable: "onUpdate", colsNum: 12,
    readonly: true, validate: (x: string, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
  },

  /** Non esiste in DTO */
  {
    field: "idAnagraficaSoggetto int", titleKey: "subjectRegistryParam", required: true, readonly: false, show: false, lookupField: true, editable: "onAdd", colsNum: 8,
    showOn: "form", validate: (x: string, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
  },
  {
    field: "includiInseriti", type: 'boolean', titleKey: "includeInsertedParam", required: false, show: false, colsNum: 4, editable: "onAdd",
  },
  {
    field: "idAnagraficaSoggetto est", titleKey: "otherSubjectRegistryParam", required: true, readonly: false, show: false, minLen: 3, autocomplete: true, colsNum: 8,
    editable: "onAdd", showOn: "form",
    validate: (x: string, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
    validate2: (x: string, helperText: string) => validateInputTextMinLen(x, 3, false, helperText, true), keyTradValidation2: "shortParam3Validation",
  },
  {
    field: "idQualifica", titleKey: "qualificationParam", required: true, show: false, lookupField: true, readonly: false, editable: "onAdd", colsNum: 8,
    validate: (x: string, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
  },
  {
    field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: false, removable: true, show: true, editable: "never"
  },

  // Riga 2
  {
    field: "idTurno", titleKey: "shiftParam", required: false, removable: false, show: false, lookupField: true, defaultValue: "", editable: "onAdd", colsNum: 6, showOn: 'form'
  },
  {
    field: "turnoDescrizione", titleKey: "shiftParam", type: 'string', required: false, removable: false, show: true, defaultValue: "", editable: "never"
  },
  {
    field: "idTurnoAssenza", titleKey: "absenceShiftParam", required: false, removable: false, show: false, lookupField: true, defaultValue: "", colsNum: 6, showOn: 'form',
    editable: "onAdd"
  },
  {
    field: "turnoAssenzaDescrizione", titleKey: "absenceShiftParam", type: 'string', required: false, removable: false, show: true, defaultValue: ""
  },

  // Riga 3 Turno
  {
    field: "reperibilita", type: 'boolean', titleKey: "availabilityParam", required: false, show: true, colsNum: 6, showOn: 'hidden'
  },
  {
    field: "prestazioneAggiuntiva", type: 'boolean', titleKey: "additionalPerformance", required: false, show: true, colsNum: 6, showOn: 'hidden'
  },

  // Riga 3 Assenza
  {
    field: "idArticoloLegge", type: 'numeric', titleKey: "articleParam", required: false, show: false, lookupField: true, showOn: "form", colsNum: 12
  },
  {
    field: "articoloLeggeDescrizione", type: 'string', titleKey: "articleParam", required: false, show: false, editable: "never", showOn: "table",
  },

  // Riga 4
  {
    field: "oraInizioTurno", type: 'time', titleKey: "turnStartParam", required: true, show: true, colsNum: 3, isLabel: true,
    field2Validation: "oraFineTurno", validate: (x: any, s: string) => true,
  },
  {
    field: "timbraturaInizioTurnoIndicatore", type: "string", titleKey: " ", required: false, show: false, readonly: true, colsNum: 2, showOn: "form", isLabel: true,
  },
  {
    field: "oraFineTurno", type: 'time', titleKey: "turnEndDateParam", required: false, show: false, colsNum: 3, isLabel: true,
    field2Validation: "oraInizioTurno", validate: (x: any, s: string) => true,
  },
  {
    field: "timbraturaFineTurnoIndicatore", type: "string", titleKey: " ", required: false, show: false, readonly: true, colsNum: 2, showOn: "form", isLabel: true,

  },
  {
    field: "oreTurno", type: 'numeric', titleKey: "hoursParam", required: false, show: true, minVal: 0, maxVal: 24, readonly: true, colsNum: 1,
    validate: (x: number, helperText: string) => validateInputNumberMinMax(x, 0, 24, false, helperText, true), keyTradValidation: "between0And24Validation"
  },
  {
    field: "minutiTurno", type: 'numeric', titleKey: "minutesParam", required: false, show: true, minVal: 0, maxVal: 59, readonly: true, colsNum: 1,
    validate: (x: number, helperText: string) => validateInputNumberMinMax(x, 0, 59, false, helperText, true), keyTradValidation: "between0And59Validation"
  },

  // Riga 5
  {
    field: "nota", type: 'string', titleKey: "noteParam", required: false, show: false, minLen: 0, maxLen: 255, colsNum: 12,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 255, false, helperText, true), keyTradValidation: "size0to255ParamValidation",
  },
  {
    field: "approvazione", titleKey: "approval", type: "datetime", required: false, removable: false, show: true, defaultValue: undefined,
    render: (rowData: Presenza) => {
      let valoreRitorno = ""
      if (!rowData.approvazioneRichiesta) {
        valoreRitorno = "N/A"
      } else if (rowData.approvazioneConcessaData === null && rowData.approvazioneNegataData === null)
        valoreRitorno = "In Attesa"
      else if (rowData.approvazioneConcessaData)
        valoreRitorno = "Concessa"
      else if (rowData.approvazioneNegataData)
        valoreRitorno = "Negata"
      return <>{valoreRitorno} </>
    }
  },
  {
    field: 'presenzaPianificata', titleKey: 'attendanceScheduled', required: false, show: true, showOn: 'table',
    render: (rowData: Presenza) => {
      if (rowData.progressivo != null) {
        return <Box display='flex'>
          <FiberManualRecordIcon fontSize='large' htmlColor='#0f0' />
        </Box>
      }
      return '';
    }
  }
];

export const allFieldsPresenzeTimbrature: Fields[] = [
  {
    field: "idAnagraficaSoggetto", titleKey: "registryTitle", removable: false, required: false, show: true, lookupField: true, editable: "onUpdate", colsNum: 12, readonly: true, showOn: "table",

  },
  {
    field: "idQualifica", titleKey: "qualificationParam", required: true, show: false, lookupField: true, readonly: false, editable: "onAdd",

  },

  {
    field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: false, removable: true, show: true, editable: "never"
  },

  {
    field: "idTurno", titleKey: "shiftParam", required: false, removable: false, show: false, lookupField: true, defaultValue: "", editable: "onAdd"
  },

  {
    field: "turnoDescrizione", titleKey: "shiftParam", required: false, removable: false, show: true, defaultValue: "", editable: "never"
  },
  {
    field: "oraInizioTurno", type: 'time', titleKey: "turnStartParam", required: true, show: true, readonly: true, colsNum: 3,
    field2Validation: "oraFineTurno",
    validate: (x: any, s: string) => true,
  },
  {
    field: "timbraturaInizioTurnoIndicatore", type: "string", titleKey: " ", required: false, show: false, readonly: true, colsNum: 3, showOn: "form", isLabel: true,
  },
  {
    field: "oraFineTurno", type: 'time', titleKey: "turnEndDateParam", required: false, show: true, readonly: true, colsNum: 3,
    field2Validation: "oraInizioTurno",
    validate: (x: any, s: string) => true,
  },
  {
    field: "timbraturaFineTurnoIndicatore", type: "string", titleKey: " ", required: false, show: false, readonly: true, colsNum: 3, showOn: "form", isLabel: true,
  },

  {
    field: "oreTurno", type: 'numeric', titleKey: "hoursParam", required: true, show: true, minVal: 0, maxVal: 24, readonly: true, colsNum: 3,
    validate: (x: number, helperText: string) => validateInputNumberMinMax(x, 0, 24, false, helperText, true), keyTradValidation: "between0And24Validation"
  },
  {
    field: "minutiTurno", type: 'numeric', titleKey: "minutesParam", required: true, show: true, minVal: 0, maxVal: 59, readonly: true, colsNum: 3,
    validate: (x: number, helperText: string) => validateInputNumberMinMax(x, 0, 59, false, helperText, true), keyTradValidation: "between0And59Validation"
  },
  {
    field: "idArticoloLegge", type: 'numeric', titleKey: "articleParam", required: false, show: false, lookupField: true, showOn: "form",
  },
  {
    field: "articoloLeggeDescrizione", type: 'numeric', titleKey: "articleParam", required: false, show: false, editable: "never", showOn: "table",
  },
  {
    field: "nota", type: 'string', titleKey: "noteParam", required: false, show: false, minLen: 0, maxLen: 255,

  },
  {
    field: "buttonDettagli", type: 'string', titleKey: "", required: false, removable: false, show: true, showOn: 'table',
  },
];

export interface Presenza {
  idStruttura: number;
  strutturaNome: string;
  idDipartimento: number;
  dipartimentoNome: string;
  idUnitaOperativa: number;
  unitaOperativaNome: string;
  idAnagraficaSoggetto: number;
  idPresenza: number;
  anagraficaSoggetto: LookupAnagraficaSoggettoElem[];
  presenzaData: string;
  reperibilita?: boolean;

  idTurno?: number;
  turnoDescrizione?: string;
  turnoDescrizioneBreve?: string;

  idTurnoAssenza?: number;
  turnoAssenzaDescrizione?: string;
  turnoAssenzaDescrizioneBreve?: string;

  idArticoloLegge?: number;
  articoloLeggeDescrizione?: string;

  idQualifica?: number;
  qualificaDescrizione?: string;
  qualificaDescrizioneBreve?: string;

  idTimbraturaInizioTurno: number,
  idTimbraturaFineTurno: number,

  nota?: string;
  anno?: string;
  mese?: string;
  progressivo?: number;
  coloreTipoPresenza?: 'Verde' | 'Giallo' | 'Rosso';
  version: number;
  oreTurno: number | string;
  minutiTurno: number | string;
  oraInizioTurno: string;
  oraFineTurno: string;
  inizioLungaAssenza: boolean;
  prestazioneAggiuntiva?: boolean;
  approvazioneRichiesta: boolean;
  approvazioneRichiestaMotivo: string;
  approvazioneConcessaData: string;
  approvazioneNegataData: string;
}

export interface PresenzaExtended extends Presenza {
  'idAnagraficaSoggetto int': number | null,
  'idAnagraficaSoggetto est': number | null
}

export interface Legenda {
  ore: number,
  giorni: number,
  turni: {
    idTurno: number,
    ore: number,
    descrizioneBreve: string,
    descrizione: string,
  }[],
  turniAssenza: {
    idTurno: number,
    ore: number,
    descrizioneBreve: string,
    descrizione: string,
  }[],
  motivoCS: string[],
  turniPrecedenti: {
    idTurno: number,
    version: number,
    ore: number,
    descrizioneBreve: string,
    descrizione: string,
    dataPresenza: string,
  }[],
}