import { validateDate, validateDateXGreaterThanY, validateInputNumberMin, validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type AnagraficaSoggettiSchedaProfessionaleKeys = keyof AnagraficaSoggettiSchedaProfessionale;

export const elementIdProps: AnagraficaSoggettiSchedaProfessionaleKeys[] = ["idAnagraficaSoggetto", "dataInizioValidita"];
export const elementRenderProps: AnagraficaSoggettiSchedaProfessionaleKeys[] = [];

export const allFields: Fields[] = [
  {
    field: "dataInizioValidita", type: "date", required: true, show: false, titleKey: "validitystartDateParam", sort: 'desc', group: 'noGroup', editable: 'onAdd', showOn: 'form',
    validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation"
  },

  {
    field: "dataInizioValidita", type: "date", required: true, show: true, titleKey: "updateParam", readonly: true, group: 'noGroup', editable: 'never', showOn: 'table',
  },

  {
    field: "matricola", type: "string", required: true, show: true, titleKey: "numberParam",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, true), keyTradValidation: "size3to50ParamValidation"
  },
  {
    field: "orarioSettimanale", type: "numeric", required: false, show: false, titleKey: "weeklyHoursParam", readonly: false, showOn: 'table',
    validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, false, helperText, false), keyTradValidation: "greaterOrEqualThanZeroValidation",
  },
  {
    field: "tipoContratto", required: false, show: true, titleKey: "typeOfContractParam", lookupField: true,

  },
  {
    field: "dataAssunzione", type: "date", required: false, show: true, titleKey: "assumptionDateParam", sort: 'desc',
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation",
    field2Validation: "dataInizioValidita",
  },
  {
    field: "dataPresaServizio", type: "date", required: false, show: true, titleKey: "dateOfServiceParam", sort: 'desc',
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation",
    field2Validation: "dataInizioValidita",
  },
  {
    field: "dataFinePeriodoProva", type: "date", required: false, show: false, titleKey: "trialPeriodEndDateParam", sort: 'desc',
    validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, false), keyTradValidation: "notValidDateValidation"
  },

  {
    field: "dataFineContratto", type: "date", required: false, show: true, titleKey: "assumptionDateEndParam", sort: 'desc',
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation",
  },

  {
    field: "idMotivo", type: "numeric", required: false, show: false, titleKey: "reasonParamExit", lookupField: true, group: "exit",
  },
  {
    field: "motivoDescrizione", required: false, show: true, titleKey: "reasonParamExit", editable: "never", group: "exit",
  },
  {
    field: "dataUscita", type: "date", required: false, removable: false, show: true, titleKey: "dateLabelexit", sort: 'desc', group: "exit",
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation",
    field2Validation: "dataInizioValidita",
  },

  /********************************** PART TIME **********************************/

  {
    field: "partTimeDataInizio", type: "date", required: false, show: false, titleKey: "partTimeStartDateParam", group: "partTimeTabLabel",
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation"
  },

  {
    field: "partTime", type: "string", required: false, show: false, titleKey: "partTimeParam", group: "partTimeTabLabel",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 50, false, helperText, false), keyTradValidation: "size3to50ParamValidation"
  },

  {
    field: "partTimeTipo", required: false, show: false, titleKey: "partTimeTypeParam", readonly: false, group: "partTimeTabLabel", lookupField: true,
  },

  {
    field: "partTimeOrizzontale", type: "string", required: false, show: false, titleKey: "partTimeHParam", readonly: false, group: "partTimeTabLabel",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, false), keyTradValidation: "size3to255ParamValidation",
  },

  {
    field: "partTimeVerticale", type: "string", required: false, show: false, titleKey: "partTimeVParam", readonly: false, group: "partTimeTabLabel",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, false), keyTradValidation: "size3to255ParamValidation",
  },

  {
    field: "partTimeMotivazione", type: "string", required: false, show: false, titleKey: "partTimeMotiveParam", readonly: false, group: "partTimeTabLabel",
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, false), keyTradValidation: "size3to255ParamValidation",
  },



  /********************************** TIPO INCARICO **********************************/
  {
    field: "idTipoIncarico", lookupField: true, required: false, show: false, titleKey: "jobType", group: "jobType",
  },
  {
    field: "tipoIncaricoDescrizione", required: false, show: true, titleKey: "jobType", group: "jobType", editable: "never",
  },
  {
    field: "dataInizioIncarico", type: "date", required: false, removable: false, show: true, titleKey: "jobStartDateParam", sort: 'desc', group: "jobType",
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation",
    field2Validation: "dataInizioValidita",
  },

  {
    field: "dataFineIncarico", type: "date", required: false, removable: false, show: true, titleKey: "jobEndDateParam", sort: 'desc', group: "jobType",
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation",
    field2Validation: "dataInizioIncarico",
    validate2: (x: string, y: string, helperText: string) => validateDateXGreaterThanY(x, y, true, helperText, false), keyTradValidation2: "greaterEqualThanJobEndDateeValidation"
  },



  /********************************** LEGGE 104 **********************************/

  {
    field: "Legge104Soggetto1DataInizio", type: "date", required: false, show: false, titleKey: "starDateTitle104", colsNum: 4, group: "law104TabLabel", subGroup: ['subjectTabLabel1'], showOn: 'form',
    validate: (x: string, helperText: string) => validateDate(x, 1992, null, helperText, false), keyTradValidation: "notValidDateValidation"
  },
  {
    field: "Legge104Soggetto1DataFine", type: "date", required: false, show: false, titleKey: "endDateTitle104", colsNum: 4, group: "law104TabLabel", subGroup: ['subjectTabLabel1'], showOn: 'form',
    validate: (x: string, helperText: string) => validateDate(x, 1992, null, helperText, false), keyTradValidation: "notValidDateValidation"
  },
  {
    field: "Legge104Soggetto1SeStesso", type: "boolean", required: false, show: false, titleKey: "forHimSelf", colsNum: 3, group: "law104TabLabel", subGroup: ['subjectTabLabel1'], showOn: 'form',
  },
  {
    field: "Legge104Soggetto2DataInizio", type: "date", required: false, show: false, titleKey: "starDateTitle104", colsNum: 4, group: "law104TabLabel", subGroup: ['subjectTabLabel2'], showOn: 'form',
    validate: (x: string, helperText: string) => validateDate(x, 1992, null, helperText, false), keyTradValidation: "notValidDateValidation"
  },
  {
    field: "Legge104Soggetto2DataFine", type: "date", required: false, show: false, titleKey: "endDateTitle104", colsNum: 4, group: "law104TabLabel", subGroup: ['subjectTabLabel2'], showOn: 'form',
    validate: (x: string, helperText: string) => validateDate(x, 1992, null, helperText, false), keyTradValidation: "notValidDateValidation"
  },
  {
    field: "Legge104Soggetto2SeStesso", type: "boolean", required: false, show: false, titleKey: "forHimSelf", colsNum: 3, group: "law104TabLabel", subGroup: ['subjectTabLabel2'], showOn: 'form',
  },
  {
    field: "Legge104Soggetto3DataInizio", type: "date", required: false, show: false, titleKey: "starDateTitle104", colsNum: 4, group: "law104TabLabel", subGroup: ['subjectTabLabel3'], showOn: 'form',
    validate: (x: string, helperText: string) => validateDate(x, 1992, null, helperText, false), keyTradValidation: "notValidDateValidation"
  },
  {
    field: "Legge104Soggetto3DataFine", type: "date", required: false, show: false, titleKey: "endDateTitle104", colsNum: 4, group: "law104TabLabel", subGroup: ['subjectTabLabel3'], showOn: 'form',
    validate: (x: string, helperText: string) => validateDate(x, 1992, null, helperText, false), keyTradValidation: "notValidDateValidation"
  },
  {
    field: "Legge104Soggetto3SeStesso", type: "boolean", required: false, show: false, titleKey: "forHimSelf", colsNum: 3, group: "law104TabLabel", subGroup: ['subjectTabLabel3'], showOn: 'form',
  },



  /********************************** LEGGE 151 **********************************/

  {
    field: "legge15101DataInizio", type: "date", required: false, show: false, titleKey: "law15101StartDateParam", group: "law151TabLabel",
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation"
  },

  {
    field: "idArticoloLeggeLegge15101", required: false, removable: false, show: false, titleKey: "lawArticle15101Param", lookupField: true, group: "law151TabLabel", editable: "always",
    validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, false, helperText, false), keyTradValidation: "greaterOrEqualThanZeroValidation"
  },
  {
    field: "articoloLeggeLegge15101Descrizione", required: false, show: true, titleKey: "lawArticle15101Param", group: "law151TabLabel", editable: "never"
  },
  {
    field: "legge15101dataFine", type: "date", required: false, show: true, titleKey: "endDateTitle", group: "law151TabLabel",
    validate: (x: string, helperText: string) => validateDate(x, 1960, null, helperText, false), keyTradValidation: "notValidDateValidation",
  },


]

export interface AnagraficaSoggettiSchedaProfessionale {
  idAnagraficaSoggetto: number,
  dataInizioValidita: string,
  tipoContratto: string,
  matricola: string,
  dataAssunzione?: string,
  dataFinePeriodoProva?: string,
  dataAnzianitaServizio?: string,
  dataPresaServizio?: string,
  dataFineContratto?: string,
  idMotivo?: number,
  dataUscita: string,
  legge15101DataInizio?: string,
  idArticoloLeggeLegge15101?: number,
  articoloLeggeLegge15101Descrizione?: string,
  partTime?: string,
  partTimeDataInizio?: string,
  partTimeTipo: string,       // ['V', 'O', 'OA']
  partTimeOrizzontale: string,
  partTimeVerticale: string,
  partTimeMotivazione: string,
  Legge104Soggetto1DataInizio: string,
  Legge104Soggetto1DataFine: string,
  Legge104Soggetto1SeStesso: boolean,
  Legge104Soggetto2DataInizio: string,
  Legge104Soggetto2DataFine: string,
  Legge104Soggetto2SeStesso: boolean,
  Legge104Soggetto3DataInizio: string,
  Legge104Soggetto3DataFine: string,
  Legge104Soggetto3SeStesso: boolean,
  specializzazione?: string,
  dataInizioIncarico?: string,
  dataFineIncarico?: string,
  orarioSettimanale: number,
  idTipoIncarico: number,
  version: number

}


