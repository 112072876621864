import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { create, getById, refr, upd, } from '../../services/services.service'
import { UnitaOperativePianificazione, Pianificazione, PianificazioneQualifica, PianificazioniAnagraficaSoggetti, PianificazioneKeys } from '../../models/Pianificazioni';
import { isNotNullAndUndefined } from '../../utils/utilfunctions';

const microservice = "pianificazioni";
const entity = "unitaoperativepianificazioni";

interface ModificaReperibilita {
  valore: boolean,
  pianificazione: Pianificazione,
}

interface PianificazioniState {
  statusValidUnitaOperativaPianificazioni: StatusEnum,
  validUnitaOperativaPianificazioni: UnitaOperativePianificazione | null,

  statusValidPianificazioni: StatusEnum,
  validPianificazioni: Pianificazione[],

  statusValidPianificazioniQualifiche: StatusEnum,
  validPianificazioniQualifiche: PianificazioneQualifica[],

  statusValidPianificazioniAnagraficaSoggetti: StatusEnum,
  validPianificazioniAnagraficaSoggetti: PianificazioniAnagraficaSoggetti[],

  statusValidPianificazione: StatusEnum,
  validPianificazione: Pianificazione | null,

  statusSavePianificazione: StatusEnum,
  saved: boolean,
  isTurnoDuplicate: boolean,

  initPianificazione: Pianificazione[],
  error: string | null,
}

const initialState: PianificazioniState = {
  statusValidUnitaOperativaPianificazioni: StatusEnum.Succeeded,
  validUnitaOperativaPianificazioni: null,
  statusValidPianificazioni: StatusEnum.Succeeded,
  validPianificazioni: [],
  statusValidPianificazioniQualifiche: StatusEnum.Succeeded,
  validPianificazioniQualifiche: [],
  statusValidPianificazioniAnagraficaSoggetti: StatusEnum.Succeeded,
  validPianificazioniAnagraficaSoggetti: [],
  statusValidPianificazione: StatusEnum.Succeeded,
  validPianificazione: null,

  statusSavePianificazione: StatusEnum.Succeeded,
  saved: false,
  isTurnoDuplicate: false,

  initPianificazione: [],
  error: null
}

export const fetchPianificazioneById = createAsyncThunk(microservice + '/fetchPianificazioneById', async (ids: Ids<PianificazioneKeys>[]) => {
  const args = ids.map(elem => elem.id)
  const response = await getById(microservice, 'pianificazione', args);
  return response.data as Pianificazione;
});

// {{server}}/api/gestioneturni/pianificazioni/cerca/unitaoperativepianificazioni/id/{idStruttura}/{idDipartiemento}/{idUO}/{anno}/{mese}
export const fetchByYearAndMonth = createAsyncThunk(microservice + '/fetchByYearAndMonth', async (obj: {
  idStruttura: number,
  idDipartimento: number,
  idUnitaOperativa: number,
  anno: number,
  mese: number | string
}) => {
  const args = Object.values(obj);
  const response = await getById(microservice, entity, args);
  return response.data as UnitaOperativePianificazione;
});

// @GetMapping("cerca/pianificazioni/id/{idAnagraficaSoggetto}/{anno}/{mese}")
export const fetchByidAnagraficaSoggettoandYearAndMonth = createAsyncThunk(microservice + '/fetchByidAnagraficaSoggettoandYearAndMonth', async (obj: {
  idAnagraficaSoggetto: number | string,
  anno: number,
  mese: number | string
}) => {
  const args = Object.values(obj);
  const response = await getById(microservice, 'pianificazioni', args);
  return response.data as Pianificazione[];
});

//@GetMapping(value = {"cerca/pianificazioni/id/{idAnagraficaSoggetto}/{idStruttura}/{idDipartimento}/{idUnitaOperativa}/{pianificazioneData}/turno/{idTurno}" , "cerca/pianificazioni/id/{idAnagraficaSoggetto}/{idStruttura}/{idDipartimento}/{idUnitaOperativa}/{pianificazioneData}/assenza/{idTurnoAssenza}"})
export const checkTurnoOrTurnoAssenzaPianificabile = createAsyncThunk(microservice + '/checkTurnoOrTurnoAssenzaPianificabile', async (obj: {
  idAnagraficaSoggetto: number,
  idStruttura: number,
  idDipartimento: number,
  idUnitaOperativa: number,
  dataRiferimento: string,
  idTurno?: number,
  idTurnoAssenza?: number,
}) => {
  const args = [
    obj.idAnagraficaSoggetto,
    obj.idStruttura,
    obj.idDipartimento,
    obj.idUnitaOperativa,
    obj.dataRiferimento,
    obj.idTurno ? 'turno' : 'assenza',
    obj.idTurno ?? obj.idTurnoAssenza,
  ];
  const response = await getById(microservice, 'pianificazioni', args);
  return response.data as boolean;
});

export const insert = createAsyncThunk(microservice + '/insert', async (obj: { unitaOperativePianificazione: UnitaOperativePianificazione, pianificazione: Pianificazione[], stato: string }) => {
  const newUnitaOperativePianificazione: Partial<UnitaOperativePianificazione> = {
    ...obj.unitaOperativePianificazione,
    pianificazioni: obj.pianificazione,
    stato: obj.stato?.toLowerCase() === 'salvata' ? 'INLAVORAZIONE' : obj.stato,
  };

  delete newUnitaOperativePianificazione.pianificazioniAnagraficaSoggetti;
  delete newUnitaOperativePianificazione.pianificazioniQualifiche;

  const response = await create(newUnitaOperativePianificazione, microservice, entity);
  return response.data as UnitaOperativePianificazione;
});

export const update = createAsyncThunk(microservice + '/update', async (obj: { unitaOperativePianificazione: UnitaOperativePianificazione, stato: string }) => {
  const newUnitaOperativePianificazione: Partial<UnitaOperativePianificazione> = {
    ...obj.unitaOperativePianificazione,
    stato: obj.stato,
  };

  delete newUnitaOperativePianificazione.pianificazioni;
  delete newUnitaOperativePianificazione.pianificazioniAnagraficaSoggetti;
  delete newUnitaOperativePianificazione.pianificazioniQualifiche;

  const response = await upd(newUnitaOperativePianificazione, microservice, entity);
  return response.data as UnitaOperativePianificazione;
});

export const refreshSoggettoEQualifica = createAsyncThunk(microservice + '/refreshSoggettoEQualifica', async (obj: { unitaOperativePianificazione: UnitaOperativePianificazione, pianificazione: Pianificazione[] }) => {
  const newUnitaOperativePianificazione: Partial<UnitaOperativePianificazione> = {
    ...obj.unitaOperativePianificazione,
    pianificazioni: obj.pianificazione,
    stato: obj.unitaOperativePianificazione.stato,
  };

  delete newUnitaOperativePianificazione.pianificazioniAnagraficaSoggetti;
  delete newUnitaOperativePianificazione.pianificazioniQualifiche;

  const response = await refr(newUnitaOperativePianificazione, microservice, entity);
  return response.data as UnitaOperativePianificazione;
});

export const pianificazioneSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    modificaReperibilita: (state: PianificazioniState, { payload }: PayloadAction<ModificaReperibilita>) => {
      const index = state.validPianificazioni.findIndex(elem => {
        return areEquals(elem, payload.pianificazione);
      });

      if (index >= 0) {
        state.validPianificazioni[index].reperibilita = payload.valore;
      }
    },
    modificaTurnoFisso: (state: PianificazioniState, { payload }: PayloadAction<Pianificazione>) => {
      const index = state.validPianificazioni.findIndex(elem => {
        return areEquals(elem, payload);
      });

      if (index >= 0) {
        state.validPianificazioni[index].turnoFisso = payload.turnoFisso;
        state.validPianificazioni[index].modificaTurnoFisso = payload.modificaTurnoFisso;
      }
    },
    deleteTurno: (state: PianificazioniState, { payload }: PayloadAction<Pianificazione>) => {
      // delete element, then filter with (elem !== undefined)
      const index = state.validPianificazioni.findIndex(elem => {
        return areEquals(elem, payload);
      });

      // Get all elements from first position to index (excluded) and from index+1 to the last element
      // then make a union of the first part and the last part
      if (index >= 0) {
        const pre = state.validPianificazioni?.slice(0, index);
        const post = state.validPianificazioni?.slice(index + 1);

        state.validPianificazioni = [...pre, ...post];
      }
    },
    addTurno: (state: PianificazioniState, { payload }: PayloadAction<Pianificazione>) => {
      const index = state.validPianificazioni.findIndex((elem) => {
        return areEquals(elem, payload);
      });

      if (index === -1)
        state.validPianificazioni.push(payload);
    },
    addAnagrafica: (state: PianificazioniState, { payload }: PayloadAction<PianificazioniAnagraficaSoggetti>) => {
      const index = state.validPianificazioniAnagraficaSoggetti.findIndex((elem) => {
        return payload.idAnagraficaSoggetto && payload.idQualifica &&
          elem.idAnagraficaSoggetto === (payload.idAnagraficaSoggetto as number) && elem.idQualifica === (payload.idQualifica as number)
      })

      if (index === -1)
        state.validPianificazioniAnagraficaSoggetti.push(payload);
    },
    reset: (state: PianificazioniState) => {
      return initialState;
    },
    resetError: (state: PianificazioniState) => {
      state.error = initialState.error;
      state.statusValidPianificazione = initialState.statusValidPianificazione;
      state.statusValidPianificazioni = initialState.statusValidPianificazioni;
      state.statusValidPianificazioniAnagraficaSoggetti = initialState.statusValidPianificazioniAnagraficaSoggetti;
      state.statusValidPianificazioniQualifiche = initialState.statusValidPianificazioniQualifiche;
      state.statusValidUnitaOperativaPianificazioni = initialState.statusValidUnitaOperativaPianificazioni;
    }
  },
  extraReducers: builder => {
    // fetchPianificazioneById
    builder.addCase(fetchPianificazioneById.pending, (state) => {
      state.statusValidPianificazione = StatusEnum.Loading;
    })
    builder.addCase(fetchPianificazioneById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidPianificazione = StatusEnum.Failed;
      state.validPianificazione = null;
    })
    builder.addCase(fetchPianificazioneById.fulfilled, (state, { payload }: PayloadAction<Pianificazione>) => {
      state.statusValidPianificazione = StatusEnum.Succeeded;
      state.validPianificazione = payload;
    })

    // fetchByYearAndMonth
    builder.addCase(fetchByYearAndMonth.pending, (state) => {
      state.statusValidUnitaOperativaPianificazioni = StatusEnum.Loading;
    })
    builder.addCase(fetchByYearAndMonth.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";

      state.statusValidUnitaOperativaPianificazioni = StatusEnum.Failed;
      state.validUnitaOperativaPianificazioni = null;

      state.statusValidPianificazioni = StatusEnum.Failed;
      state.validPianificazioni = [];

      state.statusValidPianificazioniQualifiche = StatusEnum.Failed;
      state.validPianificazioniQualifiche = [];

      state.statusValidPianificazioniAnagraficaSoggetti = StatusEnum.Failed;
      state.validPianificazioniAnagraficaSoggetti = [];

      state.initPianificazione = [];
    })
    builder.addCase(fetchByYearAndMonth.fulfilled, (state, { payload }: PayloadAction<UnitaOperativePianificazione>) => {
      state.statusValidUnitaOperativaPianificazioni = StatusEnum.Succeeded;
      state.validUnitaOperativaPianificazioni = payload;

      state.statusValidPianificazioni = StatusEnum.Succeeded;
      state.validPianificazioni = payload?.pianificazioni ?? [];

      state.statusValidPianificazioniQualifiche = StatusEnum.Succeeded;
      state.validPianificazioniQualifiche = payload?.pianificazioniQualifiche ?? [];

      state.statusValidPianificazioniAnagraficaSoggetti = StatusEnum.Succeeded;
      state.validPianificazioniAnagraficaSoggetti = payload?.pianificazioniAnagraficaSoggetti ?? [];

      state.initPianificazione = payload?.pianificazioni ?? [];
    })

    // fetchByidAnagraficaSoggettoandYearAndMonth
    builder.addCase(fetchByidAnagraficaSoggettoandYearAndMonth.pending, (state) => {
      state.statusValidUnitaOperativaPianificazioni = StatusEnum.Loading;
    })
    builder.addCase(fetchByidAnagraficaSoggettoandYearAndMonth.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";

      state.statusValidUnitaOperativaPianificazioni = StatusEnum.Failed;
      state.validUnitaOperativaPianificazioni = null;

      state.statusValidPianificazioni = StatusEnum.Failed;
      state.validPianificazioni = [];

      state.statusValidPianificazioniQualifiche = StatusEnum.Failed;
      state.validPianificazioniQualifiche = [];

      state.statusValidPianificazioniAnagraficaSoggetti = StatusEnum.Failed;
      state.validPianificazioniAnagraficaSoggetti = [];

      state.initPianificazione = [];
    })
    builder.addCase(fetchByidAnagraficaSoggettoandYearAndMonth.fulfilled, (state, { payload }: PayloadAction<Pianificazione[]>) => {
      state.error = initialState.error;

      state.statusValidPianificazioni = StatusEnum.Succeeded;
      state.validPianificazioni = payload ?? [];

      state.initPianificazione = payload ?? [];
    })


    //checkTurnoPianificaizone
    builder.addCase(checkTurnoOrTurnoAssenzaPianificabile.pending, (state) => {
      state.statusValidPianificazioni = StatusEnum.Loading;
    })
    builder.addCase(checkTurnoOrTurnoAssenzaPianificabile.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidPianificazioni = StatusEnum.Failed;
      state.isTurnoDuplicate = false;
    })
    builder.addCase(checkTurnoOrTurnoAssenzaPianificabile.fulfilled, (state, { payload }: PayloadAction<boolean>) => {
      state.error = initialState.error;
      state.statusValidPianificazioni = StatusEnum.Succeeded;
      state.isTurnoDuplicate = payload;
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidUnitaOperativaPianificazioni = StatusEnum.Loading;
      state.statusSavePianificazione = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaPianificazioni = StatusEnum.Failed;
      state.statusSavePianificazione = StatusEnum.Failed;
      state.saved = false;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<UnitaOperativePianificazione>) => {
      state.statusValidUnitaOperativaPianificazioni = StatusEnum.Succeeded;
      state.validUnitaOperativaPianificazioni = payload;

      state.initPianificazione = payload?.pianificazioni ?? [];

      state.statusSavePianificazione = StatusEnum.Succeeded;
      state.saved = true;
    })

    // saveFinal
    builder.addCase(update.pending, (state) => {
      state.statusValidUnitaOperativaPianificazioni = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaPianificazioni = StatusEnum.Failed;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<UnitaOperativePianificazione>) => {
      state.statusValidUnitaOperativaPianificazioni = StatusEnum.Succeeded;
      state.validUnitaOperativaPianificazioni = payload;

      state.statusValidPianificazioniQualifiche = StatusEnum.Succeeded;
      if (payload?.pianificazioniQualifiche) state.validPianificazioniQualifiche = payload?.pianificazioniQualifiche;

      state.statusValidPianificazioniAnagraficaSoggetti = StatusEnum.Succeeded;
      if (payload?.pianificazioniAnagraficaSoggetti) state.validPianificazioniAnagraficaSoggetti = payload?.pianificazioniAnagraficaSoggetti;

      if (payload?.pianificazioni) state.initPianificazione = payload?.pianificazioni;
    })

    // refreshSoggettoEQualifica
    builder.addCase(refreshSoggettoEQualifica.pending, (state) => {
      state.statusValidUnitaOperativaPianificazioni = StatusEnum.Loading;
    })
    builder.addCase(refreshSoggettoEQualifica.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaPianificazioni = StatusEnum.Failed;
    })
    builder.addCase(refreshSoggettoEQualifica.fulfilled, (state, { payload }: PayloadAction<UnitaOperativePianificazione>) => {
      state.statusValidUnitaOperativaPianificazioni = StatusEnum.Succeeded;
      state.validUnitaOperativaPianificazioni = payload;

      state.statusValidPianificazioniQualifiche = StatusEnum.Succeeded;
      state.validPianificazioniQualifiche = payload?.pianificazioniQualifiche ?? [];

      state.statusValidPianificazioniAnagraficaSoggetti = StatusEnum.Succeeded;
      state.validPianificazioniAnagraficaSoggetti = payload?.pianificazioniAnagraficaSoggetti ?? [];

      state.saved = false;
    })
  }
});

export const { reset, resetError, addTurno, deleteTurno, addAnagrafica, modificaTurnoFisso, modificaReperibilita } = pianificazioneSlice.actions;
export default pianificazioneSlice.reducer;

function areEquals(localElement: Pianificazione, newElement: Pianificazione): boolean {
  return localElement.idAnagraficaSoggetto === newElement.idAnagraficaSoggetto
    && localElement.pianificazioneData.split('T')[0] === newElement.pianificazioneData.split('T')[0]
    && localElement.idQualifica === newElement.idQualifica
    && (
      (isNotNullAndUndefined(newElement.idTurno) && localElement.idTurno === newElement.idTurno)
      || (isNotNullAndUndefined(newElement.idTurnoAssenza) && localElement.idTurnoAssenza === newElement.idTurnoAssenza)
    )
}