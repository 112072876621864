import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const entity = "utenteloggato";

// {
//   "idUtente":36,
//   "nome":"John Nikko",
//   "cognome":"Canoza",
//   "nomeUtente":"canoza5@gmail.com",
//   "organizzazioneDescrizione":"Nousmed",
//   "applicazioneSoftwareDescrizione":"Gestione Turni",
//   "profiloDescrizione":"Amministratore"
// }

interface UtentiLoggatoState {
  idUtente: number | null;
  nome: string | null;
  cognome: string | null;
  nomeUtente: string | null;
  organizzazioneDescrizione: string | null;
  applicazioneSoftwareDescrizione: string | null;
  profiloDescrizione: string | null;
}

const initialState: UtentiLoggatoState = {
  idUtente: null,
  nome: null,
  cognome: null,
  nomeUtente: null,
  organizzazioneDescrizione: null,
  applicazioneSoftwareDescrizione: null,
  profiloDescrizione: null
}

export const utentiLoggatoSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setUtenteLoggato: (state: UtentiLoggatoState, { payload }: PayloadAction<UtentiLoggatoState>) => {
      return {
        ...state,
        ...payload
      };
    }
  }
});

export const { reset, setUtenteLoggato } = utentiLoggatoSlice.actions;
export default utentiLoggatoSlice.reducer;