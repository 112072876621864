import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { responsiveFontSizes, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import LeftSideNavbarWithAppBar from '../core/components/leftSideNavbarWithAppBar/LeftSideNavbarWithAppBar';
import { PrivateRoute } from '../core/components/PrivateRoute';
import LoadingSvg from '../core/components/svgs/LoadingSvg';
import PageLogin from '../core/pages/PageLogin';
import PageForbidden from '../core/pages/PageForbidden';
import PageNotFound from '../core/pages/PageNotFound';
import {
  anagrafichePath, variazioniUOPath, verificaStraordinariPath,
  articoliDiLeggePath, assenzePath, assenzeProgrammatePath, centriDiCostoPath, codiciVisitePath,
  dipartimentiPath, entratiUsciti104Path, dittePath, formazionePath, idoneitaPath, loginPagePath, motiviPath,
  notFoundPath, pianificazioniPath, presenzePath, qualifichePath, strutturePath, turniPath, unitaOperativePath,
  dotazioneOrganicaPath, medicoCompetentePath, presenzeUOPath, abilitazioneUtentePath, richiesteAssenzeProgrammatePath,
  approvaAssenzeProgrammatePath, forbiddenPath, anagraficaFormazionePath, festivitaPath, tipiDocumentiPath, tipiIncaricoPath,
  medicoCompetenteCoordinatorePath, anagraficheCoordinatorePath, approvazionePresenzePath, monitoraggioPath, timbraturePath,
  presenzeDipendentiPath, pianificazioniDipendentiPath, timbratureDipendentiPath, documentiDipendentePath, dipendentiPresenzePath,
  caricamentoDocumentiPath, approvazioneAssenzePath, modelliTerminalePath, approvaLungaAssenzaPath,
} from '../utils/utilconst';
import { useApp } from './useApp';
import themes from '../themes/themes';
import DialogConfirm from '../core/components/dialogs/DialogConfirm';
import ArticoliLeggeW from '../core/components/crudWrappers/ArticoliLeggeW';
import AssenzeW from '../core/components/crudWrappers/AssenzeW';
import CentriDiCostoW from '../core/components/crudWrappers/CentriDiCostoW';
import CodiciVisiteW from '../core/components/crudWrappers/CodiciVisiteW';
import FormazioneW from '../core/components/crudWrappers/FormazioneW';
import IdoneitaW from '../core/components/crudWrappers/IdoneitaW';
import MotiviW from '../core/components/crudWrappers/MotiviW';
import QualificheW from '../core/components/crudWrappers/QualificheW';
import TurniW from '../core/components/crudWrappers/TurniW';
import StruttureW from '../core/components/crudWrappers/StruttureW';
import { itIT } from '@material-ui/core/locale';
import DipartimentiW from '../core/components/crudWrappers/DipartimentiW';
import UnitaOperativeW from '../core/components/crudWrappers/UnitaOperativeW';
import DitteW from '../core/components/crudWrappers/DitteW';
import AssenzeProgrammateW from '../core/components/crudWrappers/AssenzeProgrammateW';
import AnagraficaSoggettiW from '../core/components/crudWrappers/AnagraficaSoggettiW';
import PresenzeW from '../core/components/crudWrappers/PresenzeW';
import EntratiUsciti104W from '../core/components/crudWrappers/EntratiUsciti104W';
import PianificazioniW from '../core/components/crudWrappers/PianificazioniW';
import VariazioniUOW from '../core/components/crudWrappers/VariazioniUOW';
import VerificaStraordinariW from '../core/components/crudWrappers/VerificaStraordinariW';
import DotazioneOrganicaW from '../core/components/crudWrappers/DotazioneOrganicaW';
import MedicoCompetenteW from '../core/components/crudWrappers/MedicoCompetenteW';
import PresenzeUOW from '../core/components/crudWrappers/PresenzeUOW';
import AbilitazioniUtentiW from '../core/components/crudWrappers/AbilitazioniUtentiW';
import RichiesteAssenzeProgrammateW from '../core/components/crudWrappers/RichiesteAssenzeProgrammateW';
import ApprovazioneAssenzeProgrammateW from '../core/components/crudWrappers/ApprovazioneAssenzeProgrammateW';
import FormazioneDipendentiW from '../core/components/crudWrappers/FormazioneDipendentiW';
import PresenzeDipendentiW from '../core/components/crudWrappers/PresenzeDipendentiW';
import PianificazioniDipendentiW from '../core/components/crudWrappers/PianificazioniDipendentiW';
import FestivitaW from '../core/components/crudWrappers/FestivitaW';
import TipoDocumentoW from '../core/components/crudWrappers/TipoDocumentoW';
import TipoIncaricoW from '../core/components/crudWrappers/TipoIncaricoW';
import MonitoraggioW from '../core/components/crudWrappers/monitoraggio/MonitoraggioW';
import ApprovazionePresenzeW from '../core/components/crudWrappers/ApprovazionePresenzeW';
import TimbratureW from '../core/components/crudWrappers/TimbratureW';
import TimbratureDipendenteW from '../core/components/crudWrappers/TimbratureDipendenteW';
import DocumentiDipendenteW from '../core/components/crudWrappers/DocumentiDipendenteW';
import DipendentiPresenzeW from '../core/components/crudWrappers/DipendentiPresenzeW';
import CaricamentoDocumentiW from '../core/components/crudWrappers/CaricamentoDocumentiW';
import ApprovazioneAssenzeW from '../core/components/crudWrappers/ApprovazioneAssenzeW';
import ModelloTerminaleW from '../core/components/crudWrappers/ModelloTerminaleW';
import ChatBox from '../core/components/chatBox/ChatBox';

function App() {
  const { homeUri, logoUri, nomeOrganizzazione, hasIdAppSoftwareCookie } = useApp();

  return (
    <ThemeProvider theme={responsiveFontSizes(createTheme(themes["asl"], itIT))}>
      {
        (homeUri && logoUri)
          ? (
            <Suspense fallback={<LoadingSvg color="primary" width={300} />}>
              <ChatBox />
              <LeftSideNavbarWithAppBar logoUri={logoUri} nomeOrganizzazione={nomeOrganizzazione}>
                <Switch>
                  {/* Configurazione */}
                  <PrivateRoute path={articoliDiLeggePath} exact>
                    <ArticoliLeggeW />
                  </PrivateRoute>
                  <PrivateRoute path={codiciVisitePath} exact>
                    <CodiciVisiteW />
                  </PrivateRoute>
                  <PrivateRoute path={formazionePath} exact>
                    <FormazioneW />
                  </PrivateRoute>
                  <PrivateRoute path={idoneitaPath} exact>
                    <IdoneitaW />
                  </PrivateRoute>
                  <PrivateRoute path={motiviPath} exact>
                    <MotiviW />
                  </PrivateRoute>
                  <PrivateRoute path={qualifichePath} exact>
                    <QualificheW />
                  </PrivateRoute>
                  <PrivateRoute path={turniPath}>
                    <TurniW />
                  </PrivateRoute>
                  <PrivateRoute path={assenzePath} exact>
                    <AssenzeW />
                  </PrivateRoute>
                  <PrivateRoute path={festivitaPath} exact>
                    <FestivitaW />
                  </PrivateRoute>
                  <PrivateRoute path={tipiDocumentiPath} exact>
                    <TipoDocumentoW />
                  </PrivateRoute>
                  <PrivateRoute path={tipiIncaricoPath} exact>
                    <TipoIncaricoW />
                  </PrivateRoute>
                  <PrivateRoute path={modelliTerminalePath}>
                    <ModelloTerminaleW />
                  </PrivateRoute>

                  {/* Organizzazione */}
                  <PrivateRoute path={strutturePath}>
                    <StruttureW />
                  </PrivateRoute>
                  <PrivateRoute path={dipartimentiPath}>
                    <DipartimentiW />
                  </PrivateRoute>
                  <PrivateRoute path={unitaOperativePath}>
                    <UnitaOperativeW />
                  </PrivateRoute>
                  <PrivateRoute path={centriDiCostoPath}>
                    <CentriDiCostoW />
                  </PrivateRoute>
                  <PrivateRoute path={dittePath}>
                    <DitteW />
                  </PrivateRoute>
                  <PrivateRoute path={abilitazioneUtentePath}>
                    <AbilitazioniUtentiW />
                  </PrivateRoute>

                  {/* Gestione */}
                  <PrivateRoute path={anagrafichePath}>
                    <AnagraficaSoggettiW />
                  </PrivateRoute>
                  <PrivateRoute path={anagraficheCoordinatorePath}>
                    <AnagraficaSoggettiW />
                  </PrivateRoute>
                  <PrivateRoute path={anagraficaFormazionePath}>
                    <FormazioneDipendentiW />
                  </PrivateRoute>
                  <PrivateRoute path={presenzeDipendentiPath}>
                    <PresenzeDipendentiW />
                  </PrivateRoute>
                  <PrivateRoute path={pianificazioniDipendentiPath}>
                    <PianificazioniDipendentiW />
                  </PrivateRoute>
                  <PrivateRoute path={timbratureDipendentiPath}>
                    < TimbratureDipendenteW />
                  </PrivateRoute>
                  <PrivateRoute path={documentiDipendentePath}>
                    <DocumentiDipendenteW />
                  </PrivateRoute>
                  <PrivateRoute path={presenzePath}>
                    <PresenzeW />
                  </PrivateRoute>
                  <PrivateRoute path={pianificazioniPath}>
                    <PianificazioniW />
                  </PrivateRoute>
                  <PrivateRoute path={assenzeProgrammatePath}>
                    <AssenzeProgrammateW />
                  </PrivateRoute>
                  <PrivateRoute path={richiesteAssenzeProgrammatePath}>
                    <RichiesteAssenzeProgrammateW />
                  </PrivateRoute>
                  <PrivateRoute path={approvaAssenzeProgrammatePath}>
                    <ApprovazioneAssenzeProgrammateW />
                  </PrivateRoute>
                  <PrivateRoute path={approvaLungaAssenzaPath}>
                    <ApprovazioneAssenzeProgrammateW />
                  </PrivateRoute>
                  <PrivateRoute path={medicoCompetenteCoordinatorePath} exact>
                    <MedicoCompetenteW />
                  </PrivateRoute>
                  <PrivateRoute path={approvazionePresenzePath} exact>
                    <ApprovazionePresenzeW />
                  </PrivateRoute>
                  <PrivateRoute path={approvazioneAssenzePath} exact>
                    <ApprovazioneAssenzeW />
                  </PrivateRoute>
                  <PrivateRoute path={timbraturePath} exact>
                    <TimbratureW />
                  </PrivateRoute>
                  <PrivateRoute path={caricamentoDocumentiPath} >
                    <CaricamentoDocumentiW />
                  </PrivateRoute>

                  {/* Ricerche */}
                  <PrivateRoute path={entratiUsciti104Path} exact>
                    <EntratiUsciti104W />
                  </PrivateRoute>
                  <PrivateRoute path={verificaStraordinariPath} exact>
                    <VerificaStraordinariW />
                  </PrivateRoute>
                  <PrivateRoute path={variazioniUOPath} exact>
                    <VariazioniUOW />
                  </PrivateRoute>
                  <PrivateRoute path={dotazioneOrganicaPath} exact>
                    <DotazioneOrganicaW />
                  </PrivateRoute>
                  <PrivateRoute path={medicoCompetentePath} exact>
                    <MedicoCompetenteW />
                  </PrivateRoute>
                  <PrivateRoute path={presenzeUOPath} exact>
                    <PresenzeUOW />
                  </PrivateRoute>
                  <PrivateRoute path={dipendentiPresenzePath} exact>
                    <DipendentiPresenzeW />
                  </PrivateRoute>

                  {/* Monitoraggio */}
                  <PrivateRoute path={monitoraggioPath} exact>
                    <MonitoraggioW />
                  </PrivateRoute>

                  <Route path={notFoundPath}><PageNotFound /></Route>
                  <Route path={forbiddenPath}><PageForbidden /></Route>
                  <Route path={loginPagePath}><PageLogin /></Route>
                  <Route path="/" exact><Redirect to={homeUri} /></Route>
                  <Route path="*"><Redirect to={notFoundPath} /></Route>
                </Switch>
                <DialogConfirm />
              </LeftSideNavbarWithAppBar>
            </Suspense>
          )
          : hasIdAppSoftwareCookie
            ? <LoadingSvg color="primary" width={400} />
            : (
              <Suspense fallback={<LoadingSvg color="primary" width={300} />}>
                <PageForbidden />
              </Suspense>
            )
      }
    </ThemeProvider>
  );
}
export default App;
