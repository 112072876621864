import { Column } from "@material-table/core";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { allFields, Timbrature, presenzaTimbratura, nuovaPresenzaTimbratura, elementIdProps, elementRenderProps, TimbratureKeys } from "../../../models/Timbrature";
import { Presenza, allFieldsPresenzeTimbrature, PresenzaKeys, PresenzaExtended } from "../../../models/Presenze";
import { Fields } from "../../../models/Fields";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  update,
  reset as resetTimbrature,
  resetError as resetErrorTimbrature,
  physicalDel,
  restore,
  logicalDel,
  fetchBetweenDatesValid,
  fetchBetweenDatesDeleted
} from "../../../store/slices/timbratureSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { timbraturePath } from "../../../utils/utilconst";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { Box, FormControl, FormControlLabel, Grid, Switch as MuiSwitch, Paper, IconButton, Button, Modal, makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from "react-router-dom";
import { getDateYYYYMMDD_BackEnd, isUrlRoot, createLookup, differenzaInOreMinuti } from "../../../utils/utilfunctions";
import GeneralForm, { ChecksCallbackReturn, OptionalArgs } from "../forms/GeneralForm";
import {
  fetchLookupByToken as fetchASLookupByName,
  resetLookup as cleanAnagraficaSoggettoLookup,
} from "../../../store/slices/anagraficaSoggettiSlice";
import { VersoTimbraturaLookup } from "../../../utils/utildata";
import { Lookup, } from '../../../models/Utils';
import { fetchAllBetweenDates as fetchAllPresenzeBetweenDates, insert as insertPresenza, update as updatePresenza, resetError as resetErrorPresenze, fetchAnagraficaSoggettoPerData, } from "../../../store/slices/presenzeSlice";
import { lookupFiltered as lookupDipartimenti } from "../../../store/slices/dipartimentiFilteredSlice";
import { lookup as lookupStrutture } from "../../../store/slices/struttureSlice";
import { lookupFiltered as lookupByUnitaOperativa } from "../../../store/slices/unitaOperativeFilteredSlice";
import { fetchAllValidById as fetchAllValidTurnoAbilitato } from "../../../store/slices/anagraficaSoggettiTurnoAbilitatoSlice";
import { fetchAllValidById as fetchAllValidByIdQualifica, QualificheAbilitate } from "../../../store/slices/anagraficaSoggettiQualificaSlice";
import { differenceInHours, differenceInMinutes, addDays, minutesInHour } from 'date-fns';
import { openDialogConfirm } from "../../../store/slices/dialogsSlice";
import ReadOnlyMaterialTable from "../tables/ReadOnly/ReadOnlyMaterialTable";
import { fetchAllValid as fetchAllValidTurni } from "../../../store/slices/turniSlice";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import Maps from "../maps/Maps";
import GTModal from "../modal/GTModal";
import { FixedProps } from "../../../utils/data.types";
import { PresenzaForm } from "./PresenzeW";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 20,
      maxWidth: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'auto',
      paddingBottom: 25,

    },
  }),
);

interface TimbraturePresenze extends Partial<Presenza>, Partial<Timbrature> {
  nominativo?: string,
}

interface NuovaPresenzaTimbratura extends Partial<Presenza>, Partial<Timbrature> {
  nominativo?: string,
  idUnitaOperativa?: number,
  idStruttura?: number,
  idDipartimento?: number,
  idQualifica?: number,
  idTurno?: number,
}

const TimbratureW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const history = useHistory();
  const abilitazione = useAppSelector(state => getAbilitazione(state, timbraturePath));
  const classes = useStyles();
  const errorBE = useAppSelector(state => state.timbrature.error);
  const errorBEPresenza = useAppSelector(state => state.presenze.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorTimbrature());
  }, [dispatch])

  const [obj, setObj] = useState<TimbraturePresenze | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [objNuovaPresenza, setObjNuovaPresenza] = useState<Partial<NuovaPresenzaTimbratura> | null>(null);

  const [readOnly, setReadOnly] = useState<boolean>(false);

  const [map, setMap] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openMap, setOpenMap] = useState<boolean>(false);
  const [openPresenza, setOpenPresenza] = useState<boolean>(false);
  const [modificaPresenza, setModificaPresenza] = useState<boolean>(false);
  const isCurrentPageNotIndex = !isUrlRoot(history.location.pathname, timbraturePath);
  const currentPath = useMemo(() => history.location.pathname, [history.location.pathname]);

  const title = t('stampingParam');

  const dateStartLabel = useMemo(() => t("startDateSelect"), [t]);
  const dateStartName = useMemo(() => t("startDateTitle"), [t]);
  const dateEndLabel = useMemo(() => t("endDateSelect"), [t]);
  const dateEndName = useMemo(() => t("endDateTitle"), [t]);

  const anagraficaLookup = useAppSelector(state => state.anagraficaSoggetti.lookup);
  const anagraficaLookupDaTimbrature = useAppSelector(state => state.timbrature.lookupAnagrafiche);
  const statusAnagraficaSoggetto = useAppSelector(state => state.anagraficaSoggetti.statusValidAnagraficaSoggetti);

  const statusValidPresenze = useAppSelector((state) => state.presenze.statusValidPresenze);

  const lookupUnitOp = useAppSelector(state => state.unitaOperativeFiltered.lookup);
  const lookupDip = useAppSelector(state => state.dipartimentiFiltered.lookup);
  const lookupStr = useAppSelector(state => state.strutture.lookup);
  const lookupQualifiche = useAppSelector(state => state.qualifiche.lookupDescrizioneBreve);
  const lookupTurniBreve = useAppSelector(state => state.turni.lookupDescrizioneBreve);
  const qualificheAbilitateFiltered = useAppSelector(state => state.anagraficaSoggettiQualifica.qualificheAbilitate);
  const turniAbilitatiFiltered = useAppSelector(state => state.anagraficaSoggettiTurnoAbilitato.turniAbilitati);
  const validTurni = useAppSelector(state => state.turni.validTurni);
  const presenze = useAppSelector(state => state.presenze.validPresenze);

  const dialogRisposta = useAppSelector(state => state.dialogs.dialogConfirm.click);

  const [rowDataTimbrature, setRowDataTimbrature] = useState<Timbrature | null>(null);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  const [escludiGiaAssegnate, setEscludiGiaAssegnate] = useState(true);
  const handleChange = () => setEscludiGiaAssegnate(state => !state);

  const [states, setStates] = useState<{
    [selectName: string]: string | null;
  }>({
    [dateStartName]: getDateYYYYMMDD_BackEnd(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
    [dateEndName]: getDateYYYYMMDD_BackEnd(new Date()).replace("T00:00:00", "T23:59:59"),
  });

  const fixedProps = useMemo(() => {
    return {
      inizioData: states[dateStartName],
      fineData: states[dateEndName],
      escludiGiaAssegnate: escludiGiaAssegnate
    }
  }, [dateEndName, dateStartName, escludiGiaAssegnate, states])

  const fixedPropsPresenze: FixedProps = useMemo(() => {
    return {
      idAnagraficaSoggetto: rowDataTimbrature?.idAnagraficaSoggetto ?? null,
      dataInizio: rowDataTimbrature?.data ?? null,
      dataFine: rowDataTimbrature?.data?.replace("T00:00:00", "T23:59:59") ?? null,
    }
  }, [rowDataTimbrature?.data, rowDataTimbrature?.idAnagraficaSoggetto])

  /*Function per assegna nominativo e dettagli presenza */
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAllFieldsState(allFields)
  };

  const handleOpenMap = () => setOpenMap(true);

  const localAction = (genFormObject: Timbrature) => {
    dispatch(update(genFormObject));
    handleClose();
    setObj(null);
    dispatch(fetchBetweenDatesValid(fixedProps as { inizioData: string; fineData: string; }));
  }

  /*Fine Function per assegna nominativo e dettagli presenza */
  useEffect(() => {
    dispatch(fetchAllValidTurni());
  }, [dispatch])

  const [nuovaPresenza, setNuovaPresenza] = useState<Presenza | null>(null);
  useEffect(() => {
    if (dialogRisposta === 'yes' && openPresenza) {
      nuovaPresenza && dispatch(updatePresenza(nuovaPresenza));
      dispatch(fetchBetweenDatesValid(fixedProps as { inizioData: string; fineData: string; }));
      handleCloseNewPresenza();
      setObjNuovaPresenza(null);
      setNuovaPresenza(null);
    }

  }, [dialogRisposta, dispatch, fixedProps, nuovaPresenza, openPresenza])

  /*Function per inserimento nuova presenza*/
  const handleOpenNewPresenza = () => {
    setOpenPresenza(true);
  }

  const handleCloseNewPresenza = () => {
    setOpenPresenza(false);
  }

  const localActionPresenze = (genFormObject: PresenzaForm) => {
    let presenza: PresenzaForm | null = null;
    if (genFormObject?.idStruttura &&
      genFormObject?.idDipartimento
      && genFormObject?.idUnitaOperativa
      && genFormObject?.idAnagraficaSoggetto
      && genFormObject?.idQualifica
      && genFormObject?.idTurno
      && genFormObject?.oraInizioTurno) {

      (presenze as PresenzaForm[]).filter((elem) => {
        if (elem.idTurno && !elem.idTimbraturaInizioTurno) return true;
        return false;
      }).forEach((elem) => {
        if (genFormObject?.idStruttura.toString() === elem.idStruttura.toString()
          && genFormObject?.idDipartimento.toString() === elem.idDipartimento.toString()
          && genFormObject?.idUnitaOperativa.toString() === elem.idUnitaOperativa.toString()
          && genFormObject?.idAnagraficaSoggetto.toString() === elem.idAnagraficaSoggetto.toString()
          && genFormObject?.idQualifica?.toString() === elem.idQualifica?.toString()
          && genFormObject?.idTurno?.toString() === elem.idTurno?.toString()
          && (genFormObject?.oraInizioTurno <= elem.oraFineTurno || !elem.oraFineTurno)
        ) {
          const temp: Record<string, unknown> = {};
          if (elem.oraFineTurno) {
            const oraMinuti = differenzaInOreMinuti(genFormObject.oraInizioTurno, elem.oraFineTurno)
            temp['oreTurno'] = oraMinuti[0]
            temp['minutiTurno'] = oraMinuti[1]
          }

          presenza = {
            ...elem,
            ...temp,
            oraInizioTurno: genFormObject?.oraInizioTurno,
            idTimbraturaInizioTurno: genFormObject?.idTimbraturaInizioTurno
          };
        }
      })
    }
    if (presenza) {
      dispatch(updatePresenza(presenza));
      dispatch(fetchBetweenDatesValid(fixedProps as { inizioData: string; fineData: string; }));
      dispatch(openDialogConfirm({
        title: "",
        text: 'Modifica Presenza',

      }));
      setNuovaPresenza(presenza);
    } else {
      dispatch(insertPresenza(genFormObject))
        .then(data => {
          if (data.meta.requestStatus === 'fulfilled') {
            handleCloseNewPresenza();
            setObjNuovaPresenza(null);
            setIsLoading(true);
            dispatch(fetchBetweenDatesValid(fixedProps as { inizioData: string; fineData: string; }))
              .finally(() => setIsLoading(false))

            dispatch(resetErrorTimbrature());

          }
        })
    }
  }
  /* Fine Function per inserimento nuova presenza*/

  const handleOpenModificaPresenza = () => {
    setModificaPresenza(true);
  }

  const handleCloseModificaPresenza = () => {
    setModificaPresenza(false);
  }

  const [formLookup, setFormLookups] = useState<{ [key: string]: Lookup }>({
    idAnagraficaSoggetto: { ...anagraficaLookup, ...anagraficaLookupDaTimbrature },
    versoTimbratura: VersoTimbraturaLookup,
    'idAnagraficaSoggetto int': {},
    'idAnagraficaSoggetto est': {},
    'idTurnoAssenza': {},
    'idArticoloLegge': {},
    idDipartimento: lookupDip,
    idStruttura: lookupStr,
    idUnitaOperativa: lookupUnitOp,
    idTurno: createLookup(turniAbilitatiFiltered, 'idTurno', ['descrizione']),
    idQualifica: createLookup(qualificheAbilitateFiltered, 'idQualifica', ['descrizioneBreve']),
  });

  // Condition for DialogProps
  const isTurnoNotte = (presenza: Presenza): boolean => {
    const inizio_hour = Number(presenza?.oraInizioTurno?.split(':')[0]);
    const inizio_minute = Number(presenza?.oraInizioTurno?.split(':')[1]);

    const fine_hour = Number(presenza?.oraFineTurno?.split(':')[0]);
    const fine_minute = Number(presenza?.oraFineTurno?.split(':')[1]);

    if (Number.isNaN(inizio_hour) || Number.isNaN(inizio_minute) || (Number.isNaN(fine_hour)) || (Number.isNaN(fine_minute)))
      return false;
    return presenza &&
      undefined !== presenza?.idTurno && lookupTurniBreve[presenza.idTurno]?.toLowerCase().startsWith('n') &&
      (undefined !== presenza?.oraInizioTurno && undefined !== presenza?.oraFineTurno ? presenza.oraFineTurno < presenza.oraInizioTurno : true)
  }

  const postDialogConfirmCallback = (setGeneralFormObject: Dispatch<SetStateAction<(Partial<Presenza> & Partial<Timbrature>)>>) => {
    setGeneralFormObject(state => {
      let approvazioneRichiesta = false;
      if (errorBEPresenza?.startsWith('#')) {
        approvazioneRichiesta = true;
      }
      return {
        ...state,
        approvazioneRichiesta: approvazioneRichiesta,
      }
    })
  };

  const clearErrorMessageDialog = useCallback(() => {
    dispatch(resetErrorPresenze());
  }, [dispatch])

  useEffect(() => {

    setFormLookups(state => {
      return {
        ...state,
        idAnagraficaSoggetto: { ...anagraficaLookup, ...anagraficaLookupDaTimbrature },
        versoTimbratura: VersoTimbraturaLookup,
        'idAnagraficaSoggetto int': {},
        'idAnagraficaSoggetto est': {},
        'idTurnoAssenza': {},
        'idArticoloLegge': {},
        idDipartimento: lookupDip,
        idStruttura: lookupStr,
        idUnitaOperativa: lookupUnitOp,
        idTurno: createLookup(turniAbilitatiFiltered, 'idTurno', ['descrizione']),
        idQualifica: createLookup(qualificheAbilitateFiltered, 'idQualifica', ['descrizioneBreve']),

      };
    })
  }, [anagraficaLookup, anagraficaLookupDaTimbrature, lookupDip, lookupStr, lookupUnitOp, qualificheAbilitateFiltered, turniAbilitatiFiltered]);

  const formCallback = useCallback((formObject: (Partial<Presenza> & Partial<Timbrature>) | null, field: string, optionalArgs: OptionalArgs<(Partial<Presenza> & Partial<Timbrature>)>): ChecksCallbackReturn | void => {
    const { setInternalObj, setFields, } = optionalArgs;

    if (formObject) {
      if (field === 'idStruttura' && formObject.idStruttura && formObject.idAnagraficaSoggetto && formObject.presenzaData) {
        dispatch(fetchAllValidTurnoAbilitato([formObject.idStruttura, formObject.idAnagraficaSoggetto, formObject.presenzaData]))
        dispatch(lookupDipartimenti({ idStruttura: formObject.idStruttura }));
      } else if (field === 'idDipartimento' && formObject.idDipartimento && formObject.idStruttura) {
        dispatch(lookupByUnitaOperativa({ idDipartimento: formObject.idDipartimento, idStruttura: formObject.idStruttura }));
      }
      if (formObject.idStruttura &&
        formObject.idDipartimento &&
        formObject.idUnitaOperativa &&
        formObject.presenzaData) {
        dispatch(fetchAllPresenzeBetweenDates({
          idStruttura: formObject.idStruttura,
          idDipartimento: formObject.idDipartimento,
          idUnitaOperativa: formObject.idUnitaOperativa,
          da: formObject.presenzaData,
          a: formObject.presenzaData
        }))
      }

      setFormObject({ internalStateSetter: setInternalObj, internalFieldsSetter: setFields, object: formObject, field, isUpdate: false });
      if (field === 'idTurno') {
        if (validTurni?.find(elem => elem.idTurno === Number(formObject[field]))?.richiestaApprovazione) {
          return {
            type: 'info',
            field: 'idTurno',
            message: 'Turno soggetto ad approvazione',
          }
        }
      }
    }
  }, [dispatch, validTurni])

  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [allFieldsStateForm, setAllFieldsStateForm] = useState<Fields[]>(allFields);
  const [allFieldsStateNuovaPresenza, setAllFieldsStateNuovaPresenza] = useState<Fields[]>(nuovaPresenzaTimbratura);
  const [allFieldStateModificaPresenza, setAllFieldStateModificaPresenza] = useState<Fields[]>(allFieldsPresenzeTimbrature);

  const [columns, setColumns] = useState<Array<Column<Timbrature>>>([]);
  useEffect(() => {
    return setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<Timbrature> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        };
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as TimbratureKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.field === 'nominativo') {
          obj.emptyValue = undefined;
          obj.render = (rowData: Timbrature) => {
            if (!rowData?.idAnagraficaSoggetto) {
              return (
                <Button
                  variant="contained"
                  color='primary'
                  onClick={() => {
                    setObj(rowData);
                    handleOpen();
                  }}
                >
                  {t('assignParam')}
                </Button>
              );
            } else {
              if (rowData.cognome && rowData.nome) {
                return rowData.cognome + ' ' + rowData.nome;
              }
              else
                return t('noNameParam');
            }
          };
        } else if (f.field === 'button') {
          obj.emptyValue = undefined;
          obj.width = 20;
          obj.render = (rowData: Timbrature) => {
            if (!rowData?.idAnagraficaSoggetto) {
              return null;
            } else {
              if (rowData.cognome && rowData.nome) {
                return <>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      handleOpen();
                      setObj(rowData);
                      setReadOnly(false);
                      setAllFieldsStateForm(allFields);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </>;
              }
              else
                return t('noNameParam');
            }
          };
        } else if (f.field === 'tracciato') {
          obj.render = (rowData: Timbrature) => {
            return <Button
              onClick={() => {
                handleOpenMap();
                const token = rowData.tracciato.split('+')?.[1];
                let lat = token?.split('N')?.[0].slice(1);
                let lng = token?.split('N')?.[1];

                // lat: [-90, 90], lng: [-180, 180]
                lat = lat?.slice(0, lat?.lastIndexOf('.'));
                lng = lng?.slice(0, lng?.lastIndexOf('.'));

                setMap({ lat: Number(lat), lng: Number(lng) });
              }}
              size='small'
              variant='outlined'
              color='primary'>
              {t('show')}
            </Button>
          }
        }

        if (f.lookupField) {
          switch (f.field) {
            case "versoTimbratura":
              obj.lookup = VersoTimbraturaLookup;
          }
        }

        if (f.field === 'buttonDettagli') {
          obj.emptyValue = undefined;
          obj.width = 20;
          obj.render = (rowData: Timbrature) => {
            if (rowData?.presenza && rowData?.idAnagraficaSoggetto) {
              return <>
                <Button
                  variant='contained'
                  color="primary"
                  onClick={() => {
                    setObj({
                      ...rowData?.presenza,
                      nominativo: rowData?.cognome + ' ' + rowData?.nome
                    });
                    setAllFieldsStateForm(presenzaTimbratura)
                    handleOpen();
                    setReadOnly(true)
                  }}
                >
                  {t('Dettagli')}
                </Button>
              </>
            } else if (!rowData?.presenza && rowData?.idAnagraficaSoggetto) {
              return <>
                <Button
                  variant='contained'
                  color="secondary"
                  onClick={() => {
                    if (rowData?.versoTimbratura && VersoTimbraturaLookup[rowData?.versoTimbratura] === 'Entrata') {
                      setObjNuovaPresenza({
                        idAnagraficaSoggetto: rowData?.idAnagraficaSoggetto,
                        oraInizioTurno: rowData?.oraInizioTurno,
                        presenzaData: rowData?.data,
                        nota: rowData?.causale,
                        nominativo: rowData?.cognome + ' ' + rowData?.nome,
                        idStruttura: rowData?.presenza?.idStruttura,
                        idUnitaOperativa: rowData?.presenza?.idUnitaOperativa,
                        idDipartimento: rowData?.presenza?.idDipartimento,
                        idQualifica: rowData?.presenza?.idQualifica,
                        idTurno: rowData?.presenza?.idTurno,
                        idTimbraturaInizioTurno: rowData?.idTimbratura
                      })
                      setAllFieldsStateNuovaPresenza(nuovaPresenzaTimbratura)
                      handleOpenNewPresenza()
                      dispatch(lookupStrutture())
                      dispatch(fetchAllValidByIdQualifica([rowData?.idAnagraficaSoggetto, rowData?.data]))

                    }
                    else {
                      setRowDataTimbrature(rowData)
                      handleOpenModificaPresenza()
                      setAllFieldStateModificaPresenza(allFieldsPresenzeTimbrature)
                    }
                  }}
                >
                  {t('Assegna Presenza')}
                </Button>
              </>
            } else return "";
          }
        }
        return obj;
      })
    );
  }, [allFieldStateModificaPresenza, allFieldsState, dispatch, lookupDip, lookupQualifiche, lookupStr, lookupUnitOp, setColumns, t]);

  const [columnsMofidicaPresenza, setColumnsModificaPresenza] = useState<Array<Column<Presenza>>>([]);
  useEffect(() => {
    return setColumnsModificaPresenza(
      allFieldStateModificaPresenza.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<Presenza> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        };
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as PresenzaKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.field === 'idAnagraficaSoggetto') {
          obj.emptyValue = undefined;
          obj.render = (rowData: Presenza) => {
            return rowData.anagraficaSoggetto?.[0].cognome + ' ' + rowData.anagraficaSoggetto?.[0].nome
          }
        }

        if (f.field === 'buttonDettagli') {
          obj.emptyValue = undefined;
          obj.width = 20;
          obj.render = (rowData: Presenza) => {
            return <>
              <Button
                variant='contained'
                color="primary"
                onClick={() => {
                  const temp: Record<string, unknown> = {};
                  const oraMinuti = differenzaInOreMinuti(rowData?.oraInizioTurno, rowDataTimbrature?.oraFineTurno)
                  temp['oreTurno'] = oraMinuti[0]
                  temp['minutiTurno'] = oraMinuti[1]

                  if (undefined !== rowDataTimbrature?.idTimbratura && undefined !== rowDataTimbrature?.oraFineTurno) {
                    const presenzaUpdated: Presenza = {
                      ...rowData,
                      ...temp,
                      idTimbraturaFineTurno: rowDataTimbrature.idTimbratura,
                      oraFineTurno: rowDataTimbrature.oraFineTurno,
                    }

                    dispatch(updatePresenza(presenzaUpdated))
                      .then(data => {
                        if (data.meta.requestStatus === 'fulfilled') {
                          dispatch(fetchBetweenDatesValid(fixedProps as { inizioData: string; fineData: string; }))
                        }
                      })
                      .finally(() => {
                        handleCloseModificaPresenza();
                      })
                  }
                }}
              >
                {t('Assegna Timbrature')}
              </Button>
            </>
          }
        }
        return obj;
      })
    );
  }, [allFieldStateModificaPresenza, allFieldsState, dispatch, fixedProps, fixedPropsPresenze, presenze, rowDataTimbrature?.idTimbratura, rowDataTimbrature?.oraFineTurno, rowDataTimbrature?.oraInizioTurno, t]);

  const [formObject, setFormObject] = useState<{ internalStateSetter: Dispatch<SetStateAction<(Partial<PresenzaExtended> & Partial<Timbrature>)>>, internalFieldsSetter: Dispatch<SetStateAction<Fields[]>>, object: (Partial<PresenzaExtended> & Partial<Timbrature>), field: string, isUpdate: boolean } | null>(null);
  useEffect(() => {

    const oreTurnoConst = 'oreTurno';
    const minutiTurnoConst = 'minutiTurno';
    const idAnagraficaSoggettoInternaConst = "idAnagraficaSoggetto int";
    const idTurnoConst = 'idTurno';
    const oraInizioTurnoConst = 'oraInizioTurno';
    const oraFineTurnoConst = 'oraFineTurno';
    const idAnagraficaSoggettoConst = 'idAnagraficaSoggetto';
    const idQualificaConst = 'idQualifica';

    if (formObject) {
      const generalFormObjectStateSetter = formObject.internalStateSetter;
      const generalFormFieldsStateSetter = formObject.internalFieldsSetter;
      const object = formObject.object;
      const field = formObject.field;

      /**
       * Toggle readonly and required form fields
       */
      switch (field) {
        case idTurnoConst:
          generalFormFieldsStateSetter(state => {
            const newState = [...state];
            const ID_NOTTE = Object.entries(lookupTurniBreve ?? {})
              .filter(elem => elem[1].trim().toLowerCase().startsWith('n'))
              .map(elem => elem[0]);
            newState.forEach((elem, index, arr) => {
              if (object[idTurnoConst] && ID_NOTTE.includes(object[idTurnoConst].toString())) {
                newState.forEach((elem, index, array) => {
                  if (elem.field === oraInizioTurnoConst || elem.field === oraFineTurnoConst) {
                    array[index].validate2 = undefined;
                  }
                })
              } else {
                newState.forEach((elem, index, array) => {
                  if (elem.field === oraInizioTurnoConst || elem.field === oraFineTurnoConst) {
                    array[index].validate2 = nuovaPresenzaTimbratura[index].validate2;
                  }
                })
              }
            });

            return newState;
          });
          break;
        case idAnagraficaSoggettoInternaConst:
          generalFormFieldsStateSetter(state => {
            const newState = [...state];
            newState.forEach((elem, index, arr) => {
              if (elem.field === idAnagraficaSoggettoInternaConst)
                elem.required = true;
              if (object[idTurnoConst] && (elem.field === oraInizioTurnoConst || elem.field === oraFineTurnoConst)) {
                arr[index].required = elem.field === oraInizioTurnoConst;
                arr[index].readonly = false;
              }
            });
            return newState;
          });
          break;
      }

      /**
       * set GeneralForm fields object based on filled up field
       */
      generalFormObjectStateSetter(state => {
        let newState: (Partial<PresenzaExtended> & Partial<Timbrature>) = { ...state };
        let defaultQualifica: QualificheAbilitate | undefined = undefined;

        switch (field) {
          case idTurnoConst:
            const selectedTurno = turniAbilitatiFiltered.find(elem => elem.idTurno === Number(object[idTurnoConst]));

            newState[oreTurnoConst] = selectedTurno ? selectedTurno.ore : undefined;
            newState[minutiTurnoConst] = selectedTurno ? '00' : undefined;

            break;
          case idAnagraficaSoggettoInternaConst:
            defaultQualifica = qualificheAbilitateFiltered.find(elem => elem.selected);

            newState[idAnagraficaSoggettoConst] = object[idAnagraficaSoggettoInternaConst] ?? undefined;
            newState[idAnagraficaSoggettoInternaConst] = object[idAnagraficaSoggettoInternaConst];
            newState[idQualificaConst] = defaultQualifica ? defaultQualifica[idQualificaConst] : undefined;
            break;
          case oraInizioTurnoConst:
          case oraFineTurnoConst:
            if (object[oraInizioTurnoConst] && object[oraFineTurnoConst]) {
              const oraInizioTurno = object[oraInizioTurnoConst];
              const oraFineTurno = object[oraFineTurnoConst];

              const ID_NOTTE = Object.entries(lookupTurniBreve ?? {})
                .filter(elem => elem[1].trim().toLowerCase().startsWith('n'))
                .map(elem => elem[0]);

              const FROM_HOURS = Number(oraInizioTurno.split(':')[0]);
              const FROM_MINUTES = Number(oraInizioTurno.split(':')[1]);
              const TO_HOURS = Number(oraFineTurno.split(':')[0]);
              const TO_MINUTES = Number(oraFineTurno.split(':')[1]);

              const inizioDate = new Date();
              inizioDate.setHours(FROM_HOURS, FROM_MINUTES);

              const fineDate = new Date();
              fineDate.setHours(TO_HOURS + (TO_HOURS >= FROM_HOURS ? 0 : 24), TO_MINUTES);

              const mezzaNotte = addDays(new Date(), 1);
              mezzaNotte.setHours(0, 0);

              const oreVal = differenceInHours(fineDate, inizioDate);
              const minutiVal = differenceInMinutes(fineDate, inizioDate) % minutesInHour;

              if (oreVal >= 0 && minutiVal >= 0) {  // stesso giorno && fine >= inizio
                newState[oreTurnoConst] = oreVal;
                newState[minutiTurnoConst] = minutiVal;
              } else if (newState[idTurnoConst] && !ID_NOTTE.includes(newState[idTurnoConst].toString())) { // stesso giorno && fine < inizio && turno != 'notte'
                newState[oreTurnoConst] = 0;
                newState[minutiTurnoConst] = 0;
              } else if (newState[idTurnoConst] && ID_NOTTE.includes(newState[idTurnoConst].toString()) && (oreVal < 0 || minutiVal < 0)) {
                newState[oreTurnoConst] = differenceInHours(mezzaNotte, inizioDate);
                newState[minutiTurnoConst] = differenceInMinutes(mezzaNotte, inizioDate) % minutesInHour;
              }
            }
            break;
        }
        return newState;
      });
    }
  }, [formObject, lookupTurniBreve, qualificheAbilitateFiltered, turniAbilitatiFiltered]);

  const validTimbrature = useAppSelector(state => state.timbrature.validTimbrature);
  const deletedTimbrature = useAppSelector(state => state.timbrature.deletedTimbrature);
  const statusValidTimbrature = useAppSelector(state => state.timbrature.statusValidTimbrature);
  const statusDeletedTimbrature = useAppSelector(state => state.timbrature.statusDeletedTimbrature);
  const validPresenza = useAppSelector(state => state.presenze.validPresenze);

  const [data, setData] = useState<Array<Timbrature>>([]);
  const [dataModificaPresenze, setDataModificaPresenze] = useState<Array<Presenza>>([]);

  /**
   * Search token and save results to slice. Called by autocomplete in GeneralForm.
   * @param {string} token - string to be searched
   * @param {number} minLen - minimum acceptable length of token
   */
  const searchAction = (token: string, minLen: number = 3) => {
    if (token.length === minLen) {
      dispatch(fetchASLookupByName({ token: token }))
    }
    else if (token.length === 0) {
      dispatch(cleanAnagraficaSoggettoLookup());
    }
  }

  const handleDateChange = (d: Date | null, field?: string) => {
    let fieldDate: string | null = null;
    if (d) {
      let month = (d.getMonth() + 1).toString().padStart(2, '0');
      let day = d.getDate().toString().padStart(2, '0');
      fieldDate = d.getFullYear() + "-" + month + "-" + day + "T00:00:00";
    }
    field && setStates(prev => { return { ...prev, [field]: fieldDate } });
  };

  useEffect(() => {

    setDataModificaPresenze(validPresenza.filter(elem => {
      return elem?.idTurno != null && elem?.idTimbraturaFineTurno === null && undefined !== rowDataTimbrature?.oraFineTurno && elem?.oraInizioTurno <= rowDataTimbrature?.oraFineTurno
    }));
  }, [rowDataTimbrature?.oraFineTurno, validPresenza])

  useEffect(() => {
    toggleValidDeleted ? setData(validTimbrature.filter(elem => {
      if (fixedProps?.escludiGiaAssegnate)
        return elem?.presenza === null || elem?.presenza === undefined;
      return true;
    })) : setData(deletedTimbrature);
  }, [deletedTimbrature, fixedProps?.escludiGiaAssegnate, toggleValidDeleted, validTimbrature]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
      setAllFieldsStateNuovaPresenza([]);
      dispatch(resetTimbrature());
      setFormObject(null);
    };
  }, [dispatch]);

  const Map = useMemo(() => {
    return map && map.lat && map.lng
      ? <Maps map={map} />
      : null
  }, [map]);

  const _dateStartName = useMemo(() => states[dateStartName], [dateStartName, states]);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  fullWidth
                  variant="inline"
                  label={dateStartLabel}
                  format={"dd/MM/yyyy"}
                  onChange={(e) => handleDateChange(e, dateStartName)}
                  autoOk={true}
                  disabled={isCurrentPageNotIndex}
                  value={states[dateStartName] ?? null}
                  views={["year", "month", "date"]}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {
              _dateStartName &&
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    fullWidth
                    variant="inline"
                    label={dateEndLabel}
                    format={"dd/MM/yyyy"}
                    onChange={(e) => handleDateChange(e, dateEndName)}
                    autoOk={true}
                    minDate={new Date(_dateStartName)}
                    maxDate={new Date()}
                    disabled={isCurrentPageNotIndex}
                    value={states[dateEndName] ?? null}
                    views={["year", "month", "date"]}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            {
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        disabled={!isUrlRoot(currentPath, timbraturePath)}
                        checked={escludiGiaAssegnate}
                        onChange={handleChange}
                        name="escludi assegnate"
                        color="primary"
                      />
                    }
                    labelPlacement="start"
                    label={t("escludi assegnate")}
                  />
                </FormControl>
              </Grid>
            }
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2}>
        <CrudMaterialTable
          abilitazione={abilitazione}
          title={title}
          columns={columns}
          columnsButton={true}
          data={data}
          elementIdProps={elementIdProps}
          elementRenderProps={elementRenderProps}
          fetchAllValid={fetchBetweenDatesValid}
          fetchAllDeleted={fetchBetweenDatesDeleted}
          physicalDel={physicalDel}
          update={undefined}
          errorBE={errorBE}
          logoUri={logoUri}
          localizedDatePicker={true}
          isExportLandscape={false}
          fixedProps={fixedProps}
          resetErrorCallback={resetError}
          isLoading={isLoading}
          logicalDel={logicalDel}
          restore={restore}
          statusValid={statusValidTimbrature}
          dataValid={toggleValidDeleted}
          setToggleValidDeleted={setToggleValidDeleted}
          statusDeleted={statusDeletedTimbrature}
          extraOptions={{
            maxBodyHeight: 460,
            draggable: false,
          }}
        />
      </Box>
      <Modal
        className={clsx(classes.container)}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper>
          <Grid container>
            <Grid item xs={9} />
            <Grid item xs={3}>
              <Box display='flex' justifyContent='right'>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Box px={3} paddingBottom={4}>
            <GeneralForm
              fields={allFieldsStateForm}
              translate={t}
              obj={obj}
              localAction={localAction}
              status={statusAnagraficaSoggetto}
              update={true}
              lookups={formLookup}
              autoCompleteSearchAction={searchAction}
              componentPath={history.location.pathname}
              readOnly={readOnly}
            />
          </Box>
        </Paper>
      </Modal>

      {/* Modal Inserimento nuova Presenza */}
      <Modal
        className={clsx(classes.container)}
        open={openPresenza}
        onClose={handleCloseNewPresenza}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper>
          <Grid container>
            <Grid item xs={9} />
            <Grid item xs={3}>
              <Box display='flex' justifyContent='right'>
                <IconButton onClick={handleCloseNewPresenza}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Box px={3} paddingBottom={4}>
            <GeneralForm
              fields={allFieldsStateNuovaPresenza}
              translate={t}
              obj={objNuovaPresenza}
              localAction={localActionPresenze}
              status={statusValidPresenze}
              update={false}
              error={errorBEPresenza}
              lookups={formLookup}
              checksCallback={formCallback}
              dialogProps={{
                body: t('addChangeTurnPrompt'),
                condition: isTurnoNotte,
                postDialogConfirmCallback: postDialogConfirmCallback,
                clearErrorMessage: clearErrorMessageDialog
              }}
              componentPath={history.location.pathname}
            />
          </Box>
        </Paper>
      </Modal>

      {/* Modal Modifica Presenza */}
      <Modal
        className={clsx(classes.container)}
        open={modificaPresenza}
        onClose={handleCloseModificaPresenza}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper>
          <Grid container>
            <Grid item xs={9} />
            <Grid item xs={3}>
              <Box display='flex' justifyContent='right'>
                <IconButton onClick={handleCloseModificaPresenza}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Box px={3} paddingBottom={4}>
            <ReadOnlyMaterialTable
              title={title}
              columns={columnsMofidicaPresenza}
              columnsButton={true}
              data={dataModificaPresenze}
              fetchAllValid={fetchAnagraficaSoggettoPerData}
              fixedProps={fixedPropsPresenze}
              statusValid={statusValidPresenze}
              errorBE={errorBEPresenza}
              logoUri={logoUri}
              localizedDatePicker={true}
              isExportLandscape={true}
              extraOptions={{
                maxBodyHeight: 460,
              }}
            />
          </Box>
        </Paper>
      </Modal>
      {/*  */}
      {
        openMap &&
        <GTModal
          openState={{
            open: openMap,
            setOpen: setOpenMap
          }}
        >
          {
            Map ??
            <Typography>
              Impossibile rilevare la posizione
            </Typography>
          }
        </GTModal>
      }
    </>
  )
}

export default TimbratureW;