/* eslint-disable */
import React, { Dispatch, SetStateAction } from "react";
import MaterialTable, { Column, Icons, Options } from "@material-table/core";
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useTheme } from "@material-ui/core";
import useCrudMaterialTable from "./useCrudMaterialTable";
import { AsyncThunk } from "@reduxjs/toolkit";
import { Ids } from "../../../../models/Utils";
import { ExportType } from "../../../../utils/utildata";
import { PDFExtraData } from '../../../../models/Utils';
import { FixedProps } from "../../../../utils/data.types";

const icons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}
export interface CrudMaterialTableProps<T> {
  abilitazione: number;
  title: string;
  elementIdProps: string[];
  elementRenderProps: string[];
  columns: Array<Column<any>>;
  columnsButton?: boolean;
  data: Array<any>;
  fetchAllValid: AsyncThunk<T[], void, {}> | AsyncThunk<T[], T, {}> | AsyncThunk<T, T, {}>;
  fetchAllDeleted?: AsyncThunk<T[], void, {}> | AsyncThunk<T[], T, {}> | AsyncThunk<T, T, {}>;
  insert?: AsyncThunk<T, T, {}>;
  logicalDel: AsyncThunk<{ ids: Ids<any>[] }, Ids<any>[], {}>;
  physicalDel: AsyncThunk<{ ids: Ids<any>[] }, Ids<any>[], {}>;
  restore: AsyncThunk<{ ids: Ids<any>[] }, Ids<any>[], {}>;
  update?: AsyncThunk<T, T, {}>;
  dataValid: boolean;
  setToggleValidDeleted: Dispatch<SetStateAction<boolean>>;
  statusValid: string;
  statusDeleted: string;
  errorBE: string | null;
  logoUri: string | null;
  localizedDatePicker?: boolean;
  nullableTextFields?: boolean;
  fixedProps?: FixedProps;
  exportDataExtra?: PDFExtraData;
  exportType?: ExportType;
  isExportLandscape?: boolean,
  insertCallback?: () => void;
  updateCallback?: (obj: T) => void;
  detailCallback?: (obj: T) => void;
  resetErrorCallback?: () => void;
  autofetch?: boolean;
  extraOptions?: Options<object>;
  isLoading?: boolean;
}

const CrudMaterialTable = ({
  abilitazione, extraOptions,
  title, columns, columnsButton, data, elementIdProps, elementRenderProps,
  fetchAllValid, fetchAllDeleted, insert, logicalDel, physicalDel, restore, update,
  dataValid, setToggleValidDeleted, statusValid, statusDeleted,
  errorBE, localizedDatePicker, nullableTextFields, fixedProps, exportDataExtra, exportType, isExportLandscape, logoUri,
  insertCallback, updateCallback, detailCallback, resetErrorCallback, autofetch, isLoading }: CrudMaterialTableProps<any>) => {

  const theme = useTheme();
  const { loading, materialTableRef, localization, editable, actions, options, components } = useCrudMaterialTable(
    abilitazione,
    theme, title, logoUri, elementIdProps, elementRenderProps,
    fetchAllValid, logicalDel, physicalDel, restore, columnsButton ? true : false,
    dataValid, setToggleValidDeleted,
    statusValid, statusDeleted, errorBE, fetchAllDeleted, insert, update, localizedDatePicker, nullableTextFields, fixedProps, exportDataExtra, exportType, isExportLandscape,
    insertCallback, updateCallback, detailCallback, resetErrorCallback, autofetch, isLoading);

  const updatedOptions = {
    ...options,
    ...extraOptions,
    pageSize: 10,
  }

  return (
    <MaterialTable
      components={components}
      icons={icons}
      tableRef={materialTableRef}
      title={title}
      columns={columns}
      data={data}
      editable={editable}
      actions={actions}
      options={updatedOptions}
      localization={localization}
      isLoading={loading}
    />
  )
}
export default CrudMaterialTable;