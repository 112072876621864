import React, { useEffect, useState } from "react";
import i18n from "../i18n";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { logoutAction, setCookieOrganizationAction, setSessionProfilAction, setLogoUri, fetchOrganizzazioneInfo } from "../store/slices/authInfoSlice";
import { clearFunzionalita } from "../store/slices/funzionalitaSlice";
import { cookieOrg, cookiePortal, cookieProfil, cookieUser, forbiddenPath, localStorageKeyLoggedApp, localStorageValueLoggedApp, monitoraggioPath, sessionLogo } from "../utils/utilconst";
import { getCookie } from "../utils/utilfunctions";
import { fetchFunzionalita } from "../store/slices/funzionalitaSlice";
import { fetchById as fetchApplicazioneSoftware } from "../store/slices/applicazioniSoftwareSlice";
import useConfig from "./hooks/useConfig";
import useChatClient from "../core/components/chatBox/useChatClient";
import useHttpInterceptor from "./hooks/useHttpInterceptor";
import useAuth from "./hooks/useAuth";
import { useHistory } from "react-router-dom";
import { setUtenteLoggato } from "../store/slices/utenteLoggatoSlice";

export const useApp = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const organization = useAppSelector(state => state.authInfo.organization);
  const idProfil = useAppSelector(state => state.authInfo.idProfil);

  const token = useAppSelector(state => state.authInfo.token);
  const homeUri = useAppSelector(state => state.funzionalita.homeuri);
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const nomeOrganizzazione = useAppSelector(state => state.authInfo.organizationName);
  const appSoftware = useAppSelector(state => state.applicazioneSoftware.appSoftware);

  const [hasIdAppSoftwareCookie, setHasIdAppSoftwareCookie] = useState<boolean>(false);

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) {
      const profil = getCookie(cookieProfil);
      const token = getCookie(cookiePortal);

      if (profil || token) {
        setHasIdAppSoftwareCookie(true);
      } else if (!window.location.pathname.includes(forbiddenPath)) {
        history.replace(forbiddenPath);
      }
    }
  }, [hasIdAppSoftwareCookie, history]);

  const HttpHeader = React.useMemo(() => {
    return {
      Authorization: 'Bearer ' + token,
      IdOrganizzazione: organization,
      lang: i18n.language,
    }
  }, [organization, token]);

  /**
   * Setup chat client (EventSource)
   */
  useChatClient({ header: HttpHeader, hasIdAppSoftwareCookie });

  /**
   * Setup Http Request and Response interceptor
   */
  useHttpInterceptor({ header: HttpHeader, hasIdAppSoftwareCookie });

  /**
   * Inizializza le configurazioni dell'applicazione web
   */
  useConfig({ hasIdAppSoftwareCookie });

  /**
   * Gestisce lo stato dell'autenticazione dell'utente
   */
  useAuth({ hasIdAppSoftwareCookie });

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    if (token && idProfil) {
      dispatch(fetchFunzionalita({ idAppSoft: idProfil[0], idAppSoftProfil: idProfil[1] }))
      dispatch(fetchApplicazioneSoftware({ idApplicazioneSoftware: Number(idProfil[0]) }));
    }
  }, [dispatch, hasIdAppSoftwareCookie, idProfil, token]);

  /**
   * Modifica vari elementi della pagina
   */
  useEffect(() => {
    document.title = appSoftware?.descrizione ?? '';
    document.body.style.backgroundColor = window.location.pathname === monitoraggioPath ? '#fff' : '#d4e9Ddd';
    const linkElement = document.getElementById('favicon') as HTMLLinkElement;
    if (appSoftware?.favIcon)
      linkElement.href = appSoftware.favIcon
  }, [appSoftware?.descrizione, appSoftware?.favIcon]);

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    window.localStorage.setItem(localStorageKeyLoggedApp, localStorageValueLoggedApp);
    window.onstorage = () => {
      let r = window.localStorage.getItem(localStorageKeyLoggedApp);
      if (r !== localStorageValueLoggedApp) {
        dispatch(logoutAction());
      }
    };
    return () => { window.onstorage = null };
  }, [dispatch, hasIdAppSoftwareCookie]);

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    const logo = sessionStorage.getItem(sessionLogo);
    if (!nomeOrganizzazione) {
      const token = getCookie(cookiePortal);

      if (token)
        dispatch(fetchOrganizzazioneInfo({
          IDOrg: Number(getCookie(cookieOrg)),
          token
        }));
    }
    else if (logo)
      dispatch(setLogoUri(logo));
  }, [dispatch, hasIdAppSoftwareCookie, nomeOrganizzazione])

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    if (token === null) {
      dispatch(clearFunzionalita());
    }
  }, [token, dispatch, hasIdAppSoftwareCookie]);

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    if (!organization) dispatch(setCookieOrganizationAction());
  }, [organization, dispatch, hasIdAppSoftwareCookie]);

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    if (!idProfil || getCookie(cookieProfil)) dispatch(setSessionProfilAction());
  }, [idProfil, dispatch, hasIdAppSoftwareCookie]);

  /**
   * Memorizza le informazioni passate dal portale dell'utente loggato prese dal cookie
   */
  useEffect(() => {
    const loggedUser = getCookie(cookieUser);

    if (loggedUser) {
      dispatch(setUtenteLoggato(JSON.parse(loggedUser)))
    }
  }, [dispatch]);

  return { homeUri, logoUri, nomeOrganizzazione, hasIdAppSoftwareCookie };
}